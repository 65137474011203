<template>
    <svg ref="gantt"></svg>
</template>

<script>
import Gantt from 'frappe-gantt';

export default {
    name: 'FrappeGantt',
    props: {
        viewMode: {
            type: String,
            required: false,
            default: 'Day'
        },
        tasks: {
            required: true,
            default: null
        }
    },
    data() {
        return {
            gantt: {},
        };
    },
    watch: {
        viewMode() {
            this.updateViewMode();
        },

        tasks() {
            this.updateTasks();
        }
    },
    mounted() {
        this.setupGanttChart();
    },
    methods: {
        setupGanttChart() {
            this.gantt = new Gantt(this.$refs.gantt, this.tasks, {
                on_click: task => {
                    this.$emit('task-updated', task);
                },

                on_date_change: (task, start, end) => {
                    this.$emit('task-date-updated', { task, start, end });
                },

                on_progress_change: (task, progress) => {
                    this.$emit('task-progress-updated', { task, progress });
                }
            });
            this.updateTasks();
            this.updateViewMode();
        },

        updateViewMode() {
            this.gantt.change_view_mode(this.viewMode);
            this.adjustGanttHeight();
        },

        updateTasks() {
            this.gantt.refresh(this.tasks);
            this.adjustGanttHeight();
        },
        adjustGanttHeight() {
            this.$nextTick(() => {
                setTimeout(() => {
                    const svgElement = this.$refs.gantt;
                    let currentHeight = svgElement.getBoundingClientRect().height;
                    let newHeight = currentHeight - 100;
                    svgElement.setAttribute('height', newHeight.toString());
                }, 400);
            });
        }
    }
};
</script>

<style lang="scss">
@import "~frappe-gantt/dist/frappe-gantt.css";
</style>