import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import posthogPlugin from "./plugins/posthog";
import http from "./http";
import config from "./config";
import "./assets/css/main.css"; // global styles
import Shepherd from 'shepherd.js';
import 'shepherd.js/dist/css/shepherd.css';
import './shepherd-custom-styles.css';
import tawkPlugin from "./plugins/tawk";

Vue.config.productionTip = false;
Vue.prototype.$shepherd = Shepherd;
Vue.use(posthogPlugin);
Vue.use(tawkPlugin);

new Vue({
  el: "#app",
  http: http,
  router: router,
  store: store,
  vuetify: vuetify,
  config: config,
  render: (h) => h(App),

  created() {
    router.afterEach((to, from, failure) => {
      if (!failure) {
        Vue.nextTick(() => {
          this.$posthog.capture('$pageview', { path: to.fullPath });
        });
      }
    });

    if (sessionStorage.redirect) {
      const redirect = sessionStorage.redirect;
      delete sessionStorage.redirect;
      this.$router.push(redirect);
    }
  },
});
