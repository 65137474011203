<template>
    <v-col cols="12" sm="6" md="3">
        <v-card :class="[{ 'project-container': !isDescriptionVisible }, 'expanded-project-container']" :to="url"
            @mouseover="startTimer" @mouseout="clearTimer" :disabled="!isSetupComplete">
            <v-card-title>{{ project.name }}</v-card-title>
            <v-card-text :style="containerStyle">
                <div :style="descriptionStyle">
                    {{ !isSetupComplete ? 'Project setup incomplete' : project.description }}
                </div>
            </v-card-text>
        </v-card>
    </v-col>
</template>

<style scoped>
.project-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 268px;
    background: white;
    border-radius: 4px;
    box-shadow: 0px 2px 5px #171a1f, 0px 0px 2px #171a1f;
}

.expanded-project-container {
    height: auto !important;
}
</style>

<script>

export default {
    props: {
        project: Object,
        isSetupComplete: Boolean,
    },
    data() {
        return {
            owned_projects: [],
            projectInfoDict: {},
            project_ids: [],
            isDescriptionVisible: false,
        }
    },
    computed: {
        url() {
            let project_id = this.project.id;
            let project_status = this.project.status;
            let status_redirect_mapping = {
                "board_setup_finished": `/project_dashboard/${project_id}`,
                "initial_tasks_guides_finished": `/project_dashboard/${project_id}`,
                "initial_tasks_cost_finished": `/project_dashboard/${project_id}`,
            }
            let redirect_url = status_redirect_mapping[project_status];
            return redirect_url;
        },
        containerStyle() {
            return {
                height: this.isDescriptionVisible ? 'auto' : '160px'
            };
        },
        descriptionStyle() {
            return {
                height: this.isDescriptionVisible ? 'auto' : '140px',
                overflow: 'hidden'
            };
        }
    },
    methods: {
        startTimer() {
            this.timer = setTimeout(this.showDescription, 1000);
        },
        clearTimer() {
            clearTimeout(this.timer);
            this.hideDescription();
        },
        showDescription() {
            this.isDescriptionVisible = true;
        },
        hideDescription() {
            this.isDescriptionVisible = false;
        }
    },
}
</script>