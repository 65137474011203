<template>
    <v-app>
        <v-container fluid>
            <v-breadcrumbs :items="breadcrumbLinks" large class="breadcrumbs-item"></v-breadcrumbs>
            <v-row>

                <!-- Main Column -->
                <v-col cols="12" md="9">

                    <!-- General Information Card -->
                    <v-card id="general-information">
                        <v-card-title>
                            <v-icon>mdi-information-outline</v-icon>
                            General Information
                        </v-card-title>
                        <v-card-text>
                            <!-- General information form -->
                            <v-form>
                                <v-row>
                                    <v-col cols="6">
                                        <h4>Task Title</h4>
                                        <v-text-field v-model="task.task_name" label="Task Title" dense solo flat
                                            outlined @input="changesMade = true"></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <h4>Epic</h4>
                                        <v-select v-model="task.epic_name" :items="epicList" label="Epic" dense solo
                                            flat outlined :menu-props="{ offsetY: true }"
                                            @input="changesMade = true"></v-select>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="4">
                                        <h4>Status</h4>
                                        <v-select v-model="task.status" :items="statusList" item-text="name"
                                            item-value="name" label="Status" dense solo flat outlined
                                            :menu-props="{ offsetY: true }" @input="changesMade = true">
                                            <template v-slot:item="{ item }">
                                                <div :style="{ color: item.color }">
                                                    {{ capitalizeFirstLetter(item.name) }}
                                                </div>
                                            </template>

                                            <template v-slot:selection="{ item, index }">
                                                <v-chip :input-value="index === 0"
                                                    :style="{ color: item.color, 'border-color': item.color }">
                                                    {{ capitalizeFirstLetter(item.name) }}
                                                </v-chip>
                                            </template>
                                        </v-select>
                                    </v-col>
                                    <v-col cols="4">
                                        <h4>Priority</h4>
                                        <v-select v-model="selectedPriority" :items="priorityList" item-text="name"
                                            item-value="order" label="Priority" dense solo flat outlined
                                            :menu-props="{ offsetY: true }" @change="handlePriorityChange">

                                            <template v-slot:item="{ item }">
                                                <div :style="{ color: item.color }">
                                                    {{ capitalizeFirstLetter(item.name) }}
                                                </div>
                                            </template>

                                            <template v-slot:selection="{ item, index }">
                                                <div :input-value="index === 0"
                                                    :style="{ color: item.color, 'border-color': item.color }">
                                                    {{ capitalizeFirstLetter(item.name) }}
                                                </div>
                                            </template>
                                        </v-select>
                                    </v-col>
                                    <v-col cols="4">
                                        <h4>Progress</h4>
                                        <v-progress-linear v-model="task.progress" height="25" class="mt-2" rounded>
                                            <strong
                                                :class="task.progress < 50 ? 'progress-color-black' : 'progress-color-white'">
                                                {{ task.progress }}%
                                            </strong>
                                        </v-progress-linear>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="4">
                                        <h4>Task Roles</h4>
                                        <v-select v-model="task.role" :items="roleList" label="Task Role" dense solo
                                            flat outlined multiple :menu-props="{ offsetY: true, maxHeight: '300' }"
                                            @input="changesMade = true"></v-select>
                                    </v-col>
                                    <v-col cols="4">
                                        <h4>Created Date</h4>
                                        <v-text-field :value="formatDate(task.created_date)" label="Due Date"
                                            append-icon="mdi-calendar" readonly dense solo flat outlined></v-text-field>
                                    </v-col>
                                    <v-col cols="4">
                                        <h4>Tools</h4>
                                        <v-combobox v-if="task && task.tools" v-model="task.tools" :items="toolsList"
                                            label="Tools" multiple outlined dense small-chips solo flat deletable-chips
                                            hide-no-data hide-selected @input="handleInput"
                                            :menu-props="{ offsetY: true, maxHeight: '300' }"></v-combobox>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="3">
                                        <h4>Start Date</h4>
                                        <v-menu v-model="menuStartDate" :close-on-content-click="false"
                                            :nudge-right="40" transition="scale-transition" offset-y min-width="auto">

                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field :value="formatDate(task.start_date)" label="Start Date"
                                                    append-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" dense
                                                    solo flat outlined></v-text-field>
                                            </template>
                                            <v-date-picker v-model="task.start_date"
                                                @input="changesMade = true"></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="3">
                                        <h4>Due Date</h4>
                                        <v-text-field :value="formatDate(task.end_date)" label="Due Date"
                                            append-icon="mdi-calendar" readonly dense solo flat outlined></v-text-field>

                                    </v-col>
                                    <v-col cols="2">
                                        <h4>T-Shirt Size</h4>
                                        <v-select v-model="task.tshirt" :items="tshirtList" item-text="T-Sirt Size"
                                            item-value="order" label="T-Shirt Size" dense solo flat outlined
                                            :menu-props="{ offsetY: true }" @input="changesMade = true">
                                        </v-select>
                                    </v-col>
                                    <v-col cols="2">
                                        <h4>Time (hs)</h4>
                                        <v-text-field v-model="task.time" label="Estimated Time" dense solo flat
                                            outlined @input="changesMade = true"></v-text-field>
                                    </v-col>
                                    <v-col v-if="user_profile.org_role_internal != 'organization_member'" cols="2">
                                        <h4>Cost</h4>
                                        <v-text-field v-model="task.cost" label="Estimated Cost" dense solo flat
                                            outlined :readonly="autoCalculateCost" @input="changesMade = true">

                                            <template v-slot:append-outer>
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon :color="autoCalculateCost ? 'primary500' : 'neutral300'"
                                                            v-bind="attrs" v-on="on" @click="toggleAutoCalculate">
                                                            {{ autoCalculateCost ? 'mdi-calculator-variant' :
                                                                'mdi-calculator-variant-outline' }}
                                                        </v-icon>
                                                    </template>
                                                    <span>Calculate automatic cost based on estimated time and
                                                        role</span>
                                                </v-tooltip>
                                            </template>
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="4">
                                        <h4>Assigned To</h4>
                                        <v-select v-model="task.assigned_to" :items="assignedToList" item-text="display"
                                            item-value="user_id" label="Assigned To" dense solo flat outlined
                                            :menu-props="{ offsetY: true, maxHeight: '300' }"
                                            @input="changesMade = true">
                                        </v-select>
                                    </v-col>
                                    <v-col cols="8">
                                        <h4>Dependencies</h4>
                                        <v-data-table :headers="headers" :items="task.dependencies_description"
                                            hide-default-footer hide-default-header dense @click:row="showTaskDetails"
                                            :items-per-page="-1" class="selectable-row">

                                            <template v-slot:item.priority="{ item }">
                                                <v-chip small dark :color="findPriorityObject(item.priority).color">
                                                    {{ findPriorityObject(item.priority).name }}
                                                </v-chip>
                                            </template>

                                            <template v-slot:item.status="{ item }">
                                                <v-chip :color="getStatusColor(item.status)" small dark>
                                                    {{ item.status }}
                                                </v-chip>
                                            </template>

                                            <template v-slot:item.start_date="{ item }">
                                                {{ formatDate(item.start_date) }}
                                            </template>

                                            <template v-slot:item.action="{ index: subindex }">
                                                <v-icon @click="removeDependency(subindex)"
                                                    color="error500">mdi-close</v-icon>
                                            </template>
                                        </v-data-table>
                                        <v-menu transition="slide-y-transition" maxHeight="300"
                                            :close-on-content-click="false">

                                            <template v-slot:activator="{ on, attrs }">
                                                <div class="d-flex justify-end">
                                                    <v-btn color="primary500" dark v-bind="attrs" v-on="on"
                                                        class="mt-2">
                                                        List of Tasks
                                                        <v-icon right>mdi-menu-down</v-icon>
                                                    </v-btn>
                                                </div>
                                            </template>

                                            <v-list :menu-props="{ offsetY: true, maxHeight: '300' }">
                                                <v-list-item v-for="(item, index) in availableDependencies" :key="index"
                                                    @click="addDependency(item)">
                                                    <v-list-item-content>
                                                        <v-list-item-title>
                                                            <strong>{{ item.epic_name }}</strong> - {{ item.name }}
                                                        </v-list-item-title>
                                                        <v-list-item-subtitle>
                                                            {{ formatDate(item.start_date) }} | {{ item.status }}
                                                        </v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-card-text>
                    </v-card>

                    <!-- Task Description Card -->
                    <v-card id="task-description" class="mt-5">
                        <v-card-title>
                            <v-icon>mdi-file-document-outline</v-icon>
                            Task Description
                        </v-card-title>
                        <v-card-text>
                            <v-form>
                                <v-row>
                                    <v-col>
                                        <h4>Description</h4>
                                        <v-textarea filled label="Description" auto-grow solo flat outlined dense
                                            rows="1" no-resize hide-details v-model="task.task_description"
                                            @input="changesMade = true"></v-textarea>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <h4>Proposed Approach </h4>
                                        <v-textarea filled label="Description" auto-grow solo flat outlined dense
                                            rows="1" no-resize hide-details v-model="task.task_guide"
                                            @input="changesMade = true">
                                            <template v-slot:append-outer>
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon color="success500" v-bind="attrs" v-on="on"
                                                            @click="getTaskGuideSuggestion">
                                                            mdi-auto-fix
                                                        </v-icon>
                                                    </template>
                                                    <span>Get a task step by step guide suggestion from Andenia</span>
                                                </v-tooltip>
                                            </template>
                                        </v-textarea>
                                        <v-progress-linear v-if="suggestionGuide" color="primary500" indeterminate
                                            rounded height="6"></v-progress-linear>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-card-text>
                    </v-card>

                    <!-- Comment Card -->
                    <v-card id="comments" class="mt-5">
                        <v-card-title>
                            <v-icon>mdi-message-reply-text-outline</v-icon>
                            Comments
                        </v-card-title>
                        <v-card-text>
                            <!-- List of current messages -->
                            <v-list>
                                <v-list-item v-for="(comment, index) in task.comments" :key="index">
                                    <v-list-item-avatar>
                                        <v-img :src="defaultAvatar"></v-img>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ comment.user_name }}</v-list-item-title>
                                        <v-list-item-subtitle>{{ comment.content }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                    <!--TODO: add frontend to edit and delete an existing comment-->
                                </v-list-item>
                            </v-list>
                            <!-- Area to add new comment -->
                            <v-textarea filled label="Leave a public comment" auto-grow solo flat outlined dense
                                rows="2" no-resize hide-details v-model="newCommentText"></v-textarea>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                            <v-btn color="primary500" dark @click="submitComment">Comment</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>

                <!-- Secondary Column - Sidebar/Right -->
                <v-col cols="12" md="3" class="sticky-column">
                    <v-card>
                        <v-card-title>
                            Section
                        </v-card-title>
                        <v-card-text>

                            <v-row>
                                <v-btn class="ml-5" @click="scrollToElement('general-information')">
                                    <v-icon>mdi-information-outline</v-icon>
                                    General Information
                                </v-btn>
                            </v-row>
                            <v-row>
                                <v-btn class="mt-3 ml-5" @click="scrollToElement('task-description')">
                                    <v-icon>mdi-file-document-outline</v-icon>
                                    Task Description
                                </v-btn>
                            </v-row>
                            <v-row>
                                <v-btn class="mt-3 ml-5 mb-3" @click="scrollToElement('comments')">
                                    <v-icon>mdi-message-reply-text-outline</v-icon>
                                    Comments
                                </v-btn>
                            </v-row>
                        </v-card-text>
                    </v-card>
                    <v-card v-if="changesMade" class="mt-3">
                        <v-card-text class="d-flex justify-center">
                            <v-btn class="flex-grow-1" color="success500" dark @click="saveTask">
                                Save
                            </v-btn>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-snackbar v-model="project_saved" timeout="2000" color="success500">
                <v-icon color="success100" medium>mdi-content-save-check</v-icon>
                Task correctly saved!
                <template v-slot:action="{ attrs }">
                    <v-btn text v-bind="attrs" @click="project_saved = false">
                        <v-icon color="success100">mdi-close</v-icon>
                    </v-btn>
                </template>
            </v-snackbar>

            <v-snackbar v-model="engineDown" color="warning500" multiLine>
                <v-icon color="warning100">mdi-alert</v-icon>
                Task guide suggestion failed. Andenia engine is currently down, please try again later
                <v-btn icon @click="engineDown = false">
                    <v-icon color="warning100">mdi-close</v-icon>
                </v-btn>
            </v-snackbar>
        </v-container>
    </v-app>
</template>

<style scoped>
.sticky-column {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    height: 100vh;
    overflow-y: auto;
}

.progress-color-black {
    color: var(--v-neutral800-base);
}

.progress-color-white {
    color: var(--v-neutral100-base);
}

.breadcrumbs-item {
    padding: 0px 15px;
}

.selectable-row {
    cursor: pointer;
}

::v-deep .v-select.v-input--dense .v-chip {
    margin: 3px;
}
</style>

<script>
import user_profile_mixin from "../mixins/user_profile.js";

const axios = require("axios").default;

export default {
    name: 'Task',
    data() {
        return {
            project_id: this.$route.params.project_id,
            task_id: this.$route.params.task_id,
            task: { task_name: 'loading...' }, //TODO: use vuetify skeleton loading
            autoCalculateCost: false,
            selectedPriority: null,
            newCommentText: '',
            project: {},
            epicList: [],
            statusList: [],
            priorityList: [],
            tshirtList: ['XS', 'S', 'M', 'L', 'XL'],
            roleList: [],
            toolsList: [],
            taskDependenciesList: [],
            assignedToList: [],
            headers: [
                { text: 'Task Name', value: 'name', sortable: false },
                { text: 'Priority', value: 'priority', align: 'end', sortable: false },
                { text: 'Status', value: 'status', align: 'start', sortable: false },
                { text: 'Start Date', value: 'start_date', align: 'center', width: '200px', sortable: false },
                { text: 'Action', value: 'action', align: 'center', sortable: false },
            ],
            changesMade: false,
            defaultAvatar: require("@/assets/images/user.png"),
            items: [],
            menuStartDate: false,
            project_saved: false,
            user_profile: { org_role_internal: "organization_member" },
            engineDown: false,
            suggestionGuide: false,
        };
    },
    mixins: [user_profile_mixin],
    beforeMount() {
        window.addEventListener("beforeunload", this.beforeNav)
        this.$once("hook:beforeDestroy", () => {
            window.removeEventListener("beforeunload", this.beforeNav);
        })
    },
    beforeRouteLeave(to, from, next) {
        if (this.changesMade) {
            const answer = window.confirm('Do you really want to leave? you have unsaved changes!');
            if (answer) {
                this.changesMade = false;
                next();
            } else {
                return next(false);
            }
        }
        next();
    },
    beforeRouteUpdate(to, from, next) {
        const handleNavigation = () => {
            if (to.params.task_id !== this.task_id) {
                this.task_id = to.params.task_id;
                this.fetchTaskInfo().then(() => {
                    next();
                }).catch(() => {
                    next(false);
                });
            } else {
                next();
            }
        };

        if (this.changesMade) {
            const answer = window.confirm('Do you really want to leave? you have unsaved changes!');
            if (answer) {
                this.changesMade = false;
                handleNavigation();
            } else {
                next(false);
            }
        } else {
            handleNavigation();
        }
    },
    watch: {
        // Verify changes in start date
        'task.start_date': function (newStartDate, oldStartDate) {
            this.calculateDueDate();
        },
        // Verify changes in estimated time
        'task.time': function (newEstimatedTime, oldEstimatedTime) {
            this.calculateDueDate();
            if (this.autoCalculateCost) {
                this.calculateCost();
            }
        },
        // Verify changes in role
        'task.role': function (newRole, oldRole) {
            if (this.autoCalculateCost) {
                this.calculateCost();
            }
        },
        // Verify changes in role
        'task.assigned_to': function (newRole, oldRole) {
            if (this.autoCalculateCost) {
                this.calculateCost();
            }
        },
        'task.priority'(newValue) {
            this.selectedPriority = newValue;
        },
        'task.progress'(newValue, oldValue) {
            const newValueInt = Math.round(newValue / 5) * 5;
            if (oldValue !== undefined && newValueInt !== this.task.progress) {
                this.task.progress = newValueInt;
                this.changesMade = true;
            }
        },
    },
    computed: {
        availableDependencies() {
            if (!this.task || !this.task.epic_name) {
                return [];
            }
            return this.taskDependenciesList.filter(dep => {
                return dep.epic_name === this.task.epic_name &&
                    !this.task.dependencies_description.some(selectedDep => selectedDep.id === dep.id);
            });
        },
        breadcrumbLinks() {
            if ('name' in this.project && 'task_name' in this.task) {
                let items = [
                    {
                        text: 'Projects',
                        link: true,
                        exact: true,
                        disabled: false,
                        to: {
                            name: 'Projects',
                        }
                    },
                    {
                        text: this.project.name,
                        link: true,
                        exact: true,
                        disabled: false,
                        to: {
                            name: 'Project',
                            params: {
                                project_id: this.project.id
                            }
                        }
                    },
                    {
                        text: this.task.task_name,
                        disabled: true,
                    },
                ];
                return items;
            } else {
                return [];
            }
        }
    },
    methods: {
        updateProgress(newValue) {
            this.task.progress = Math.floor(newValue);
        },
        formatDate(dateValue) {
            if (!dateValue) return null;
            const d = new Date(dateValue);
            const options = { year: 'numeric', month: 'short', day: 'numeric' };
            return d.toLocaleDateString('en-US', options);
        },
        beforeNav(event) {
            if (!this.changesMade) {
                return
            } else {
                event.preventDefault()
                this.changesMade = false;
                return
            }
        },
        getTaskGuideSuggestion() {
            this.suggestionGuide = true;
            let guide_payload = { task_description: this.task.task_description, time: this.task.time, tools: this.task.tools };
            this.axios.post(`tasks/${this.project.id}/guide_suggestion`, guide_payload)
                .then((res) => {
                    this.task.task_guide = res.data.task_guide;
                    this.suggestionGuide = false;
                    this.changesMade = true;
                    return;
                })
                .catch((err) => {
                    console.log(err);
                    this.engineDown = true;
                    return;
                });
        },
        fetchProjectInfo() {
            if (this.project_id == null) {
                return Promise.reject("No project ID");
            }
            return axios
                .get(`/projects/${this.project_id}`)
                .then((response) => {
                    this.project = response.data;
                    let project_status = response.data.status;
                    const valid_states = ["board_setup_finished", "initial_tasks_guides_finished", "initial_tasks_cost_finished"];
                    if (!valid_states.includes(project_status)) {
                        this.$router.push(`/projects`);
                    }
                })
                .catch((error) => {
                    console.log(
                        "Error occurred while fetching project with id: " + this.project_id
                    );
                    this.$router.push(`/projects`);
                    return Promise.reject(error);
                });
        },
        fetchTaskInfo() {
            if (this.task_id == null) {
                return Promise.reject("No task ID");
            }
            return axios
                .get(`/tasks/${this.project_id}/${this.task_id}`)
                .then((response) => {
                    this.task = response.data.task;
                    this.task.start_date = this.formatStartDateForPicker(response.data.task.start_date);
                })
                .catch((error) => {
                    console.log(
                        "Error occurred while fetching task with id: " + this.task_id
                    );
                    this.$router.push(`/projects`);
                    return Promise.reject(error);
                });
        },
        createAssignedList() {
            const taskRoles = this.task.role;

            const matchedUsers = [];
            const otherUsers = [];

            this.project.project_team.forEach(user => {
                if (taskRoles.includes(user.project_role)) {
                    matchedUsers.push({
                        ...user,
                        display: `${user.full_name} - ${user.project_role}`
                    });
                } else {
                    otherUsers.push({
                        ...user,
                        display: `${user.full_name} - ${user.project_role}`
                    });
                }
            });

            this.assignedToList = [...matchedUsers, ...otherUsers];
        },
        saveTask() {
            console.log("Save: ", this.task)

            let payload = {
                fields: {
                    epic_name: this.task.epic_name,
                    task_name: this.task.task_name,
                    task_description: this.task.task_description,
                    tools: this.task.tools,
                    role: this.task.role,
                    time: this.task.time || 0,
                    tshirt: this.task.tshirt,
                    cost: this.task.cost || 0,
                    start_date: this.task.start_date,
                    end_date: this.task.end_date,
                    task_guide: this.task.task_guide,
                    dependencies: this.task.dependencies,
                    status: this.task.status,
                    priority: this.task.priority,
                    assigned_to: this.task.assigned_to,
                    progress: this.task.progress,
                }
            };
            this.axios
                .post(`tasks/${this.project_id}/${this.task.id}/edit`, payload)
                .then(response => {
                    this.changesMade = false;
                    this.project_saved = true;
                    console.log('Task created successfully', response.data);
                })
                .catch(error => {
                    console.error('Error creating task', error);
                });
        },
        prepareTaskOptions() {
            if (this.project && this.project.roles) {
                this.project.roles.forEach(roles => {
                    if (!this.roleList.includes(roles.name)) {
                        this.roleList.push(roles.name);
                    }
                });
            }
            if (this.project && this.project.tasks) {
                this.project.tasks.forEach(task => {
                    if (!this.epicList.includes(task.epic_name)) {
                        this.epicList.push(task.epic_name);
                    }
                    if (!this.taskDependenciesList.includes(task.task_id)) {
                        this.taskDependenciesList.push({ id: task.task_id, epic_name: task.epic_name, name: task.task_name, priority: task.priority, start_date: task.start_date, status: task.status })
                    }
                    if (task.tools && Array.isArray(task.tools)) {
                        task.tools.forEach(tool => {
                            if (!this.toolsList.includes(tool)) {
                                this.toolsList.push(tool)
                            }
                        });
                    }
                });
            }
            if (this.project && this.project.task_status_options) {
                this.project.task_status_options.forEach(task_status => {
                    let statusExists = this.statusList.find(status => status.name === task_status.name);
                    if (!statusExists) {
                        this.statusList.push({ name: task_status.name, color: task_status.color });
                    }
                });
            }
            if (this.project && this.project.task_priority_options) {
                this.project.task_priority_options.forEach(task_priority => {
                    let priorityExists = this.priorityList.find(priority => priority.name === task_priority.name);
                    if (!priorityExists) {
                        this.priorityList.push({ name: task_priority.name, color: task_priority.color, order: task_priority.order });
                    }
                });
            }
        },
        getStatusColor(statusName) {
            const status = this.statusList.find(s => s.name === statusName);
            return status ? status.color : '#6f6f6f';
        },
        formatStartDateForPicker(start_date) {
            const date = new Date(start_date);
            if (isNaN(date.getTime())) {
                return null;
            }

            const year = date.getFullYear();
            const month = (`0${date.getMonth() + 1}`).slice(-2);
            const day = (`0${date.getDate()}`).slice(-2);
            return `${year}-${month}-${day}`;
        },
        calculateDueDate() {
            if (!this.task.start_date || !this.task.time) {
                return; // Do nothing if start_date and end_date doesn't exist
            }

            const startDate = new Date(this.task.start_date);
            const daysToAdd = this.task.time / 8; // Calculates days based on an 8 hour day

            // Add the days to the start_date to get the due_date
            startDate.setDate(startDate.getDate() + daysToAdd);

            // Update due_date in desired format, adjust as needed
            this.task.end_date = startDate.toISOString().split('T')[0];
        },
        calculateCost() {
            if (!this.autoCalculateCost || !this.task.time || this.task.role.length === 0) {
                this.task.cost = 0;
                return this.task.cost;
            }

            // Check if there's an assigned user and get their cost_per_hour
            if (this.task.assigned_to) {
                const assignedUser = this.project.project_team.find(user => user.user_id === this.task.assigned_to);
                if (assignedUser) {
                    this.task.cost = this.task.time * assignedUser.cost_per_hour;
                    return this.task.cost;
                }
            }

            // Find the highest cost among the selected roles
            const maxRoleCost = this.task.role
                .map(roleName => {
                    const role = this.project.roles.find(r => r.name === roleName);
                    return role ? role.cost : 0;
                })
                .reduce((max, current) => Math.max(max, current), 0);

            // Calculate the total cost by multiplying the estimated time by the highest cost per hour
            this.task.cost = this.task.time * maxRoleCost;
        },
        toggleAutoCalculate() {
            this.autoCalculateCost = !this.autoCalculateCost;
            if (this.autoCalculateCost) {
                this.calculateCost(); // Recalculate cost if auto-calculate is turned on
            }
        },
        scrollToElement(elementId) {
            const element = document.getElementById(elementId);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        },
        submitComment() {
            const newComment = {
                content: this.newCommentText,
                user_name: this.user_profile.user_name
            };

            this.axios
                .post(`tasks/${this.project_id}/${this.task.id}/comment`, {content: this.newCommentText})
                .then(response => {
                    // Add the new comment to the comments array
                    this.task.comments.push(newComment);
                })
                .catch(error => {
                    console.error('Error adding comment', error);
                });

            // Clear new comment text
            this.newCommentText = '';
        },
        handleInput(newSelectedTools) {
            this.changesMade = true;
            const newTools = newSelectedTools.filter(tool => !this.toolsList.includes(tool));
            this.toolsList.push(...newTools);
        },
        addDependency(item) {
            this.task.dependencies_description.push(item);
            this.taskDependenciesList = this.taskDependenciesList.filter(dependency => dependency.id !== item.id);
        },

        removeDependency(index) {
            const itemToRemove = this.task.dependencies_description[index];
            this.taskDependenciesList.push(itemToRemove);
            this.task.dependencies_description.splice(index, 1);
        },
        handlePriorityChange(newOrder) {
            this.task.priority = newOrder;
            this.changesMade = true;
        },
        capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
        findPriorityObject(order) {
            return this.priorityList.find(p => p.order === order) || {};
        },
        showTaskDetails: function (value) {
            const task = this.project.tasks.find(task => task.task_name === value.name);
            console.log(task)

            if (task && task.id) {
                this.$router.push(`/project/${this.project.id}/task/${task.id}`);
            } else {
                console.error('Task ID is missing or task not found');
            }
        },
    },
    created() {
        Promise.all([this.fetchUserProfile(), this.fetchProjectInfo(), this.fetchTaskInfo()])
            .then(() => {
                this.prepareTaskOptions();
                this.createAssignedList();
            })
            .catch((error) => {
                console.error("Error fetching project or task info:", error);
            });
    }
}

</script>
