<template>
    <div class="app">
        <v-app-bar color="neutral800" dark>
            <v-toolbar-title class="ml-4">
                <router-link to="/projects" class="white--text">
                    <v-img alt="Andenia" class="shrink mr-2" contain src="../assets/images/andenia_logo.png"
                        transition="scale-transition" width="40" height="40" />
                </router-link>
            </v-toolbar-title>
            <v-spacer></v-spacer>

            <v-stepper v-model="selectedStep" class="stepper">
                <v-stepper-header>
                    <v-stepper-step v-for="(step, index) in steps" color='primary500' :key="step"
                        :complete="step < selectedStep" :step="step">
                        {{ stepNames[index] }}
                        <v-icon v-if="index < steps.length - 1"
                            :color="step <= selectedStep ? 'primary500' : ''">mdi-chevron-right</v-icon>
                    </v-stepper-step>
                </v-stepper-header>
            </v-stepper>

            <v-spacer></v-spacer>
            <v-btn icon class="mr-4" @click="startTour" color="neutral200">
                <v-icon>mdi-help-circle-outline</v-icon>
            </v-btn>
        </v-app-bar>
        <v-container class="content-container">
            <v-row>
                <div class="header-container">
                    <div class="heading-container">
                        <h1>Task Validation</h1>
                    </div>
                    <div class="button-header">
                        <v-btn class="splitbot-epic-button mr-4" color="primary500" outlined @click="newEpic">
                            <v-icon>mdi-plus</v-icon>
                            New Epic
                        </v-btn>
                        <v-btn color="success500" @click="dependenciesTasks" :disabled="invalidTaskIndices.length > 0">
                            <v-icon>mdi-check-circle-outline</v-icon>
                            Done
                        </v-btn>
                    </div>
                </div>
                <v-expansion-panels class="panel mb-10" focusable ref="expansionPanels">
                    <v-expansion-panel v-for="(epic, index) in sortByEpicId(filteredTasks)" :key="index">
                        <v-expansion-panel-header
                            :disable-icon-rotate="invalidTaskIndices.some(item => item.index === index)"
                            :class="{ 'active-header': activePanel === index }" @click="togglePanel(index)">
                            <v-layout row align-center justify-space-between>
                                <v-flex class="ml-4">
                                    {{ epic.epic_name }}
                                </v-flex>
                                <v-flex xs4>
                                    <v-layout row align-center justify-end>
                                        <v-btn @click.stop="newTask(epic)" class="button_add_row"
                                            :class="{ 'show-button': activePanel === index }">
                                            <v-icon>mdi-plus</v-icon>New Task
                                        </v-btn>

                                        <v-icon @click.stop="confirmDeleteEpic(index)" class="icon_delete_row mr-7"
                                            :class="{ 'show-button': activePanel === index }">mdi-delete</v-icon>
                                    </v-layout>
                                </v-flex>
                            </v-layout>
                            <template v-slot:actions>
                                <v-tooltip bottom v-if="invalidTaskIndices.some(item => item.index === index)">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon color="warning500" v-bind="attrs" v-on="on">
                                            mdi-alert
                                        </v-icon>
                                    </template>
                                    <span>{{ invalidTaskIndices.find(item => item.index === index).message }}</span>
                                </v-tooltip>
                                <v-icon v-else>
                                    mdi-chevron-down
                                </v-icon>
                            </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-data-table :headers="headers" :items="epic.tasks" hide-default-footer expand-icon
                                :items-per-page="-1">
                                <template v-slot:item="{ item: task, index: taskIndex }">
                                    <tr :class="{ 'highlighted-row': temporarilyHighlightedRows.includes(taskIndex) }">
                                        <td>{{ task.task_name }}</td>
                                        <td>{{ task.tshirt }}</td>
                                        <td>{{ task.time }}</td>
                                        <td>{{ task.task_description }}</td>
                                        <td>{{ task.role.join(',\n') }}</td>
                                        <td>
                                            <div class="my-step-container">
                                                <v-btn dense color="primary500" dark @click="splitTask(task)"
                                                    :disabled="!task.can_split">
                                                    Split
                                                    <v-icon right>mdi-auto-fix</v-icon>
                                                </v-btn>
                                            </div>
                                        </td>
                                        <td>
                                            <v-icon class="mr-2" @click="editItem(task)">mdi-pencil</v-icon>
                                            <v-icon @click="confirmDeleteRow(epic, taskIndex)">mdi-delete</v-icon>
                                        </td>
                                    </tr>
                                </template>
                            </v-data-table>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-row>
            <v-overlay :value="overlay">
                <v-card class="dialog-content">
                    <v-card-title>
                        <h2>
                            {{ dialogTitle }}
                        </h2>
                    </v-card-title>
                    <v-progress-circular :size="70" :width="7" color="primary500" indeterminate></v-progress-circular>
                </v-card>
            </v-overlay>
        </v-container>

        <v-dialog v-model="dialog_epic" max-width="400">
            <v-card>
                <v-card-title>
                    New Epic
                </v-card-title>
                <v-card-text>
                    <v-text-field v-model="newEpicName" label="Epic Name"></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="error500" text @click="closeEpic">Cancel</v-btn>
                    <v-btn color="success500" text @click="newTaskEpic()">Add</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="showEditDialog" max-width="500px" bottom>
            <v-card>
                <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text>
                    <v-form v-model="valid" ref="form" lazy-validation>
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                <v-text-field v-model="editedItem.task" label="Task" :rules="taskRules"
                                    required></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <v-text-field v-model="editedItem.tshirt" label="T-Shirt Size" :rules="tshirtRules"
                                    @blur="forceUppercase" required></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <v-text-field v-model="editedItem.time" label="Time [h]" :rules="timeRules"
                                    type="number" required></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <v-text-field v-model="editedItem.description" label="Description"
                                    :rules="descriptionRules" required></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <v-select v-model="editedItem.role" :items="states" :menu-props="{ maxHeight: '400' }"
                                    label="Select" multiple hint="Pick Role" persistent-hint :rules="roleRules"
                                    required></v-select>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary500" text @click="cancelEdit">Cancel</v-btn>
                    <v-btn color="primary500" text @click="saveEdit" :disabled="!valid">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="showDeleteDialog" max-width="500">
            <v-card>
                <v-card-title class="headline">Confirm Deletion</v-card-title>
                <v-card-text>{{ deleteConfirmationMessage }}</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="success500" text @click="cancelDelete">Cancel</v-btn>
                    <v-btn color="error500" text @click="confirmDelete">Delete</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-snackbar v-model="creation_failed_snackbar" color="warning500" multiLine>
            <v-icon color="warning100">mdi-alert</v-icon>
            {{ error_message }}
            <v-btn icon @click="creation_failed_snackbar = false">
                <v-icon color="warning100">mdi-close</v-icon>
            </v-btn>
        </v-snackbar>

        <v-snackbar v-model="task_splited" color="info500" multiLine timeout="10000">
            <v-icon color="info100">mdi-alert</v-icon>
            The task was splitted into {{ num_tasks_splitted }} tasks
            <v-btn icon @click="task_splited = false">
                <v-icon color="info100">mdi-close</v-icon>
            </v-btn>
        </v-snackbar>
    </div>
</template>

<style scoped>
.header-container {
    display: flex;
    margin-top: 65px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.panel {
    margin-top: 20px;
    font-family: Inter;
}

.v-expansion-panel-header {
    height: 55px;
}

.active-header {
    background-color: var(--v-primary500-base);
    color: var(--v-neutral100-base);
    font-weight: bold;
    font-family: Inter;
}

.dialog-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    border-radius: 8px;
    background-color: var(--v-neutral100-base) !important;
    color: var(--v-neutral800-base) !important
}

.blur {
    filter: blur(3px);
    pointer-events: none;
}

.button_add_row {
    min-width: fit-content;
    float: right;
    margin-right: 10px;
    color: var(--v-primary500-base);
    box-shadow: none;
    border: 2px solid var(--v-primary500-base);
    border-radius: 4px;
    opacity: 0;
    transition: opacity 0.3s ease;
    pointer-events: none;
}

.icon_delete_row {
    min-width: fit-content;
    float: right;
    margin-right: 10px;
    color: var(--v-error400-base);
    box-shadow: none;
    opacity: 0;
    transition: opacity 0.3s ease, background-color 0.3s ease, padding 0.3s ease;
    pointer-events: none;
    border-radius: 50%;
}

.icon_delete_row:hover {
    background-color: var(--v-error100-base);
    ;
    /* Cambia el color de fondo a blanco */
    border-radius: 50%;
    padding: 5px;
}

.show-button {
    pointer-events: visible;
    opacity: 1;
}

.stepper {
    background-color: transparent !important;
    box-shadow: none !important;
}

.highlighted-row {
    transition: background-color 1.5s ease;
    background-color: var(--v-primary200-base);
}
</style>

<script>
import axios from 'axios';

export default {
    name: 'Task Validation',
    data() {
        return {
            taskRules: [
                v => !!v || 'Task name is required'
            ],
            tshirtRules: [
                v => !!v || 'T-Shirt Size is required',
                v => ['XS', 'S', 'M', 'L', 'XL'].includes((v || "").toUpperCase()) || 'Size must be XS, S, M, L, or XL'
            ],
            timeRules: [
                v => (v !== null && v !== undefined && v !== '') || 'Time is required',
                v => (!isNaN(parseFloat(v)) && parseFloat(v) > 0) || 'Time must be a positive number greater than zero'
            ],
            descriptionRules: [
                v => !!v || 'Description is required'
            ],
            roleRules: [
                v => (v && v.length > 0) || 'At least one role must be selected'
            ],
            valid: false,
            epics: [],
            id: this.$route.params.project_id,
            splitOptions: Array.from({ length: 5 }, (_, i) => i + 2),
            activePanel: null,
            overlay: false,
            dialogTitle: "",
            showEditDialog: false,
            headers: [
                { text: 'Task', sortable: false, value: 'task', width: '200px' },
                { text: 'T-Shirt Size', align: 'center', value: 'tshirt', sortable: false, width: '90px' },
                { text: 'Time [h]', align: 'center', value: 'time', sortable: false, width: '90px' },
                { text: 'Description', value: 'description', sortable: false },
                { text: 'Role', value: 'role', sortable: false, width: '170px' },
                { text: 'Split', align: 'center', value: 'split', sortable: false, width: '150px' },
                { text: 'Actions', align: 'center', value: 'actions', sortable: false, width: '90px' },
            ],
            tasks_id: -1,
            editedItem: {
                task: '',
                tshirt: '',
                time: '',
                description: '',
                role: null,
            },
            defaultItem: {
                task: '',
                tshirt: '',
                time: '',
                description: '',
                role: null,
            },
            selectedRole: [],
            states: [],
            showIcon: -1,
            formTitle: [],
            editMode: false,
            epicMode: false,
            dialog_epic: false,
            newEpicName: '',
            selectedStep: 1,
            steps: [1, 2, 3, 4],
            stepNames: ['Description', 'Validation', 'Time and Dependencies', 'Finish'],
            tourResumed: false,
            onboarding_step: null,
            isTourRestarted: false,
            tour: null,
            continuedTour: null,
            creation_failed_snackbar: false,
            task_splited: false,
            num_tasks_splitted: null,
            error_message: null,
            deletingEpic: null,
            deletingEpicIndex: null,
            deletingRowIndex: null,
            deleteConfirmationMessage: '',
            showDeleteDialog: false,
            temporarilyHighlightedRows: [],
        };
    },
    methods: {
        isRowHighlighted(index) {
            return this.highlightedRows.includes(index);
        },
        forceUppercase() {
            this.editedItem.tshirt = this.editedItem.tshirt?.toUpperCase() || "";
        },
        startTour() {
            this.$posthog.capture('onboarding_tour_restarted_task_validation');
            if (this.tour) {
                this.tourResumed = true;
                this.isTourRestarted = true;
                this.tour.start();
            }
        },
        postFlagOnboarding() {
            this.tourResumed = false;
            if (!this.isTourRestarted) {
                this.onboarding_step = 5;
                this.axios.post('/user/onboarding', {
                    step: this.onboarding_step,
                })
                    .then((response) => {
                        console.log(response.data);
                        this.tour.cancel();
                        this.continuedTour.cancel();
                    })
                    .catch((error) => {
                        console.log(`Sorry, no post flag. ${error}`);
                    })
            }
            else {
                this.tour.cancel();
                this.continuedTour.cancel();
            }
        },
        getFlagOnboarding() {
            this.axios.get('/user/onboarding')
                .then((response) => {
                    console.log(response.data);

                    this.onboarding_step = response.data.step;
                    if (response.data.step <= 4) {
                        this.tourResumed = true;
                        this.tour.start();
                    }
                })
                .catch((error) => {
                    console.log(`Sorry, no flag. ${error}`);
                })
        },
        resumeTour() {
            this.continuedTour = new this.$shepherd.Tour({
                useModalOverlay: true,
                modal: true,
                exitOnEsc: true,
                keyboardNavigation: true,
            });

            this.continuedTour.defaultStepOptions = {
                classes: 'shepherd-theme-dark',
                scrollTo: true,
            };

            this.continuedTour.addStep({
                title: '(1/2) Add new task',
                text: 'If necessary, you can add new tasks to the generated epics.',
                attachTo: {
                    element: '.panel .v-expansion-panel--active .button_add_row',
                    on: 'left'
                },
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 8,
                canClickTarget: false,
                buttons: [
                    {
                        text: 'Skip all tips',
                        classes: 'shepherd-button-secondary',
                        action: () => this.postFlagOnboarding()
                    },
                    {
                        text: 'Got it',
                        classes: 'shepherd-button-primary',
                        action: this.continuedTour.next
                    }
                ],
            });

            this.continuedTour.addStep({
                title: '(2/2) Task Split',
                text: 'Utilize Andenia to split large tasks into smaller, manageable ones.',
                attachTo: {
                    element: '.panel .v-expansion-panel--active .my-step-container',
                    on: 'bottom-end'
                },
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 4,
                canClickTarget: false,
                buttons: [
                    {
                        text: 'Skip all tips',
                        classes: 'shepherd-button-secondary',
                        action: () => this.postFlagOnboarding()
                    },
                    {
                        text: 'Finish Tour',
                        classes: 'shepherd-button-primary',
                        action: () => this.postFlagOnboarding()
                    }
                ],
            });

            // Iniciar la segunda parte del tour
            this.continuedTour.start();
        },
        newEpic() {
            // Open Epic dialog
            this.dialog_epic = true;
        },
        newTaskEpic() {
            this.$posthog.capture('task_validation_new_epic', {
                project_id: this.id,
                epic_name: this.newEpicName
            });
            this.dialog_epic = false;
            this.editMode = false;
            this.epicMode = true;
            this.formTitle = 'First Task';
            this.editedItem = {
                task: this.defaultItem.task,
                tsirt: this.defaultItem.tshirt,
                time: this.defaultItem.time,
                description: this.defaultItem.description,
                role: this.defaultItem.role
            }
            this.showEditDialog = true;
        },
        closeEpic() {
            // Close the Epic dialog and reset the name to the default.
            this.dialog_epic = false;
            this.newEpicName = '';
        },

        togglePanel(index) {
            // Analyze which epic is active.
            this.activePanel = this.activePanel === index ? null : index;
            if (this.tourResumed && this.activePanel === index) {
                setTimeout(() => {
                    this.resumeTour();
                }, 300);
                this.tourResumed = false;
            }
        },
        newTask(epic) {
            // Get the epics's current number
            const epic_id = epic.tasks[0].epic_id;

            // Find the number of the last task for the current epic
            let lastTaskNumber = 0;
            for (const task of epic.tasks) {
                if (task.epic_id === epic_id) {
                    const taskNumber = parseInt(task.task_id, 10);
                    if (!isNaN(taskNumber) && taskNumber > lastTaskNumber) {
                        lastTaskNumber = taskNumber;
                    }
                }
            }

            // Increase the last task number by one to get the new task_id
            const newTaskNumber = lastTaskNumber + 1;

            // Build the new task_id in the desired format
            const new_task_id = newTaskNumber;

            // Set new_task_id and other values
            this.formTitle = 'New Task';
            this.editMode = false;
            this.epicMode = false;
            this.task_id = new_task_id;
            this.editedItem = {
                task: this.defaultItem.task,
                tshirt: this.defaultItem.tshirt,
                time: this.defaultItem.time,
                description: this.defaultItem.description,
                role: this.defaultItem.role,
                cost: 0
            }
            this.showEditDialog = true;
        },
        cancelEdit() {
            this.showEditDialog = false;
            this.$refs.form.reset();
        },
        editItem(item) {
            this.formTitle = 'Edit Task';
            this.editMode = true;
            this.tasks_id = item.id;
            this.editedItem = {
                task: item.task_name,
                tshirt: item.tshirt,
                time: item.time,
                description: item.task_description,
                role: item.role
            };
            this.showEditDialog = true;
            this.$nextTick(() => {
                this.$refs.form.validate();
            });

        },
        saveEdit() {
            if (this.$refs.form.validate()) {
                if (this.editMode) {
                    // Edition mode
                    let task_id = this.tasks_id;
                    this.tasks_id = -1;

                    let payload = {
                        fields: {
                            task_name: this.editedItem.task,
                            tshirt: this.editedItem.tshirt,
                            time: this.editedItem.time,
                            task_description: this.editedItem.description,
                            role: this.editedItem.role,
                            cost: 0
                        }

                    };

                    this.axios
                        .post(`/tasks/${this.id}/${task_id}/edit`, payload)
                        .then((response) => {
                            console.log(response.data);

                            const epic = this.epics[this.activePanel];
                            const editedTaskIndex = epic.tasks.findIndex(task => task.id === task_id);

                            if (editedTaskIndex !== -1) {
                                epic.tasks[editedTaskIndex].task_name = this.editedItem.task;
                                epic.tasks[editedTaskIndex].tshirt = this.editedItem.tshirt;
                                epic.tasks[editedTaskIndex].time = this.editedItem.time;
                                epic.tasks[editedTaskIndex].task_description = this.editedItem.description;
                                epic.tasks[editedTaskIndex].role = this.editedItem.role;
                            }

                            this.showEditDialog = false;
                            this.$posthog.capture('task_validation_task_edited', {
                                project_id: this.id,
                                epic_name: epic.epic_name,
                                task_id: task_id,
                                task_name: this.editedItem.task
                            });

                        })
                        .catch((error) => {
                            console.log(`Sorry, the task couldn't be updated: ${error}`);
                        });
                } else {
                    let payload;

                    if (this.epicMode) {
                        payload = {
                            tasks: [
                                {
                                    epic_id: this.epics.length,
                                    task_id: 1,
                                    epic_name: this.newEpicName,
                                    task_name: this.editedItem.task,
                                    tshirt: this.editedItem.tshirt,
                                    time: this.editedItem.time,
                                    task_description: this.editedItem.description,
                                    role: this.editedItem.role,
                                    cost: 0
                                }
                            ]
                        };
                    } else {
                        payload = {
                            tasks: [
                                {
                                    epic_id: this.epics[this.activePanel].tasks[0].epic_id,
                                    task_id: this.tasks_id,
                                    epic_name: this.epics[this.activePanel].epic_name,
                                    task_name: this.editedItem.task,
                                    tshirt: this.editedItem.tshirt,
                                    time: this.editedItem.time,
                                    task_description: this.editedItem.description,
                                    role: this.editedItem.role,
                                    cost: 0
                                }
                            ]
                        };
                    }

                    this.axios
                        .post(`/tasks/${this.id}/create_tasks`, payload)
                        .then((response) => {
                            console.log(response.data);

                            if (this.epicMode) {
                                const newEpic = {
                                    tasks: [
                                        {
                                            cost: response.data.tasks[0].cost,
                                            dependencies: response.data.tasks[0].dependencies,
                                            end_date: null,
                                            id: response.data.tasks[0].id,
                                            project_id: this.id,
                                            role: this.editedItem.role,
                                            selected: false,
                                            start_date: null,
                                            status: null,
                                            task_id: response.data.tasks[0].task_id,
                                            task_name: this.editedItem.task,
                                            task_description: this.editedItem.description,
                                            task_guide: null,
                                            epic_id: response.data.tasks[0].epic_id,
                                            epic_name: this.newEpicName,
                                            time: this.editedItem.time,
                                            tshirt: this.editedItem.tshirt,
                                            tools: response.data.tasks[0].tools,
                                            can_split: response.data.tasks[0].can_split,
                                        }
                                    ],
                                    epic_name: this.newEpicName
                                };

                                this.epics.push(newEpic);
                            } else {
                                const newTask = {
                                    id: response.data.tasks[0].id,
                                    cost: response.data.tasks[0].cost,
                                    dependencies: response.data.tasks[0].dependencies,
                                    project_id: this.id,
                                    task_id: response.data.tasks[0].task_id,
                                    task_name: this.editedItem.task,
                                    time: this.editedItem.time,
                                    tshirt: this.editedItem.tshirt,
                                    epic_id: response.data.tasks[0].epic_id,
                                    task_description: this.editedItem.description,
                                    role: this.editedItem.role,
                                    tools: response.data.tasks[0].tools,
                                    can_split: response.data.tasks[0].can_split,
                                };


                                // If it's not a new epic, add it to the active panel if exists
                                const epic = this.epics[this.activePanel];
                                if (epic) {
                                    this.$posthog.capture('task_validation_new_task_created', {
                                        project_id: this.id,
                                        epic_name: epic.epic_name,
                                        task_id: response.data.tasks[0].id,
                                        task_name: this.editedItem.task
                                    });
                                    epic.tasks.push(newTask);

                                    // Resaltar la nueva fila temporalmente
                                    const newTaskIndex = epic.tasks.length - 1;
                                    this.temporarilyHighlightedRows.push(newTaskIndex);
                                    console.log(this.temporarilyHighlightedRows)
                                    setTimeout(() => {
                                        this.temporarilyHighlightedRows = [];
                                    }, 10000);

                                } else {
                                    console.error("No active epic to add the new task.");
                                }
                            }
                            this.showEditDialog = false;
                        })
                        .catch((error) => {
                            console.log(`Sorry, the new task couldn't be added: ${error}`);
                        });
                }
            }
        },

        confirmDeleteRow(epic, index) {
            const taskName = epic.tasks[index].task_name;
            this.deletingEpic = epic;
            this.deletingRowIndex = index;
            this.deleteConfirmationMessage = `Are you sure you want to delete "${taskName}"?`;

            this.showDeleteDialog = true;
        },
        confirmDeleteEpic(index) {
            const epicName = this.epics[index].epic_name;
            this.deletingEpicIndex = index;
            this.deleteConfirmationMessage = `Are you sure you want to delete the epic "${epicName}" and all its tasks?`;
            this.showDeleteDialog = true;
        },

        cancelDelete() {
            this.resetDeleteState();
        },

        resetDeleteState() {
            this.deletingEpic = null;
            this.deletingEpicIndex = null;
            this.deletingRowIndex = null;
            this.showDeleteDialog = false;
        },

        confirmDelete() {
            if (this.deletingEpicIndex !== null) {
                this.deleteEpic(this.deletingEpicIndex);
            } else if (this.deletingRowIndex !== null) {
                this.deleteRow(this.deletingRowIndex);
            }
            this.showDeleteDialog = false;
            this.resetDeleteState();
        },

        sortByEpicId(epics) {
            return epics.sort((a, b) => a.tasks[0].epic_id - b.tasks[0].epic_id);
        },

        deleteRow(index) {
            const epic = this.deletingEpic;
            let deleted_task_name = epic.tasks[index].task_name;

            if (epic && epic.tasks.length > index) {
                let task_id = epic.tasks[index].id;
                this.axios
                    .delete(`/tasks/initial/${this.id}/${task_id}`)
                    .then((response) => {
                        console.log(response);
                        this.$posthog.capture('task_validation_task_deleted', {
                            project_id: this.id,
                            epic_name: epic.epic_name,
                            task_id: task_id,
                            task_name: deleted_task_name
                        });
                    })
                    .catch((error) => {
                        console.log(`Sorry, the task couldn't be deleted: ${error}`);
                    });

                // Delete task from the list
                epic.tasks.splice(index, 1);

                // Check if the epic has no more tasks
                if (epic.tasks.length === 0) {
                    this.epics.splice(index, 1);
                    this.deletingEpicIndex = null;
                }
            }
        },

        deleteEpic(index) {
            let epic = this.epics[index];
            let epic_id = epic.tasks[0].epic_id;
            this.axios
                    .delete(`/tasks/${this.id}/epic/${epic_id}`)
                    .then((response) => {
                        console.log(response);
                        this.$posthog.capture('task_validation_epic_deleted', {
                            project_id: this.id,
                            epic_name: epic.epic_name,
                        });
                    })
                    .catch((error) => {
                        console.log(`Sorry, the epic couldn't be deleted: ${error}`);
                    });

            this.epics.splice(index, 1);
            this.deletingEpicIndex = null;
        },

        fetchProjectsList() {
            this.axios.get(`/tasks/${this.id}`)
                .then((response) => {
                    console.log(response.data);
                    this.epics = response.data.tasks.reduce((accumulator, task) => {
                        const existingTask = accumulator.find((item) => item.epic_name === task.epic_name);
                        if (existingTask) {
                            existingTask.tasks.push({ ...task });
                        } else {
                            accumulator.push({
                                epic_name: task.epic_name,
                                tasks: [{ ...task }],
                            });
                        }
                        return accumulator;
                    }, []);

                    // Get all the role
                    const allRoles = [];
                    this.epics.forEach((epic) => {
                        epic.tasks.forEach((task) => {
                            if (task.role) {
                                allRoles.push(task.role[0]);
                            }
                        });
                    });

                    // Delete all duplicated role with Set
                    const uniqueRoles = [...new Set(allRoles)];
                    this.states = uniqueRoles;

                    this.epics = this.sortByEpicId(this.epics)
                    console.log(this.epics);
                })
                .catch((error) => {
                    console.log(`Sorry, no epics here. ${error}`);
                });
            this.selectedStep = 2;
        },
        splitTask(task) {
            //TODO: Add user_created, user_split and user_ignore values here
            this.overlay = true;
            this.dialogTitle = "Splitting task...";
            const payload = {
                task_id: task.id,
            };

            axios.post(`/tasks/${this.id}/split_tasks`, {
                project_details: payload,
            })
                .then((response) => {
                    console.log("DONE_SPLIT_TASK");
                    console.log(response);
                    console.log("Num_task: ", response["data"]["num_tasks"])
                    console.log("IDS_TASK: ", response["data"]["generated_task_ids"])
                    this.num_tasks_splitted = response["data"]["num_tasks"]
                    this.fetchProjectsList();
                    this.overlay = false;
                    this.task_splited = true;
                    this.$posthog.capture('task_validation_task_splitted', {
                        project_id: this.id,
                        epic_name: task.epic_name,
                        task_id: task.id,
                        task_name: task.task_name,
                        task_tshirt_size: task.tshirt
                    });
                    // Resalta las filas temporalmente
                    this.temporarilyHighlightedRows = response["data"]["generated_task_ids"].map(taskId => parseInt(taskId) - 1);
                    console.log(this.temporarilyHighlightedRows)
                    setTimeout(() => {
                        this.temporarilyHighlightedRows = [];
                    }, 10000);
                })
                .catch((error) => {
                    console.error("Error splitting the task:", error);
                    this.overlay = false;
                    if (error.response.status === 401) {
                        this.error_message = "This task cannot be split.";
                    } else {
                        this.error_message = "Andenia engine is currently down, please try again later.";
                    }
                    this.creation_failed_snackbar = true;
                });

        },
        dependenciesTasks() {
            this.overlay = true;
            this.dialogTitle = "Your project is being set up!";
            const payload = {
                project_id: this.id
            };
            this.selectedStep = 3;
            axios.post(`/tasks/${this.id}/dependencies_tasks`, {
                project_details: payload,
            })
                .then((response1) => {
                    console.log(response1);
                    return axios.post(`/projects/${this.id}/tasks_time`, {});
                })
                .then((response1) => {
                    console.log(response1);
                    return axios.post(`/projects/${this.id}/tasks_cost`, {});
                })
                .then((response3) => {
                    console.log(response3);
                    this.selectedStep = 4;
                    this.overlay = false;
                    this.$router.push(`/project/${this.id}`);
                })
                .catch((error) => {
                    // Handle errors if necessary 
                    console.error(error);
                });
        },
    },
    mounted() {
        this.tour = new this.$shepherd.Tour({
            useModalOverlay: true,
            modal: true,
            exitOnEsc: true,
            keyboardNavigation: true,
        });

        this.tour.defaultStepOptions = {
            classes: 'shepherd-theme-dark',
            scrollTo: true,
        };

        this.tour.addStep({
            title: '(1/3) Welcome to Task Validation!',
            text: 'Welcome to Task Validation! Verify, modify, delete, or add epics and tasks as needed.',
            attachTo: {
                element: '',
                on: ''
            },
            modalOverlayOpeningPadding: 0,
            modalOverlayOpeningRadius: 4,
            canClickTarget: false,
            buttons: [
                {
                    text: 'Skip all tips',
                    classes: 'shepherd-button-secondary',
                    action: () => this.postFlagOnboarding()
                },
                {
                    text: 'Got it',
                    classes: 'shepherd-button-primary',
                    action: this.tour.next
                }
            ],
        });

        this.tour.addStep({
            title: '(2/3) Epics',
            text: 'These epics are generated by Andenia from your project description.',
            attachTo: {
                element: '.panel',
                on: 'top'
            },
            modalOverlayOpeningPadding: 0,
            modalOverlayOpeningRadius: 4,
            canClickTarget: false,
            buttons: [
                {
                    text: 'Skip all tips',
                    classes: 'shepherd-button-secondary',
                    action: () => this.postFlagOnboarding()
                },
                {
                    text: 'Got it',
                    classes: 'shepherd-button-primary',
                    action: this.tour.next
                }
            ],
        });

        this.tour.addStep({
            title: '(3/3) Add new Epic',
            text: 'Here, you can add a new epic.',
            attachTo: {
                element: '.splitbot-epic-button',
                on: 'left'
            },
            modalOverlayOpeningPadding: 0,
            modalOverlayOpeningRadius: 8,
            canClickTarget: false,
            buttons: [
                {
                    text: 'Skip all tips',
                    classes: 'shepherd-button-secondary',
                    action: () => this.postFlagOnboarding()
                },
                {
                    text: 'Got it',
                    classes: 'shepherd-button-primary',
                    action: this.tour.next
                }
            ],
        });

    },
    created() {
        this.getFlagOnboarding();
        axios
            .get(`/projects/${this.id}`)
            .then((response) => {
                let project_status = response.data.status;
                const valid_states = ["initial_tasks_created", "initial_dependencies_finished", "initial_tasks_times_finished"];
                if (!valid_states.includes(project_status)) {
                    this.$router.push(`/projects`);
                }
            })
            .catch((error) => {
                console.log(
                    "Error occurred while fetching project data for project: " + this.id
                );
                this.$router.push(`/projects`);
            });
        this.fetchProjectsList();
    },
    computed: {
        filteredTasks() {
            return this.epics.filter(epic => epic.tasks.length > 0);
        },
        hasZeroTime() {
            return this.epics.some(epic =>
                epic.tasks.some(task => task.time === 0)
            );
        },
        invalidTaskIndices() {
            return this.epics.map((epic, index) => {
                let message = '';
                if (epic.tasks.some(task => task.time == 0)) {
                    message = 'Task time cannot be zero.';
                } else if (epic.tasks.some(task => !task.tshirt)) {
                    message = 'Task t-shirt size is required.';
                } else if (epic.tasks.some(task => !task.task_name)) {
                    message = 'Task name is required.';
                } else if (epic.tasks.some(task => !task.task_description)) {
                    message = 'Task description is required.';
                } else if (epic.tasks.some(task => !task.role.length)) {
                    message = 'Role is required for the task.';
                }

                return {
                    index,
                    isValid: message !== '',
                    message
                };
            }).filter(epic => epic.isValid);
        },
    },
};
</script>