<template>
  <v-container fluid>
    <v-row>
      <draggable class="draggable-list columns-container" group="columns" animation="200" draggable=".item"
        :list="visibleColumns" :scroll-sensitivity="200" :force-fallback="true" @end="onColumnDrop">
        <Column v-for="column in visibleColumns" :key="column.id" :column="column" :project="project"
          v-on:cardMoved="moveCard" v-on:requestAddCard="addCardDialog" v-on:deleteTaskBoard="handleDeleteTask"
          v-on:deleteStatus="deleteStatusProject" v-on:hide-column="handleHideColumn" class="item mr-5" />
      </draggable>
    </v-row>
    <CardAdd :showDialog="dialogToShow" :column="newCardColumn" :project="project"
      v-on:createNewTaskBoard="handleNewTask" @update:showDialog="dialogToShow = $event" />
  </v-container>
</template>

<style scoped>
.menu-content {
  display: flex;
  vertical-align: middle;
}

.text-content {
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: var(--v-neutral400-base);
  opacity: 1;
  border: none;
  border-radius: 4px;
  gap: 6px;
}

.button-menu {
  height: 40px !important;
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  background: var(--v-neutral100-base);
  color: var(--v-neutral600-base);
  opacity: 1;
  box-shadow: none;
  border: none;
  border-radius: 4px;
  gap: 6px;
}

.columns-container {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
}

.item {
  flex: 0 0 auto;
}

.columns-container::-webkit-scrollbar {
  height: 8px;
  border-radius: 4px;
  background-color: var(--v-primary100-base);
}

.columns-container::-webkit-scrollbar-thumb {
  background-color: var(--v-primary500-base);
  border-radius: 4px;
}

.columns-container::-webkit-scrollbar-track {
  background-color: var(--v-primary100-base);
  border-radius: 4px;
}

.columns-container::-webkit-scrollbar-track:hover {
  background-color: var(--v-primary100-base);
  border-radius: 4px;
}
</style>

<script>
import Column from "@/components/Column.vue";
import CardAdd from "./CardAdd.vue";
import draggable from "vuedraggable";

export default {
  data() {
    return {
      dialogToShow: false,
      hiddenColumns: [],
      collapsedColumns: [],
      newCardColumn: null,
    };
  },
  props: {
    project: null,
  },
  components: {
    Column,
    CardAdd,
    draggable,
  },
  methods: {
    onColumnDrop(event) {
      const oldPosition = event.oldIndex;
      const newPosition = event.newIndex;
      this.moveColumn(oldPosition, newPosition);
    },
    moveCard(event) {
      this.$emit("taskOrderChanged");
    },
    moveColumn(oldIndex, newIndex) {
      if (newIndex < 0 || newIndex >= this.project.task_status_options.length) {
        return;
      }
      const movedStatus = this.project.task_status_options.splice(oldIndex, 1)[0];
      this.project.task_status_options.splice(newIndex, 0, movedStatus);
      this.project.task_status_options.forEach((status, index) => {
        status.order = index;
      });
      this.$emit("updateProjectStatusOrder");
    },
    deleteStatusProject(column_id) {
      const columnIndex = this.project.task_status_options.findIndex(column => column.order === column_id);
      if (columnIndex === -1) {
        return;
      }
      let column_deleted = this.project.task_status_options.splice(columnIndex, 1);
      this.project.task_status_options.forEach((status, index) => {
        status.order = index;
      });
      this.$posthog.capture('project_status_deleted', {
        project_id: this.project.id,
        project_status: column_deleted
      });
      this.$emit("updateProjectStatusOrder");
    },
    handleNewTask(event) {
      this.$emit("createNewTaskProject", event);
    },
    handleDeleteTask(event) {
      this.$emit("deleteTaskProject", event);
    },
    addCardDialog(event) {
      this.newCardColumn = event;
      this.dialogToShow = true;
    },
    handleHideColumn(columnId) {
      const column = this.boardColumns.find(col => col.id === columnId);
      this.hiddenColumns.push(columnId);
      this.collapsedColumns.push(column);
    },
    expandColumn(columnId) {
      this.hiddenColumns = this.hiddenColumns.filter(id => id !== columnId);
      this.collapsedColumns = this.collapsedColumns.filter(column => column.id !== columnId);
    },
  },
  computed: {
    boardColumns() {
      if (this.project) {
        const columns = {};
        const sortedStatusOptions = [...this.project.task_status_options].sort((a, b) => a.order - b.order);
        sortedStatusOptions.forEach((statusOption) => {
          columns[statusOption.name] = {
            name: statusOption.name,
            color: statusOption.color,
            id: statusOption.order,
            cards: [],
          };
        });
        this.project.tasks.forEach((task) => {
          const columnName = task.status;
          if (columns[columnName]) {
            columns[columnName].cards.push(task);
            columns[columnName].cards.sort((a, b) => a.order - b.order);
          }
        });
        const sortedColumns = Object.values(columns).sort((a, b) => a.id - b.id);
        return sortedColumns;
      } else {
        return [];
      }
    },
    visibleColumns() {
      return this.boardColumns.filter(column => !this.hiddenColumns.includes(column.id));
    }
  },
  mounted() {
    this.selectedColumns = this.boardColumns.map(() => true);
  },
};
</script>
