<template>
  <v-app>
    <v-main class="background" :class="{ darkColor: isHome, whiteColor: isLogin }">
      <AppBar v-if="isAppBarVisible" :collapsed="isAppBarCollapsed" :org_role="user_profile.org_role_internal"
        :org_name="user_profile.org_name" :user_name="user_profile.user_name" />
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import "@lottiefiles/lottie-player";
import AppBar from "@/components/AppBar.vue";
import user_profile_mixin from "@/mixins/user_profile.js";
import router from "@/router/index.js";

export default {
  name: "Andenia",
  components: {
    AppBar,
  },
  data: () => ({
    user_profile: { org_role_internal: "organization_member" },
  }),
  mixins: [user_profile_mixin],
  computed: {
    isHome() {
      return this.$route.path === '/';
    },
    isLogin() {
      const selectedRoutes = ['/login', '/register', '/logout'];
      return selectedRoutes.includes(this.$route.path);
    },
  },
  methods: {
    updateAppBarState() {
      const appBarState = router.currentRoute.meta.appBar || 'visible';
      this.isAppBarVisible = appBarState !== 'hide';
      this.isAppBarCollapsed = appBarState === 'collapsed';
    },
  },
  watch: {
    $route() {
      this.updateAppBarState();
      this.fetchUserProfile();
    },
  }
};
</script>

<style scoped>
.background {
  background-color: var(--v-neutral100-base);
}

.darkColor {
  background-color: var(--v-neutral800-base);
}

.whiteColor {
  background-color: white;
}

body {
  background: no-repeat top left fixed !important;
  background-size: cover !important;
}

#app {
  background: inherit;
  background-size: inherit;
}

html {
  overflow-x: auto !important;
}
</style>
