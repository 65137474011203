<template>
  <v-card v-bind:key="card.task_name" @click="showDetails" class="card-content" @mouseover="showMenu"
    @mouseout="hiddeMenu">
    <v-card-text class="d-flex justify-space-between">
      <v-chip small>{{ card.epic_name }}</v-chip>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon class="item-content" v-bind="attrs" v-on="on" :hidden="menuHidden">
            <v-icon>mdi-dots-horizontal</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item @click="deleteCardItem">
            <v-list-item-icon>
              <v-icon color="error500">mdi-delete</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Delete</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-card-text>
    <v-card-title class="card-title" style="word-break: break-word;">
      {{ card.task_name }}
    </v-card-title>
    <v-card-text class="py-0">
      <v-row align="center" no-gutters>
        <v-col class="text-left" cols="6">{{ card.role.join(', ') }}</v-col>
        <v-col cols="6" class="text-right">
          {{ card.end_date | time_until }}
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<style scoped>
.card-content {
  width: 292px;
  background-color: white;
  border-radius: 6px;
}

.item-content {
  height: 24px;
  width: 24px;
}

.card-content:hover {
  border-color: var(--v-primary100-base);
  border-style: solid;
}
</style>

<script>

export default {
  props: {
    card: Object,
  },
  data() {
    return {
      menuHidden: true
    }
  },

  filters: {
    time_until: function (endDate) {
      const now = new Date();
      const end = new Date(endDate);
      const difference = end - now;
      const daysDifference = Math.ceil(difference / (1000 * 3600 * 24));

      if (daysDifference > 0) {
        if (Math.abs(daysDifference) == 1) {
          return `${daysDifference} day to deadline`;
        }
        else {
          return `${daysDifference} days to deadline`;
        }
      } else if (daysDifference < 0) {
        if (Math.abs(daysDifference) == 1) {
          return `${Math.abs(daysDifference)} day overdue`;
        }
        else {
          return `${Math.abs(daysDifference)} days overdue`;
        }

      } else {
        return 'Due today';
      }
    },
  },
  methods: {
    showMenu() {
      this.menuHidden = false;
    },
    hiddeMenu() {
      this.menuHidden = true;
    },
    showDetails: function (event) {
      this.$router.push(`/project/${this.card.project_id}/task/${this.card.id}`);
    },
    deleteCardItem() {
      this.$emit("deleteCard", this.card.id)
    },
  }
};
</script>
