export default {
    data() {
        return {
        }
    },
    created: function () {
    },
    methods: {
        fetchUserProfile(projects = false, tasks = false) {
            let base_path = `/user/profile_info?projects=${projects.toString()}&tasks=${tasks.toString()}`
            return this.axios.get(base_path)
                .then((response) => {
                    this.owned_projects = response.data.projects_with_access;
                    this.user_profile = {
                        user_name: response.data.user_name,
                        org_name: response.data.org_name,
                        org_role_internal: response.data.org_role_internal,
                        org_role_external: response.data.org_role_external,
                        onboarding_step: response.data.onboarding_step,
                    };
                })
                .catch((error) => {
                    console.log(`Sorry, we are having issues retrieving your user profile: ${error}`);
                    this.$router.push(`/login`);
                })
        },
    },
}