<template>
  <v-app >
    <v-app-bar fixed color="neutral100" elevate-on-scrol scroll-target="#scrolling-techniques-7">
      <v-img src="~@/assets/images/andenia_appbar.png" alt="LogoAppBar" contain max-width="12%" max-height="70%" />
      <v-spacer></v-spacer>
      <v-btn plain @click="scrollToFeatures">Features</v-btn>
      <v-btn plain @click="scrollToIntegration">Integration</v-btn>
      <v-btn plain @click="scrollToPricing">Pricing</v-btn>
      <v-btn plain @click="scrollToContactUs">Contact</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary500" outlined plain to="/login">Log in</v-btn>
      <v-btn color="primary500" dark class="ml-3" href="mailto:santiago.rivier@andenia.com">Sign Up</v-btn>
    </v-app-bar>


    <!-- Sizes your content based upon application components -->
    <v-main>

      <!-- Header Section -->
      <v-container fluid class="header-section text-center">
        <v-row justify="center">
          <v-col cols="12">
            <h1 class="display-4 font-weight-bold mb-8">Pick your project<br>let AI manage IT!</h1>
            <h2 class="subtitle-3 mb-8">AI assisted project management platform</h2>
            <v-row justify="center">
              <v-col cols="auto">
                <v-btn large color="primary500" dark>Get started for free<v-icon right dark>
                    mdi-arrow-right
                  </v-icon></v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn text large href="mailto:santiago.rivier@andenia.com">Contact Us</v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="text-center">
          <v-col>
            <v-responsive aspect-ratio="16/9">
              <video autoplay loop muted controls width="100%">
                <source src="~@/assets/images/andenia_video.mp4" type="video/mp4">
              </video>
            </v-responsive>
          </v-col>
        </v-row>
      </v-container>

      <!-- Features Section -->
      <section ref="features">
        <v-container fluid class="features-section">
          <v-row justify="space-between">
            <v-col cols="3">
              <v-img src="~@/assets/images/icon_feature_1.png" alt="Feature_1" contain max-width="35%" max-height="35%"
                class="mb-3" />
              <h2 class="mb-3">Automatic generation tasks</h2>
              <p class="subtitle-1 justified-text">Based on the project description, optimizing the planning process and
                reducing
                management times</p>
            </v-col>
            <v-col cols="3">
              <v-img src="~@/assets/images/icon_feature_2.png" alt="Feature_2" contain max-width="35%" max-height="35%"
                class="mb-3" />
              <h2 class="mb-3">Projects from different areas</h2>
              <p class="subtitle-1 justified-text">Addressing the need for a technical project manager</p>
            </v-col>
            <v-col cols="3">
              <v-img src="~@/assets/images/icon_feature_3.png" alt="Feature_3" contain max-width="35%" max-height="35%"
                class="mb-3" />
              <h2 class="mb-3">Past project learning</h2>
              <p class="subtitle-1 justified-text">To improve the planning of future projects</p>
            </v-col>
          </v-row>
        </v-container>
      </section>

      <!-- Smart Task Creation Section -->
      <v-container fluid class="smart-task-section">
        <v-row justify="center">
          <v-col cols="4" class="mx-auto my-auto">
            <h2>Smart task creation</h2>
            <p class="justified-text">Create a new task in your project and let Andenia take care of assigning estimated
              time and cost,
              identifying dependencies with other tasks, assigning the most suitable role and user, or if you don't have
              the right person in your team, recommending the optimal freelancer for the task</p>
            <v-btn large color="primary500" dark>Get started for free <v-icon right dark>
                mdi-arrow-right
              </v-icon></v-btn>
          </v-col>
          <v-col cols="6">
            <v-img src="~@/assets/images/task_creation.png" alt="Feature_3" max-width="90%" max-height="90%"
              class="mx-auto my-auto" contain />
          </v-col>
        </v-row>
      </v-container>

      <!-- Manage Projects Section -->
      <v-container fluid class="manage-projects-section">
        <v-row justify="center" class="text-center mb-6">
          <v-col cols="6">
            <h2 class="display-1 font-weight-bold">100+</h2>
            <p>Customers visit Andenia every month</p>
          </v-col>
          <v-col cols="6">
            <h2 class="display-1 font-weight-bold">90%</h2>
            <p>Time saved in managing your projects</p>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="text-center mt-4">
          <v-col>
            <h2 class="display-1 font-weight-bold">Manage your projects fast</h2>
            <p class="subtitle-1">End to end project planning in less than an hour</p>
          </v-col>
        </v-row>
        <v-row justify="center" class="mt-10">
          <v-col cols="8" class="mx-auto my-auto">
            <v-img src="~@/assets/images/life_cicle_andenia.png" alt="LifeCicle" max-width="100%" max-height="100%"
              class="mx-auto my-auto" contain />
          </v-col>
          <v-col cols="4" class="mx-auto my-auto">
            <v-col class="text-left">
              <h3><v-icon large color="primary500">mdi-numeric-1-circle-outline</v-icon> Describe your project</h3>
              <p class="ml-10 justified-text">Using a project requirements document or manually through our chatbot
                interface or with the form input</p>
            </v-col>
            <v-col class="text-left">
              <h3><v-icon large color="primary500">mdi-numeric-2-circle-outline</v-icon> Validate all tasks created</h3>
              <p class="ml-10 justified-text">By our AI models, adding, editing or deleting the tasks as well as the
                suggested time estimations, roles and dependencies assigned</p>
            </v-col>
            <v-col class="text-left">
              <h3><v-icon large color="primary500">mdi-numeric-3-circle-outline</v-icon> Start working with your team
              </h3>
              <p class="ml-10 justified-text">Using our different types of visualization and the project specific AI
                assistant</p>
            </v-col>
          </v-col>
        </v-row>
      </v-container>

      <!-- Integrations Section -->
      <section ref="integration">
        <v-container fluid class="integrations-section">
          <v-row justify="center" class="text-center mb-6">
            <v-col cols="12">
              <h2 class="display-1 font-weight-bold">Integrations</h2>
            </v-col>
          </v-row>
          <div class="carousel-wrapper">
            <div class="carousel-content" ref="carouselContent">
              <div class="carousel-item" v-for="(item, index) in displayedItems" :key="index">
                <v-img :src="item.image" :alt="item.text" class="integration-icon" contain></v-img>
              </div>
            </div>
          </div>
        </v-container>
      </section>

      <!-- Pricing Section -->
      <section ref="pricing">
        <v-container fluid class="pricing-plans-section">
          <v-row justify="center" class="text-center mb-6">
            <v-col cols="12">
              <h2 class="display-1 font-weight-bold white--text">Pricing & Plans</h2>
              <p class="subtitle-1 white--text">Discover straightforward plans that fit your needs perfectly. Sign up
                and experience exceptional value.</p>
            </v-col>
          </v-row>
          <v-row justify="center" class="text-center">
            <v-col v-for="(plan, index) in pricingPlans" :key="index" cols="4" class="mb-4">
              <v-card :class="{ 'pa-6': true, 'pricing-card': true, 'promotion-card': plan.promotion }"
                class="d-flex flex-column">
                <v-card-title :class="{ 'display-1': true, 'blue--text': plan.promotion, 'font-weight-bold': true }">{{
                  plan.title }}</v-card-title>
                <div class="price"><v-icon :class="{ 'mr-4': true, 'blue--text': plan.promotion }" large>{{ plan.icon
                    }}</v-icon>{{ plan.price }}</div>
                <v-list dense class="flex-grow-1">
                  <v-list-item v-for="(feature, i) in plan.features" :key="i">
                    <v-list-item-icon>
                      <v-icon color="success500">mdi-check-circle</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content class="feature-text">{{ feature }}</v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-card-actions class="justify-center">
                  <v-btn color="primary500" dark :outlined="!plan.promotion" :href="getButtonHref(plan.buttonText)" large>{{
                    plan.buttonText }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </section>


      <!-- Contact Us Section -->
      <section ref="contactus">
        <v-container fluid class="contact-us-section">
          <v-row align="center" justify="center">
            <v-col cols="6" class="text-left">
              <h2>Join over 100 teams around the world using Andenia to manage projects and save time.</h2>
            </v-col>
            <v-col cols="3" class="text-right mr-8">
              <v-btn color="primary500" dark class="contact-us-btn" href="mailto:santiago.rivier@andenia.com">Contact Us <v-icon
                  right dark>
                  mdi-arrow-right
                </v-icon></v-btn>
            </v-col>
          </v-row>
        </v-container>
      </section>


    </v-main>

    <v-footer color="neutral100">
      <v-container>
        <v-row>
          <v-col cols="3" class="text-center text-md-left">
            <v-img src="~@/assets/images/andenia_footer.png" alt="AndeniaFooter" contain max-width="60%"
              max-height="60%" class="mx-auto my-4" />
          </v-col>
          <v-col cols="3">
            <h4 class="ml-4">Company</h4>
            <v-list color="neutral100">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title><a href="/aboutus">About us</a></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title><a href="mailto:santiago.rivier@andenia.com">Contact us</a></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col cols="3">
            <h4 class="ml-4">Product</h4>
            <v-list color="neutral100">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title><a @click="scrollToFeatures">Features</a></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title><a @click="scrollToIntegration">Integration</a></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title><a @click="scrollToPricing">Pricing</a></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title><a @click="scrollToContactUs"  >Contact</a></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col cols="3">
            <h4 class="ml-4">Legal</h4>
            <v-list color="neutral100">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title><a href="#">Privacy Policy</a></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title><a href="#">Terms & Conditions</a></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title><a href="#">Return Policy</a></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-center">
            <p>© 2024 Andenia. All rights reserved.</p>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </v-app>
</template>

<style scoped>
.header-section {
  background: white;
  padding: 140px 10%;
}

.features-section {
  background: white;
  padding: 0 10%;
}

.smart-task-section {
  background: var(--v-neutral100-base);
  padding: 60px 10%;
}

.manage-projects-section {
  background: white;
  padding: 60px 10%;
}

.integrations-section {
  background: var(--v-neutral100-base);
  padding: 60px 10%;
  overflow: hidden;
}

.carousel-wrapper {
  overflow: hidden;
  position: relative;
  margin: 0 auto;
  padding: 0 50px;
}

.carousel-content {
  display: flex;
  width: max-content;
  will-change: transform;
}

.carousel-item {
  flex: 0 0 25%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.integration-icon {
  width: 100px;
  height: 100px;
}

.carousel-wrapper::before,
.carousel-wrapper::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 50px;
  z-index: 1;
}

.carousel-wrapper::before {
  left: 0;
  background: linear-gradient(to right, var(--v-neutral100-base), #00000000);
}

.carousel-wrapper::after {
  right: 0;
  background: linear-gradient(to left, var(--v-neutral100-base), #00000000);
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-50%));
  }
}

.pricing-plans-section {
  background: var(--v-neutral800-base);
  padding: 60px 10%;
}

.title {
  font-size: 1.5rem;
  font-weight: bold;
}

.price {
  font-size: 1.25rem;
  margin: 20px 0;
}

.pricing-card {
  border-radius: 8px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.promotion-card {
  transform: scale(1.05);
}

.v-card-title {
  margin-bottom: 0;
}

.v-card-subtitle {
  margin-bottom: 20px;
}

.v-card-actions {
  margin-top: 20px;
}

.v-btn {
  text-transform: uppercase;
  font-weight: bold;
}

.white--text {
  color: white !important;
}

.feature-text {
  font-size: 0.875rem;
  text-align: left;
}

.blue--text {
  color: var(--v-primary500-base) !important;
  /* Vuetify primary blue */
}

.contact-us-section {
  background: white;
  padding: 60px 10%;
}

.contact-us-section h2 {
  font-size: 1.5rem;
  margin: 0;
}

.contact-us-btn {
  font-size: 1rem;
  padding: 10px 20px;
}

.justified-text {
  text-align: justify;
}
</style>

<script>
const axios = require('axios').default;

export default {

  data() {
    return {
      current: 0,
      headerHeight: 65,
      items: [
        { id: 1, image: require("@/assets/images/Github.png"), text: 'Github' },
        { id: 2, image: require("@/assets/images/Gitlab.png"), text: 'Gitlab' },
        { id: 3, image: require("@/assets/images/Slack.png"), text: 'Slack' },
        { id: 4, image: require("@/assets/images/Airtable.png"), text: 'Airtable' },
        { id: 5, image: require("@/assets/images/ClickUp.png"), text: 'ClickUp' },
        { id: 6, image: require("@/assets/images/Trello.png"), text: 'Trello' },
        { id: 7, image: require("@/assets/images/Notion.png"), text: 'Notion' },
      ],
      pricingPlans: [
        {
          title: 'Basic',
          icon: 'mdi-account',
          price: 'Free',
          features: [
            'Unlimited Projects',
            'Personal Use',
            '10 AI Credits for Features',
            'Community Support',
          ],
          buttonText: 'Get started for free',
          promotion: false,
        },
        {
          title: 'Team',
          icon: 'mdi-account-group',
          price: 'Contact Us',
          features: [
            'Unlimited Projects',
            'Organization and Workspace Hierarchy',
            'Up to 20 Team Members',
            'AI Credits for Features',
            'Role-Based Access Management',
            'Past Projects Learning',
            'Community Support',
          ],
          buttonText: 'Contact us for details',
          promotion: true,
        },
        {
          title: 'Enterprise',
          icon: 'mdi-domain',
          price: 'Contact Us',
          features: [
            'Unlimited Projects',
            'Unlimited Members',
            'AI Credits for Features',
            'Dedicated Support 24/7',
            'Automatic Documentation',
            'Past Projects Learning',
            'Custom Solutions',
          ],
          buttonText: 'Contact us for details',
          promotion: false,
        },
      ],
    }
  },
  computed: {
    displayedItems() {
      return [...this.items, ...this.items];
    },
  },
  mounted() {
    this.startAnimation();
  },
  methods: {
    startAnimation() {
      const carouselContent = this.$refs.carouselContent;
      const totalWidth = carouselContent.scrollWidth / 2;

      const animate = () => {
        let start = null;

        const step = (timestamp) => {
          if (!start) start = timestamp;
          const progress = timestamp - start;
          const translateX = (progress / 10) % totalWidth;

          carouselContent.style.transform = `translateX(${-translateX}px)`;

          requestAnimationFrame(step);
        };

        requestAnimationFrame(step);
      };

      animate();
    },
    getButtonHref(buttonText) {
      if (buttonText === 'Get started for free') {
        return '/login';
      } else if (buttonText === 'Contact us for details') {
        return 'mailto:santiago.rivier@andenia.com';
      }
      return '#';
    },
    scrollToFeatures() {
      const destination = this.$refs.features.offsetTop - this.headerHeight;
      window.scrollTo({
        top: destination,
        behavior: 'smooth',
      });
    },
    scrollToIntegration() {
      const destination = this.$refs.integration.offsetTop - this.headerHeight;
      window.scrollTo({
        top: destination,
        behavior: 'smooth',
      });
    },
    scrollToPricing() {
      const destination = this.$refs.pricing.offsetTop - this.headerHeight;
      window.scrollTo({
        top: destination,
        behavior: 'smooth',
      });
    },
    scrollToContactUs() {
      const destination = this.$refs.contactus.offsetTop - this.headerHeight;
      window.scrollTo({
        top: destination,
        behavior: 'smooth',
      });
    },
  },
  components: {
  },
  created() {
  },
}
</script>