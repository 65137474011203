<template>
  <v-card class="column-card d-flex flex-column list" width="298px" v-bind:key="column.name">
    <v-card-title class="column-header">
      <div class="task-count" :style="{ backgroundColor: calculateTransparentColor(column.color) }">
        <span>{{ column.cards.length }}</span>
      </div>
      <div class="task-name">
        {{ capitalizeFirstLetter(column.name) }}
      </div>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon class="item-content" v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item @click="deleteStatusItem" v-bind:disabled="column.cards.length !== 0">
            <v-list-item-icon>
              <v-icon color="error500" v-bind:disabled="column.cards.length !== 0">mdi-delete</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Delete</v-list-item-title>
          </v-list-item>
          <v-list-item @click="hideColumn">
            <v-list-item-icon>
              <v-icon>mdi-eye-off</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Hide Column</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-card-title>
    <v-card-text class="column-content">
      <draggable :id="column.id" class="draggable-list" group="cards" animation="200" :list="column.cards"
        :scroll-sensitivity="200" :emptyInsertThreshold="100" :force-fallback="true" :move="onCardMove"
        @end="onCardDrop">
        <Card v-for="card in column.cards" :key="card.id" :card="card" v-on:deleteCard="deleteCardColumn"
          class="mb-2" />
      </draggable>
    </v-card-text>
    <v-card-actions class="card-action">
      <v-btn class="add_card_button" @click="$emit('requestAddCard', column)" block><v-icon left
          class="add_card_button_icon">
          mdi-plus-thick
        </v-icon>Add Card</v-btn>
    </v-card-actions>
  </v-card>
</template>

<style scoped>
.column-card {
  margin-bottom: 0.5%;
  height: calc(100vh - 160px);
  color: var(--v-neutral600-base);
  background: var(--v-neutral100-base);
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.column-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--v-neutral200-base);
  margin-bottom: 8px;
}

.task-count {
  height: 24px;
  min-width: 24px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--v-neutral100-base);
  font-size: 1rem;
  text-shadow: -0.5px -0.5px 0 var(--v-neutral500-base), 0.5px -0.5px 0 var(--v-neutral500-base), -0.5px 0.5px 0 var(--v-neutral500-base), 0.5px 0.5px 0 var(--v-neutral500-base);
  padding: 0 8px;
  box-sizing: border-box;
}

.task-name {
  color: var(--v-neutral800-base);
}

.column-content {
  overflow-y: auto;
  flex-grow: 1;
}

.column-content::-webkit-scrollbar {
  width: 8px;
  border-radius: 4px;
  background-color: var(--v-primary100-base);
}

.column-content::-webkit-scrollbar-thumb {
  background-color: var(--v-primary500-base);
  border-radius: 4px;
}

.column-content::-webkit-scrollbar-track {
  background-color: var(--v-primary100-base);
  border-radius: 4px;
}

.column-content::-webkit-scrollbar-track:hover {
  background-color: var(--v-primary100-base);
  border-radius: 4px;
}

.add_card_button {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  border: none;
  border-radius: 4px;
  gap: 6px;
}

/* Hover */
.add_card_button:hover {
  color: var(--v-primary100-base);
  background: var(--v-primary500-base);
}

/* Pressed */
.add_card_button:hover:active {
  color: var(--v-primary500-base);
  background: var(--v-primary100-base);
}
</style>

<script>
import draggable from "vuedraggable";
import Card from "@/components/Card.vue";

export default {
  props: {
    column: Object,
    project: Object,
  },

  data() {
    return {
      cardMoveEvent: Object,
    }
  },

  components: {
    Card,
    draggable,
  },

  methods: {
    onCardMove: function (event) {
      this.cardMoveEvent = event;
    },

    onCardDrop: function (event) {
      const card = this.cardMoveEvent.draggedContext.element.id;
      const sourceColumn = parseInt(this.cardMoveEvent.from.id);
      const targetColumn = parseInt(this.cardMoveEvent.to.id);
      const sourcePosition = this.cardMoveEvent.draggedContext.index;
      const targetPosition = this.cardMoveEvent.draggedContext.futureIndex;
      this.moveCard(card, sourceColumn, targetColumn, sourcePosition, targetPosition)
    },

    moveCard(card, sourceColumn, targetColumn, sourcePosition, targetPosition) {
      const taskToUpdate = this.project.tasks.find(t => t.id === card);
      const newStatus = this.project.task_status_options.find(option => option.order === targetColumn);
      const originStatus = this.project.task_status_options.find(option => option.order === sourceColumn);

      if (taskToUpdate !== null && newStatus !== null && originStatus !== null) {
        this.project.tasks.forEach(t => {
          if (t.status.toLowerCase() === newStatus.name) {
            if (t.order >= targetPosition) {
              t.order++;
            }
          }
          if (t.status.toLowerCase() === originStatus.name) {
            if (t.order >= sourcePosition) {
              t.order--;
            }
          }
        });
        taskToUpdate.status = newStatus.name;
        taskToUpdate.order = targetPosition;
      } else {
        console.log("Error by updating the orders");
      }

      this.$emit("cardMoved");
    },
    deleteCardColumn(event) {
      this.$emit("deleteTaskBoard", event)
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    deleteStatusItem() {
      this.$emit("deleteStatus", this.column.id)
    },
    hideColumn() {
      this.$emit('hide-column', this.column.id);
    },
    calculateTransparentColor(color) {
      const rgbColor = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
      const red = parseInt(rgbColor[1], 16);
      const green = parseInt(rgbColor[2], 16);
      const blue = parseInt(rgbColor[3], 16);

      return `rgba(${red}, ${green}, ${blue}, 0.8)`;
    },
  }
};
</script>
