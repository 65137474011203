<template>
	<v-app>
		<v-container v-if="owned_projects.length != 0">
			<v-row v-if="this.user_profile.org_role_internal != 'organization_member'">
				<v-col cols="auto" class="own-projects-card mr-auto ml-5">
					<h1>Projects</h1>
				</v-col>
				<v-col cols="auto" class="mr-5">
					<v-btn class="import-button" color="primary500" outlined
						@click="import_dialog_opened = true"><v-icon>mdi-import</v-icon>Import</v-btn>
				</v-col>
				<v-col cols="auto" class="mr-5">
					<v-btn class="create-project-button" color="primary500" depressed dark
						@click="new_project_dialog_opened = true"><v-icon>mdi-plus</v-icon>New Project</v-btn>
				</v-col>
			</v-row>
			<v-row v-else>
				<v-col cols="auto" class="own-projects-card mr-auto ml-5">
					<h1>Projects</h1>
				</v-col>
			</v-row>

			<v-row>
				<ProjectTile v-for="project in owned_projects" :key="project.id" :project="project"
					v-on:share="openSharing" :sharing="true" v-on:delete="openDelete" v-on:export="openExport"
					:org_role="user_profile.org_role_internal" />
			</v-row>

			<ShareDialog v-model="sharing_dialog_opened" v-on:hide="hideShareDialog" :project="sharing_project" />
		</v-container>
		<v-container v-else>
			<div class="create-project-container">
				<div class="image-centered-container">
					<img src="~@/assets/images/new_project.png" alt="Project Image" class="create-project-image">
				</div>
				<div class="project-content-container">
					<h1 class="create-project-header">Create your first project</h1>
					<p class="create-project-subtitle">Fortunately, with <b>Andenia</b> it's easy to create a new one!
					</p>
					<v-row v-if="this.user_profile.org_role_internal != 'organization_member'">
						<v-col cols="auto" class="mr-5">
							<v-btn class="import-button" color="primary500" outlined
								@click="import_dialog_opened = true"><v-icon>mdi-import</v-icon>Import</v-btn>
						</v-col>
						<v-col cols="auto" class="mr-5">
							<v-btn class="create-project-button" color="primary500" depressed dark
								@click="new_project_dialog_opened = true"><v-icon>mdi-plus</v-icon>New Project</v-btn>
						</v-col>
					</v-row>

				</div>
			</div>
		</v-container>
		<v-container>
			<NewProjectDialog v-model="new_project_dialog_opened" v-on:hide="hideNewProjectDialog" />
		</v-container>
		<v-container>
			<DeleteDialog v-model="delete_dialog_opened" v-on:hideDelete="hideDeleteDialog"
				:project="deleting_project" />
		</v-container>
		<v-container>
			<ExportDialog v-model="export_dialog_opened" v-on:hideExport="hideExportDialog"
				:project="exporting_project" />
		</v-container>
		<v-container>
			<ImportDialog v-model="import_dialog_opened" v-on:hideImport="hideImportDialog" />
		</v-container>
	</v-app>
</template>

<style scoped>
.create-project-container {
	position: relative;
	height: 100vh;
	/* Ensure the container takes the full height of the viewport */
	display: flex;
	align-items: center;
	justify-content: center;
}

.image-centered-container {
	position: absolute;
	top: 30%;
	transform: translateY(-50%);
	text-align: center;
	width: 100%;
}

.create-project-image {
	width: 30%;
	height: 30%;
	/* Additional styles for the image */
}

.project-content-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-top: 200px;
	/* Add some spacing from the image */
}

.create-project-header {
	font-size: 24px;
	line-height: 36px;
	margin-top: 16px;
	/* Additional styles for the header */
}

.create-project-subtitle {
	margin-top: 8px;
	/* Additional styles for the paragraph */
}
</style>

<script>
const axios = require('axios').default;
import user_profile_mixin from "../mixins/user_profile.js";
import ProjectTile from "@/components/ProjectTile.vue";
import ShareDialog from "@/components/ShareDialog.vue";
import NewProjectDialog from "@/components/NewProjectDialog.vue";
import DeleteDialog from "@/components/DeleteDialog.vue";
import ExportDialog from "@/components/ExportDialog.vue";
import ImportDialog from "@/components/ImportDialog.vue";

export default {
	data() {
		return {
			owned_projects: [],
			user_assigned_tasks: [],
			user_profile: { org_role_internal: "organization_member" },
			sharing_project: null,
			sharing_dialog_opened: false,
			new_project_dialog_opened: false,
			delete_dialog_opened: false,
			deleting_project: {},
			export_dialog_opened: false,
			import_dialog_opened: false,
			exporting_project: {},
			rules: [
				(value) => !!value || "Required Field!",
				(value) => (value || "").length <= 40 || "Maximum table name length: 40 characters!",
			],
			tour: null,
		}
	},
	mixins: [user_profile_mixin],
	components: {
		ProjectTile,
		ShareDialog,
		NewProjectDialog,
		DeleteDialog,
		ExportDialog,
		ImportDialog,
	},
	mounted() {

		this.$tawkMessenger.showWidget();

		this.$tawkMessenger.$on('load', () => {
			// Set user data in tawk for analytics
			this.$tawkMessenger.setAttributes({
				name: this.$store.getters.user.data.full_name,
				email: this.$store.getters.user.data.email,
				tenant_name: this.$store.getters.user.org_data.name
			}, function (error) {
				console.log("Error setting tawk user attributes");
			});
		});



		this.tour = new this.$shepherd.Tour({
			useModalOverlay: true,
			modal: true,
			exitOnEsc: true,
			keyboardNavigation: true,
		});

		this.tour.defaultStepOptions = {
			classes: 'shepherd-theme-dark',
			scrollTo: true,
		};

		this.tour.addStep({
			title: '(1/4) Welcome to Andenia! ',
			text: 'Welcome to Andenia! Here, view current projects and create new ones.',
			attachTo: {
				element: '',
				on: ''
			},
			modalOverlayOpeningPadding: 0,
			modalOverlayOpeningRadius: 4,
			canClickTarget: false,
			buttons: [
				{
					text: 'Skip all tips',
					classes: 'shepherd-button-secondary',
					action: () => this.postFlagOnboarding()
				},
				{
					text: 'Got it',
					classes: 'shepherd-button-primary',
					action: this.tour.next
				}
			],
		});

		this.tour.addStep({
			title: '(2/4) Your Projects',
			text: 'View your own projects here.',
			attachTo: {
				element: '.own-projects-card',
				on: 'right-end'
			},
			modalOverlayOpeningPadding: 0,
			modalOverlayOpeningRadius: 4,
			canClickTarget: false,
			buttons: [
				{
					text: 'Skip all tips',
					classes: 'shepherd-button-secondary',
					action: () => this.postFlagOnboarding()
				},
				{
					text: 'Got it',
					classes: 'shepherd-button-primary',
					action: this.tour.next
				}
			],
		});

		this.tour.addStep({
			title: '(3/4) Import Projects',
			text: 'Easily import projects from other platforms in this section.',
			attachTo: {
				element: '.import-button',
				on: 'left'
			},
			modalOverlayOpeningPadding: 0,
			modalOverlayOpeningRadius: 8,
			canClickTarget: false,
			buttons: [
				{
					text: 'Skip all tips',
					classes: 'shepherd-button-secondary',
					action: () => this.postFlagOnboarding()
				},
				{
					text: 'Got it',
					classes: 'shepherd-button-primary',
					action: this.tour.next
				}
			],
		});

		this.tour.addStep({
			title: '(4/4) New Project',
			text: 'Start a new project via our chatbot interface or by uploading a PRD.',
			attachTo: {
				element: '.create-project-button',
				on: 'left'
			},
			modalOverlayOpeningPadding: 0,
			modalOverlayOpeningRadius: 8,
			canClickTarget: false,
			buttons: [
				{
					text: 'Skip all tips',
					classes: 'shepherd-button-secondary',
					action: () => this.postFlagOnboarding()
				},
				{
					text: 'Got it',
					classes: 'shepherd-button-primary',
					action: () => this.postFlagOnboarding()
				}
			],
		});

	},
	methods: {
		postFlagOnboarding() {
			this.user_profile.onboarding_step = 1;
			this.axios.post('/user/onboarding', {
				step: this.user_profile.onboarding_step,
			})
				.then((response) => {
					this.tour.cancel();
				})
				.catch((error) => {
					console.log(`Sorry, no post flag. ${error}`);
				})
		},
		hideShareDialog() {
			this.sharing_dialog_opened = false;
			this.sharing_project = null;
		},
		openSharing(project) {
			this.sharing_project = project;
			this.sharing_dialog_opened = true;
		},
		hideNewProjectDialog() {
			this.new_project_dialog_opened = false;
		},
		hideDeleteDialog(event) {
			let project_id_remove = event;
			this.delete_dialog_opened = false;
			if (!project_id_remove) {
				return
			}
			const indexInOwned = this.owned_projects.findIndex(project => project.id === project_id_remove);

			if (indexInOwned !== -1) {
				this.owned_projects.splice(indexInOwned, 1);
				return;
			} else {
				console.log(`Project with id ${project_id_remove} not found.`);
			}
		},
		openDelete(project) {
			console.log(JSON.stringify(project));
			this.deleting_project = project;
			this.delete_dialog_opened = true;
		},
		hideExportDialog() {
			this.export_dialog_opened = false;
		},
		openExport(project) {
			console.log(JSON.stringify(project));
			this.exporting_project = project;
			this.export_dialog_opened = true;
		},
		hideImportDialog() {
			this.import_dialog_opened = false;
			this.$posthog.capture("import_dialog_opened");
		},
	},
	created() {
		this.fetchUserProfile(true, true);
	}

}
</script>