<template>
  <v-navigation-drawer :permanent="!collapsed" :expand-on-hover="collapsed" :mini-variant="internalMini"
    @update:mini-variant="val => internalMini = val" app color="neutral800" dark>
    <v-list nav dark >
      <v-list-item style="top: 19px; margin-bottom: 30px;" >
        <router-link to="/projects" class="white--text">
          <div class="d-flex">
            <v-img alt="Andenia" class="shrink mr-2" contain src="../assets/images/andenia_logo.png"
              transition="scale-transition" width="40" height="40" />
            <span class="text-h4 ml-1" style="color: var(--v-primary500-base);">Andenia</span>
          </div>
        </router-link>
      </v-list-item>
      <v-list dense>
        <v-list-item-group v-model="internalSelectedItem" color="primary500">
          <router-link v-for="(item, index) in filteredItems" :key="index" :to="item.to">
            <v-list-item>
              <v-list-item-icon>
                <v-icon :color="item.iconColor">{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  <div :style="item.style">{{ item.title }}</div>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>
        </v-list-item-group>
      </v-list>
    </v-list>

    <v-menu v-model="menu" offset-x top>
      <template v-slot:activator>
        <v-list-item class="user-avatar-section" @click="menu = !menu">
          <v-list-item-avatar>
            <v-avatar size="36">
              <v-img :src="avatar"></v-img>
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="user-name">{{ user_name }}</v-list-item-title>
            <v-list-item-subtitle class="org-name">{{ org_name }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
      <v-list>
        <v-list-item @click="goToSettings" disabled>
          <v-list-item-icon>
            <v-icon>mdi-cog</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Settings</v-list-item-title>
        </v-list-item>
        <v-list-item @click="logout">
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

  </v-navigation-drawer>
</template>


<style scoped>
.user-avatar-section {
  position: absolute;
  bottom: 0%;
  width: 100%;
  cursor: pointer;
}

.user-name {
  color: var(--v-neutral300-base) !important;
  font-weight: bold;
  white-space: normal !important;
  line-height: 1.2em;
}

.org-name {
  color: var(--v-neutral300-base) !important;
  white-space: normal !important;
  line-height: 1.2em;
}
</style>


<script>
export default {
  props: {
    collapsed: {
      type: Boolean,
      default: false
    },
    mini: {
      type: Boolean,
      default: false
    },
    selectedItem: {
      type: Number,
      default: 1
    },
    org_role: {
      type: String,
      default: null
    },
    org_name: {
      type: String,
      default: null
    },
    user_name: {
      type: String,
      default: null
    },
  },
  data() {
    return {
      menu: false,
      internalSelectedItem: this.selectedItem,
      internalMini: this.mini,
      avatar: require("@/assets/images/user.png"),
    };
  },
  watch: {
    selectedItem(newVal) {
      this.internalSelectedItem = newVal;
    },
    internalSelectedItem(newVal) {
      this.$emit('update:selectedItem', newVal);
    },
    mini(newVal) {
      this.internalMini = newVal;
    },
    internalMini(newVal) {
      this.$emit('update:mini', newVal);
    }
  },
  computed: {
    filteredItems() {
      const items = [
        { to: '/dashboard', title: 'Dashboard', icon: 'mdi-view-dashboard-edit', iconColor: 'neutral300', style: { color: 'var(--v-neutral300-base)', fontSize: '16px', justifyContent: 'left', alignItems: 'center', gap: '8px' }, roles: ['organization_admin', 'project_management_officer'] },
        { to: '/projects', title: 'Projects', icon: 'mdi-file-cabinet', iconColor: 'neutral300', style: { color: 'var(--v-neutral300-base)', fontSize: '16px', justifyContent: 'left', alignItems: 'center', gap: '8px' }, roles: ['organization_admin', 'project_management_officer', 'organization_member'] },
        { to: '/calendar', title: 'Calendar', icon: 'mdi-calendar-blank-outline', iconColor: 'neutral300', style: { color: 'var(--v-neutral300-base)', fontSize: '16px', justifyContent: 'left', alignItems: 'center', gap: '8px' }, roles: ['organization_admin', 'project_management_officer', 'organization_member'] },
        { to: '/analytics', title: 'Analytics', icon: 'mdi-chart-box', iconColor: 'neutral300', style: { color: 'var(--v-neutral300-base)', fontSize: '16px', justifyContent: 'left', alignItems: 'center', gap: '8px' }, roles: ['organization_admin', 'project_management_officer'] },
        { to: '/integrations', title: 'Integrations', icon: 'mdi-connection', iconColor: 'neutral300', style: { color: 'var(--v-neutral300-base)', fontSize: '16px', justifyContent: 'left', alignItems: 'center', gap: '8px' }, roles: ['organization_admin', 'project_management_officer'] },
        { to: '/messages', title: 'Messages', icon: 'mdi-message-text', iconColor: 'neutral300', style: { color: 'var(--v-neutral300-base)', fontSize: '16px', justifyContent: 'left', alignItems: 'center', gap: '8px' }, roles: ['organization_admin', 'project_management_officer', 'organization_member'] }
      ];
      return items.filter(item => item.roles.includes(this.org_role));
    }
  },
  methods: {
    logout() {
      console.log("logout");
      this.$store.dispatch("logout");
    },
    goToSettings() {
      console.log("Navigating to settings");
    }
  }
};
</script>
