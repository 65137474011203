<template>
    <div class="app">
        <v-app-bar color="neutral800">
            <v-toolbar-title class="ml-4">
                <router-link to="/projects" class="white--text">
                    <v-img alt="Andenia" class="shrink mr-2" contain :src="require('@/assets/images/andenia_logo.png')"
                        transition="scale-transition" width="40" height="40" />
                </router-link>
            </v-toolbar-title>
            <v-spacer></v-spacer>

            <v-stepper v-model="selectedStep" class="stepper" dark>
                <v-stepper-header>
                    <v-stepper-step v-for="(step, index) in steps" color='primary500' :key="step"
                        :complete="step < selectedStep" :step="step">
                        {{ stepNames[index] }}
                        <v-icon v-if="index < steps.length - 1"
                            :color="step <= selectedStep ? 'primary500' : ''">mdi-chevron-right</v-icon>
                    </v-stepper-step>
                </v-stepper-header>
            </v-stepper>

            <v-spacer></v-spacer>
            <v-btn icon class="mr-4" @click="restartTour" color="neutral200">
                <v-icon>mdi-help-circle-outline</v-icon>
            </v-btn>
        </v-app-bar>

        <v-row class="mt-1" justify="center" align="center">
            <v-col cols="4">
                <v-tabs v-model="tabIndex" height="45px" @change="updateVisualizationType" hide-slider fixed-tabs
                    background-color="primary500" class="select-tabs">
                    <v-tab class="mx-1 my-1 v-tab-chatbot" value="chatbot">
                        <h4>Chat Bot</h4>
                    </v-tab>
                    <v-tab class="mx-1 my-1 v-tab-project" value="project_overview" @click="toggleResumeTourOverview">
                        <h4>Project Overview</h4>
                    </v-tab>
                </v-tabs>
            </v-col>
        </v-row>


        <v-row>

            <v-card class='mx-auto content-card d-flex flex-column'>
                <v-tabs-items v-model="visualization_type" class="fill-height">
                    <v-tab-item value="chatbot" class="fill-height">

                        <!-- Chatbot section -->
                        <v-card class="d-flex flex-column fill-height chatbot-section">
                            <!-- Message history -->
                            <v-card-text class="chat-history" id="chat_history">
                                <v-list dense>
                                    <v-list-item v-for="(message, index) in messages" :key="index"
                                        class="message-container"
                                        :class="{ 'user-container': message.from === 'user', 'bot-container': message.from !== 'user' }">
                                        <!-- User message -->
                                        <v-list-item-content v-if="message.from === 'user'"
                                            class="conversation-bubble-user">
                                            <v-list-item-title v-html="message.data"
                                                class="no-ellipsis"></v-list-item-title>
                                        </v-list-item-content>
                                        <!-- Avatar user and bot -->
                                        <v-list-item-avatar>
                                            <v-avatar color="primary400" v-if="message.from === 'user'">
                                                <v-icon dark>mdi-account-circle</v-icon>
                                            </v-avatar>
                                            <v-avatar v-else>
                                                <img :src="require('@/assets/images/avatar_sapo.jpeg')" alt="John">
                                            </v-avatar>
                                        </v-list-item-avatar>
                                        <!-- Bot message -->
                                        <v-list-item-content v-if="message.from !== 'user'"
                                            class="conversation-bubble-bot">
                                            <v-list-item-title v-html="message.data"
                                                class="no-ellipsis"></v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-card-text>
                            <!-- Input box message -->
                            <v-card-actions class="d-flex align-center flex-shrink-1">
                                <v-textarea v-model="currentMessage" auto-grow outlined dense label="Type a message"
                                    append-icon="mdi-keyboard" @keyup.enter="sendMessage(currentMessage)"
                                    :disabled="chatbotDone" class="custom-textarea" rows="1" no-resize hide-details>
                                </v-textarea>
                                <v-btn @click="sendMessage(currentMessage)" icon color="primary500" x-large
                                    :disabled="currentMessage === '' || chatbotDone">
                                    <v-icon>mdi-send-circle</v-icon>
                                </v-btn>
                            </v-card-actions>
                        </v-card>

                    </v-tab-item>

                    <v-tab-item value="project_overview" class="chat-history fill-height">
                        <v-form class="mx-6 d-flex flex-column">
                            <v-skeleton-loader class="fill-height" :loading="overviewLoading"
                                type="table-heading, list-item-three-line, image, table-tfoot">
                                <v-container class="d-flex fill-height">
                                    <v-row class="mt-1 d-flex">
                                        <v-col class="mx-10 text-start">
                                            <h2>{{ this.projectName }}</h2>
                                        </v-col>
                                        <v-col class="text-end">
                                            <v-btn class="edit-project-overview" v-if="!chatbotDone"
                                                @click="showFinishedChatbotDialog = true" color="primary500" dark
                                                :disabled="chatbotDone" elevated right>
                                                Edit Project Overview
                                            </v-btn>
                                            <v-btn class="mr-5 save-project-overview" v-if="chatbotDone"
                                                color="primary500" outlined @click="saveProjectStructure()">
                                                <v-icon>mdi-content-save</v-icon>
                                                Save
                                            </v-btn>
                                            <v-btn class="done-project-overview" v-if="chatbotDone"
                                                :disabled="!validateFieds" color="success500" @click="createTasks()">
                                                <v-icon>mdi-check-circle-outline</v-icon>
                                                Done
                                            </v-btn>
                                        </v-col>

                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <h4>Description</h4>
                                            <v-textarea filled label="Description" auto-grow solo flat outlined dense
                                                rows="1" no-resize hide-details
                                                v-model="projectStructure.project_description"
                                                :disabled="!chatbotDone"></v-textarea>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="4">
                                            <h4>Start Date</h4>
                                            <v-menu v-model="menuStartDate" :close-on-content-click="false"
                                                :nudge-right="40" transition="scale-transition" offset-y
                                                min-width="auto">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field :value="formatDate(projectStructure.start_date)"
                                                        label="Start Date" append-icon="mdi-calendar" readonly
                                                        v-bind="attrs" v-on="on" dense solo flat outlined
                                                        :disabled="!chatbotDone"></v-text-field>
                                                </template>
                                                <v-date-picker v-model="projectStructure.start_date"
                                                    @input="menuStartDate = false"></v-date-picker>
                                            </v-menu>
                                        </v-col>
                                        <v-col cols="4">
                                            <h4>End Date</h4>
                                            <v-menu v-model="menuEndDate" :close-on-content-click="false"
                                                :nudge-right="40" transition="scale-transition" offset-y
                                                min-width="auto">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field :value="formatDate(projectStructure.end_date)"
                                                        label="End Date" append-icon="mdi-calendar" readonly
                                                        v-bind="attrs" v-on="on" dense solo flat outlined
                                                        :disabled="!chatbotDone"></v-text-field>
                                                </template>
                                                <v-date-picker v-model="projectStructure.end_date"
                                                    @input="menuEndDate = false"></v-date-picker>
                                            </v-menu>
                                        </v-col>
                                        <v-col cols="4">
                                            <h4>Project Area</h4>
                                            <v-text-field v-model="projectStructure.area" label="Project Area" dense
                                                solo flat outlined :disabled="!chatbotDone"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="3">
                                            <v-row>
                                                <h4 class="mx-3">T-Shirt Size Conversion</h4>
                                            </v-row>
                                            <v-row>
                                                <v-data-table :headers="headers_tshirt"
                                                    :items="projectStructure.project_tshirt_size" hide-default-footer
                                                    class="outlined-data-table mx-3 mb-6">
                                                    <template v-slot:[`item.hour_min`]="{ item }">
                                                        <v-text-field v-model="item.hour_min" dense solo flat outlined
                                                            hide-details type="number"
                                                            :disabled="!chatbotDone"></v-text-field>
                                                    </template>
                                                    <template v-slot:[`item.hour_max`]="{ item }">
                                                        <v-text-field v-model="item.hour_max" dense solo flat outlined
                                                            hide-details type="number"
                                                            :disabled="!chatbotDone"></v-text-field>
                                                    </template>
                                                </v-data-table>
                                            </v-row>

                                        </v-col>
                                        <v-col cols="9">
                                            <v-row>
                                                <h4 class="mx-3">Tools</h4>
                                            </v-row>
                                            <v-row>
                                                <v-combobox v-model="projectStructure.tools" :items="availableTools"
                                                    class="mx-3" label="Add some tags" multiple outlined dense
                                                    small-chips solo flat deletable-chips hide-no-data hide-selected
                                                    @input="handleInput" :disabled="!chatbotDone"
                                                    :menu-props="{ bottom: true, offsetY: true }"></v-combobox>
                                            </v-row>
                                            <v-row align="center" justify="space-between">
                                                <h4 class="mx-3">Team:</h4>
                                                <v-btn @click="addRole" color="primary500" dark class="mx-3"
                                                    :disabled="!chatbotDone">
                                                    <v-icon left>mdi-plus</v-icon>
                                                    New Role
                                                </v-btn>
                                            </v-row>
                                            <v-row>
                                                <v-col>
                                                    <v-data-table :headers="headers" :items="projectStructure.team"
                                                        class="outlined-data-table" hide-default-footer
                                                        :items-per-page="-1">
                                                        <template v-slot:item.action="{ item, index: subindex }">
                                                            <v-icon class="mr-2" @click="editItem(item, subindex)"
                                                                :disabled="!chatbotDone">mdi-pencil</v-icon>
                                                            <v-icon class="mr-2" @click="confirmDeleteItem(subindex)"
                                                                :disabled="!chatbotDone">mdi-delete</v-icon>
                                                        </template>
                                                    </v-data-table>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>


                                </v-container>
                            </v-skeleton-loader>
                        </v-form>
                    </v-tab-item>
                </v-tabs-items>

                <!-- Message after push Done button to create tasks -->
                <v-overlay :value="overlay">
                    <v-card class="dialog-content">
                        <v-card-title>
                            <h2>
                                The tasks are being created!
                            </h2>
                        </v-card-title>
                        <v-progress-circular :size="70" :width="7" color="primary500"
                            indeterminate></v-progress-circular>
                    </v-card>
                </v-overlay>

                <!-- Message to confirm to finish the chatbot interaction -->
                <v-dialog v-model="showFinishedChatbotDialog" max-width="500">
                    <v-card>
                        <v-card-title class="headline">End the conversation</v-card-title>
                        <v-card-text>Are you finished describing your project? You'll be prompted to review and complete
                            the
                            overview information</v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="warning600" text @click="showFinishedChatbotDialog = false">Cancel</v-btn>
                            <v-btn color="success600" text @click="finishConversation()">Finish</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <!-- Message to delete role -->
                <v-dialog v-model="dialogDelete" max-width="500px">
                    <v-card>
                        <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="success500" dark @click="dialogDelete = false">Cancel</v-btn>
                            <v-btn color="error500" dark @click="deleteRole">Delete</v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <!-- Dialog to edit or create rol -->
                <v-dialog v-model="showEditDialog" max-width="700px">
                    <v-card>
                        <v-card-title>
                            {{ currentEditIndex >= 0 ? 'Edit Role' : 'Add New Role' }}
                        </v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col cols="6">
                                    <h4>Rol</h4>
                                    <v-text-field v-model="currentEditItem.role" label="Rol" dense solo></v-text-field>
                                </v-col>
                                <v-col cols="3">
                                    <h4>Cost per Hour</h4>
                                    <v-text-field v-model="currentEditItem.costPerHour" label="Cost/Hour" dense solo
                                        type="number"
                                        :rules="[v => (!v || v > 0) || 'The number must be positive']"></v-text-field>
                                </v-col>
                                <v-col cols="3">
                                    <h4>Number</h4>
                                    <v-text-field v-model="currentEditItem.number" label="Number" dense solo
                                        type="number"
                                        :rules="[v => (!v || v > 0) || 'The number must be positive']"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary500" text @click="showEditDialog = false">Cancel</v-btn>
                            <v-btn color="primary500" text @click="saveRoleChanges">Save</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <v-snackbar v-model="creation_failed_snackbar" color="warning500" multiLine>
                    <v-icon color="warning100">mdi-alert</v-icon>
                    <span class="message-text-engine">Andenia engine is currently down, please try again later</span>
                    <v-btn icon @click="creation_failed_snackbar = false">
                        <v-icon color="warning100">mdi-close</v-icon>
                    </v-btn>
                </v-snackbar>

                <v-snackbar v-model="project_saved" timeout="2000" color="success500" multiLine>
                    <v-icon color="success100">mdi-content-save-check</v-icon>
                    Project overview correctly saved!
                    <v-btn icon @click="project_saved = false">
                        <v-icon color="success100">mdi-close</v-icon>
                    </v-btn>
                </v-snackbar>

                <v-snackbar v-model="isProjectOverviewDone" color="success500" multiLine>
                    <v-icon color="success100">mdi-content-save-check</v-icon>
                    All details are accurate! Go to <b>Project Overview</b>
                    <v-btn icon @click="isProjectOverviewDone = false">
                        <v-icon color="success100">mdi-close</v-icon>
                    </v-btn>
                </v-snackbar>

            </v-card>
        </v-row>
    </div>
</template>


<style scoped>
.message-container {
    display: flex;
    margin-bottom: 10px;
    width: fit-content;
    max-width: 70%;
}

.message-text-engine {
    color: var(--v-warning100-base);
}

.conversation-bubble-bot {
    background-color: var(--v-primary100-base);
    border: 1px solid var(--v-primary500-base);
    border-radius: 0 8px 8px 8px;

}

.conversation-bubble-user {
    background-color: var(--v-primary100-base);
    border: 1px solid var(--v-primary500-base);
    border-radius: 8px 0 8px 8px;
}

.select-tabs {
    border-radius: 11px;
}

.select-tabs .v-tab.v-tab--active {
    color: var(--v-neutral800-base);
    background-color: var(--v-neutral100-base)
}

.select-tabs .v-tab:not(.v-tab--active) {
    color: var(--v-neutral100-base) !important;
}

.v-tab-chatbot {
    border-radius: 8px;
}

.v-tab-project {
    border-radius: 8px;
}

.no-ellipsis {
    white-space: normal !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 22px !important;
    font-family: Epilogue !important;
    color: var(--v-primary900-base) !important;
    padding-left: 9px;
    padding-right: 9px;
}

.app {
    font-family: Inter;
    height: 100vh;
    width: 100vw;
}

.stepper {
    background-color: transparent !important;
    box-shadow: none !important;
}

.custom-textarea {
    max-height: 100px !important;
    overflow-y: auto !important;
    padding: 7px;
}

.content-card {
    height: calc(100vh - 200px);
    width: 80%;
}

.chat-history {
    flex-grow: 1;
    overflow-y: auto;
}

.chat-history::-webkit-scrollbar,
.custom-textarea::-webkit-scrollbar {
    width: 8px;
    border-radius: 4px;
    background-color: var(--v-primary100-base);
}

.chat-history::-webkit-scrollbar-thumb,
.custom-textarea::-webkit-scrollbar-thumb {
    background-color: var(--v-primary500-base);
    /* Color del pulgar de la barra de desplazamiento */
    border-radius: 4px;
    /* Puntas redondeadas del pulgar */
}

.chat-history::-webkit-scrollbar-track,
.custom-textarea::-webkit-scrollbar-track {
    background-color: var(--v-primary100-base);
    /* Color de fondo de la pista de la barra de desplazamiento */
    border-radius: 4px;
    /* Puntas redondeadas del pulgar */
}

.chat-history::-webkit-scrollbar-track:hover,
.custom-textarea::-webkit-scrollbar-track:hover {
    background-color: var(--v-primary100-base);
    /* Color de fondo de la pista de la barra de desplazamiento al pasar el cursor */
    border-radius: 4px;
    /* Puntas redondeadas del pulgar */
}

.bot-container {
    margin-left: 20px;
    margin-right: auto;
}

.bot-container .message {
    background-color: var(--v-primary100-base);
    border: 1px solid var(--v-primary500-base);
    border-radius: 0 8px 8px 8px;
}

.user-container {
    margin-left: auto;
    margin-right: 20px;
    justify-content: flex-end;
}

.user-container .message {
    background-color: var(--v-neutral200-base);
    border-radius: 8px 8px 0 8px;
    word-break: break-word;
}

.dialog-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    border-radius: 8px;
    background-color: var(--v-neutral100-base) !important;
    color: var(--v-neutral800-base) !important
}

.outlined-data-table {
    border: 1px solid var(--v-neutral400-base) !important;
    border-radius: 4px !important;
}
</style>

<script>
import axios from 'axios';

export default {
    name: 'Chatbot',
    data() {
        return {
            id: this.$route.params.project_id,
            currentMessage: '',
            projectStructure: {},
            chatbotDone: false,
            overviewLoading: false,
            newDataAvailable: false,
            showFinishedChatbotDialog: false,
            projectName: "Project Name",
            messages: [],
            initialMessages: [
                { from: 'andenia_bot', data: "Welcome to Andenia chatbot interface! I'll guide you through the steps required to <b>set up a new project in Andenia</b>. You can save or edit your progress at any time. Once ready, click done!" },
                { from: 'andenia_bot', data: "Let's start with the project description! <b>Could you provide a quick summary of your project?</b>" }
            ],
            headers: [
                { text: 'Rol', value: 'name', sortable: false },
                { text: 'Cost/Hour', value: 'cost', align: 'center', width: '120px', sortable: false },
                { text: 'Number', value: 'number', align: 'center', width: '120px', sortable: false },
                { text: 'Delete', value: 'action', align: 'center', width: '120px', sortable: false },
            ],
            headers_tshirt: [
                { text: 'T-shirt', value: 'size', align: 'center', width: '75px', sortable: false },
                { text: 'Min Hour', value: 'hour_min', align: 'center', sortable: false },
                { text: 'Max Hour', value: 'hour_max', align: 'center', sortable: false },
            ],
            newTag: '',
            selectedStep: 1,
            steps: [1, 2, 3, 4],
            stepNames: ['Description', 'Validation', 'Time and Dependencies', 'Finish'],
            overlay: false,
            creation_failed_snackbar: false,
            project_saved: false,
            onboarding_step: null,
            tourChatbot: null,
            tourOverview: null,
            tourOverviewEdit: null,
            isTourRestarted: false,
            tabIndex: 0,
            visualization_type: 'chatbot',
            availableTools: [],
            dialogDelete: false,
            showEditDialog: false,
            currentEditIndex: -1,
            currentEditItem: {
                role: '',
                costPerHour: 1,
                number: 1,
            },
            menuStartDate: false,
            menuEndDate: false,
            isProjectOverviewDone: false,
            isValidTshirtSize: true
        };
    },
    methods: {
        formatDate(dateValue) {
            if (!dateValue) return null;
            const d = new Date(dateValue);
            const options = { year: 'numeric', month: 'short', day: 'numeric' };
            return d.toLocaleDateString('en-US', options);
        },
        updateVisualizationType(index) {
            const types = ['chatbot', 'project_overview'];
            this.visualization_type = types[index];
            if (this.visualization_type == 'project_overview') {
                this.$posthog.capture('chatbot_to_project_overview_opened', {
                    project_id: this.id
                });
                this.updateOverview();
            }
        },
        getProjectName() {
            this.axios.get(`/projects/${this.id}`)
                .then((response) => {
                    this.projectName = response.data.name;
                })
                .catch((error) => {
                    console.log(`An error has ocurred while fetching the project data:  ${error}`);
                })

        },
        scrollDown() {
            setTimeout(() => {
                var objDiv = document.getElementById("chat_history");
                objDiv.scrollTop = objDiv.scrollHeight;
            }, 0)
        },
        finishConversation() {
            axios
                .post(`/projects/${this.id}/edit`, {
                    fields: { project_status: "chatbot_conversation_ended" },
                })
                .then((response) => {
                    this.chatbotDone = true;
                    let finish_message = "Thank you for your input! Please check the project details on the right and feel free to edit it if necessary by pressing the <b>Edit</b> button. Once finished, click the <b>Done</b> button to trigger the tasks generation.";
                    this.messages.push({
                        from: 'andenia_bot',
                        data: finish_message,
                    });
                    this.scrollDown();
                    this.$posthog.capture('chatbot_conversation_finished', {
                        project_id: this.id
                    });
                })
                .catch((error) => {
                    console.log(
                        "Error occurred while finishing the conversation"
                    );
                });
            this.showFinishedChatbotDialog = false;

            if (this.onboarding_step == 3) {
                this.onboarding_step = 4;
                setTimeout(() => {
                    this.resumeTourOverviewEdit();
                }, 1000);
            }


        },
        createTasks() {
            this.overlay = true;
            this.projectStructure.project_name = this.projectName;
            this.projectStructure.project_status = "chatbot_finished";
            this.projectStructure.project_id = this.id;
            this.$posthog.capture('initial_tasks_creation_started', {
                project_id: this.id
            });
            axios
                .post(`/projects/${this.id}/edit`, {
                    fields: this.projectStructure,
                })
                .then((response) => {
                    axios
                        .post(`/tasks/${this.id}/chatbot_tasks`, {
                            project_details: this.projectStructure,
                        })
                        .then((response) => {
                            this.$posthog.capture('initial_tasks_creation_finished', {
                                project_id: this.id
                            });
                            this.$router.push(`/taskvalidation/${this.id}`);
                        })
                        .catch((error) => {
                            this.overlay = false;
                            this.creation_failed_snackbar = true;
                            this.$posthog.capture('initial_tasks_creation_failed', {
                                project_id: this.id,
                                error_msg: error
                            });
                        });
                })
                .catch((error) => {
                    this.overlay = false;
                    this.creation_failed_snackbar = true;
                });
        },
        async updateOverview() {
            if (this.newDataAvailable) {
                this.overviewLoading = true;
                await axios
                    .post(`/chatbots/${this.id}/overview_structure`, {})
                    .then((response) => {
                        let response_data = response.data;
                        if ('is_structure' in response_data) {
                            if (response_data.is_structure) {
                                this.projectStructure = response_data.project_structure;
                            }
                        }
                        this.overviewLoading = false;
                        this.newDataAvailable = false;
                    })
                    .catch((error) => {
                        this.creation_failed_snackbar = true;
                        this.overviewLoading = false;
                        this.newDataAvailable = false;
                    });
            }
        },
        async sendMessage(message) {
            this.messages.push({
                from: 'user',
                data: message,
            });
            this.currentMessage = '';
            this.scrollDown();
            await axios
                .post(`/projects/${this.id}/chatbot_overview`, {
                    message: message,
                })
                .then((response) => {
                    if (this.chatbotDone == true) {
                        console.log("message ignored");
                    } else {
                        let response_data = response.data;
                        this.messages.push({
                            from: 'andenia_bot',
                            data: response_data.data,
                        });
                        this.isProjectOverviewDone = response_data.chatbot_done
                        if (this.projectName === "Project Name") {
                            this.projectName = response_data.project_name;
                        }

                        this.newDataAvailable = true;
                        this.scrollDown();

                    }
                });
        },
        saveProjectStructure() {
            const isArrayFieldEmpty = (field) => {
                return Array.isArray(field) && (field === null || field.length === 0);
            };

            if (Object.keys(this.projectStructure).length === 0) {
                return false;
            }
            if (
                this.projectStructure.end_date === null &&
                this.projectStructure.start_date === null &&
                this.projectStructure.project_description === null &&
                isArrayFieldEmpty(this.projectStructure.team) &&
                isArrayFieldEmpty(this.projectStructure.tools)
            ) {
                return false;
            }

            let saveStructure = {
                end_date: this.projectStructure.end_date,
                start_date: this.projectStructure.start_date,
                project_name: this.projectName,
                description: this.projectStructure.project_description,
                tools: this.projectStructure.tools,
                project_tshirt_size: this.projectStructure.project_tshirt_size,
                team: this.projectStructure.team
            }
            axios
                .post(`/projects/${this.id}/edit`, {
                    fields: saveStructure,
                })
                .then((response) => {
                    this.project_saved = true;
                });
            return true;
        },
        initializeTools() {
            this.availableTools = [...this.projectStructure.tools];
        },
        handleInput(newSelectedTools) {
            const newTools = newSelectedTools.filter(tool => !this.availableTools.includes(tool));
            this.availableTools.push(...newTools);
        },

        addRole() {
            this.currentEditIndex = -1;
            this.currentEditItem;
            this.showEditDialog = true;
        },
        editItem(item, index) {
            this.currentEditIndex = index;
            this.currentEditItem.role = item.name;
            this.currentEditItem.costPerHour = item.cost;
            this.currentEditItem.number = item.number;
            this.showEditDialog = true;
        },
        saveRoleChanges() {
            if (this.currentEditIndex >= 0) {
                // Update existing role
                let role = this.projectStructure.team[this.currentEditIndex];
                role.name = this.currentEditItem.role;
                role.cost = parseFloat(this.currentEditItem.costPerHour);
                role.number = parseInt(this.currentEditItem.number);
                // Aditional validation
                role.cost = isNaN(role.cost) ? 0 : (role.cost < 0 ? 0 : role.cost);
                role.number = isNaN(role.number) ? 0 : (role.number < 0 ? 0 : role.number);
            } else {
                // Create new rol
                this.projectStructure.team.push({
                    name: this.currentEditItem.role,
                    cost: parseFloat(this.currentEditItem.costPerHour),
                    number: parseInt(this.currentEditItem.number),
                });
            }

            this.currentEditIndex = -1;
            this.showEditDialog = false;

        },
        confirmDeleteItem(item) {
            this.dialogDelete = true;
            this.deletingRoleIndex = item;
        },
        deleteRole() {
            this.projectStructure.team.splice(this.deletingRoleIndex, 1);
            this.dialogDelete = false;
        },
        restartTour() {
            this.$posthog.capture('onboarding_tour_restarted_chatbot');
            this.isTourRestarted = true;
            if (this.visualization_type === 'chatbot') {
                if (this.tourChatbot) {
                    this.tourChatbot.start();
                }
            } else {
                if (this.chatbotDone) {
                    // continuedTourEdit
                    if (this.tourOverviewEdit) {
                        this.tourOverviewEdit.start();
                    } else {
                        this.resumeTourOverviewEdit();
                    }
                } else {
                    // continuedTour
                    if (this.tourOverview) {
                        this.tourOverview.start();
                    } else {
                        this.resumeTourOverview();
                    }
                }
            }
        },
        postFlagOnboarding() {
            let tourList = [this.tourChatbot, this.tourOverview, this.tourOverviewEdit];
            if (!this.isTourRestarted) {
                this.axios.post('/user/onboarding', {
                    step: this.onboarding_step,
                })
                    .then((response) => {
                        // console.log(response.data);
                        tourList.forEach((item) => {
                            if (item) {
                                item.cancel();
                            }
                        });
                        // console.log(this.onboarding_step)
                    })
                    .catch((error) => {
                        console.log(`Sorry, no post flag. ${error}`);
                    })
            }
            else {
                tourList.forEach((item) => {
                    if (item) {
                        item.cancel();
                    }
                });
                this.isTourRestarted = false;
            }
        },
        getFlagOnboarding() {
            this.axios.get('/user/onboarding')
                .then((response) => {
                    // console.log(response.data);

                    this.onboarding_step = response.data.step;
                    if (response.data.step <= 1) {
                        this.onboarding_step = 2;
                        this.tourChatbot.start();
                    }
                })
                .catch((error) => {
                    console.log(`Sorry, no flag. ${error}`);
                })
        },
        toggleResumeTourOverview() {
            if (this.onboarding_step == 2) {
                this.onboarding_step = 3;
                setTimeout(() => {
                    this.resumeTourOverview();
                }, 1000);
            }
        },
        resumeTourOverview() {
            this.tourOverview = new this.$shepherd.Tour({
                useModalOverlay: true,
                modal: true,
                exitOnEsc: true,
                keyboardNavigation: true,
            });

            this.tourOverview.defaultStepOptions = {
                classes: 'shepherd-theme-dark',
                scrollTo: true,
            };

            this.tourOverview.addStep({
                title: '(1/1) Finish ChatBot and Edit',
                text: 'Complete ChatBot setup to begin editing project details.',
                attachTo: {
                    element: '.edit-project-overview',
                    on: 'left'
                },
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 4,
                canClickTarget: false,
                buttons: [
                    {
                        text: 'Skip all tips',
                        classes: 'shepherd-button-secondary',
                        action: () => this.postFlagOnboarding()

                    },
                    {
                        text: 'Got it',
                        classes: 'shepherd-button-primary',
                        action: () => this.postFlagOnboarding()
                    }
                ],
            });

            // Iniciar la segunda parte del tour
            this.tourOverview.start();
        },
        resumeTourOverviewEdit() {
            this.tourOverviewEdit = new this.$shepherd.Tour({
                useModalOverlay: true,
                modal: true,
                exitOnEsc: true,
                keyboardNavigation: true,
            });

            this.tourOverviewEdit.defaultStepOptions = {
                classes: 'shepherd-theme-dark',
                scrollTo: true,
            };

            this.tourOverviewEdit.addStep({
                title: '(1/2) Save',
                text: 'Save your information effortlessly right here.',
                attachTo: {
                    element: '.save-project-overview',
                    on: 'left'
                },
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 4,
                canClickTarget: false,
                buttons: [
                    {
                        text: 'Skip all tips',
                        classes: 'shepherd-button-secondary',
                        action: () => this.postFlagOnboarding()
                    },
                    {
                        text: 'Got it',
                        classes: 'shepherd-button-primary',
                        action: this.tourOverviewEdit.next
                    }
                ],
            });

            this.tourOverviewEdit.addStep({
                title: '(2/2) Done',
                text: 'After verification, proceed to task creation.',
                attachTo: {
                    element: '.done-project-overview',
                    on: 'left'
                },
                modalOverlayOpeningPadding: 0,
                modalOverlayOpeningRadius: 4,
                canClickTarget: false,
                buttons: [
                    {
                        text: 'Skip all tips',
                        classes: 'shepherd-button-secondary',
                        action: () => this.postFlagOnboarding()
                    },
                    {
                        text: 'Got it',
                        classes: 'shepherd-button-primary',
                        action: () => this.postFlagOnboarding()
                    }
                ],
            });


            // Iniciar la segunda parte del tour
            this.tourOverviewEdit.start();
        },
    },
    mounted() {
        this.tourChatbot = new this.$shepherd.Tour({
            useModalOverlay: true,
            modal: true,
            exitOnEsc: true,
            keyboardNavigation: true,
        });

        this.tourChatbot.defaultStepOptions = {
            classes: 'shepherd-theme-dark',
            scrollTo: true,
        };

        this.tourChatbot.addStep({
            title: '(1/4) Start to materialize your idea ',
            text: 'Materialize your idea: Utilize the chatbot to describe project details, team, and preferred tools.',
            attachTo: {
                element: '',
                on: ''
            },
            modalOverlayOpeningPadding: 0,
            modalOverlayOpeningRadius: 0,
            canClickTarget: false,
            buttons: [
                {
                    text: 'Skip all tips',
                    classes: 'shepherd-button-secondary',
                    action: () => this.postFlagOnboarding()
                },
                {
                    text: 'Got it',
                    classes: 'shepherd-button-primary',
                    action: this.tourChatbot.next
                }
            ],
        });

        this.tourChatbot.addStep({
            title: '(2/4) Chat Bot Section',
            text: 'Describe your project idea using the chatbot interface in this section.',
            attachTo: {
                element: '.v-tab-chatbot',
                on: 'bottom'
            },
            modalOverlayOpeningPadding: 0,
            modalOverlayOpeningRadius: 8,
            canClickTarget: false,
            buttons: [
                {
                    text: 'Skip all tips',
                    classes: 'shepherd-button-secondary',
                    action: () => this.postFlagOnboarding()
                },
                {
                    text: 'Got it',
                    classes: 'shepherd-button-primary',
                    action: this.tourChatbot.next
                }
            ],
        });

        this.tourChatbot.addStep({
            title: '(3/4) Project Overview Section',
            text: 'Access main project information extracted from your conversation in this section.',
            attachTo: {
                element: '.v-tab-project',
                on: 'bottom'
            },
            modalOverlayOpeningPadding: 0,
            modalOverlayOpeningRadius: 8,
            canClickTarget: false,
            buttons: [
                {
                    text: 'Skip all tips',
                    classes: 'shepherd-button-secondary',
                    action: () => this.postFlagOnboarding()
                },
                {
                    text: 'Got it',
                    classes: 'shepherd-button-primary',
                    action: this.tourChatbot.next
                }
            ],
        });

        this.tourChatbot.addStep({
            title: '(4/4) Chat Area',
            text: 'View your ongoing conversation with our ChatBot in this section.',
            attachTo: {
                element: '.chatbot-section',
                on: 'right'
            },
            modalOverlayOpeningPadding: 0,
            modalOverlayOpeningRadius: 4,
            canClickTarget: false,
            buttons: [
                {
                    text: 'Skip all tips',
                    classes: 'shepherd-button-secondary',
                    action: () => this.postFlagOnboarding()
                },
                {
                    text: 'Got it',
                    classes: 'shepherd-button-primary',
                    action: () => this.postFlagOnboarding()
                }
            ],
        });

    },
    computed: {
        validateFieds() {
            // Check that project structure is not an empty object (first load)
            if (Object.keys(this.projectStructure).length === 0) {
                return false;
            }


            let isInvalidTshirtSize = true;

            // Check values in T-shirt size table
            if (this.projectStructure.project_tshirt_size !== null) {
                isInvalidTshirtSize = this.projectStructure.project_tshirt_size.some(size => {
                    return size.hour_min === null || size.hour_min < 0 ||
                        size.hour_max === null || size.hour_max < 0 ||
                        size.hour_min >= size.hour_max ||
                        size.hour_min === '' || size.hour_max === '';
                });
            }

            // If field type is array, check if it's empty
            const isArrayFieldEmpty = (field) => {
                return Array.isArray(field) && (field === null || field.length === 0);
            };

            if (
                this.projectStructure.end_date === null ||
                this.projectStructure.project_description === null || this.projectStructure.project_description === '' ||
                this.projectStructure.area === null || this.projectStructure.area === '' ||
                isArrayFieldEmpty(this.projectStructure.team) ||
                isArrayFieldEmpty(this.projectStructure.tools) ||
                isInvalidTshirtSize
            ) {
                // If at least one field is empty, return it as not validated
                return false;
            } else {
                console.log("Step 5")
                // All fields are valid
                return true;
            }
        }
    },
    watch: {
        isProjectOverviewDone(newVal) {
            this.$posthog.capture('chatbot_ready_for_project_overview', {
                project_id: this.id
            });
        },
    },
    created() {

        this.getFlagOnboarding();
        this.getProjectName();
        console.log("Starting the chatbot");
        axios
            .get(`/projects/${this.id}/overview_chat_history`)
            .then((response) => {
                let initial_data = response.data;
                if (initial_data.redirect) {
                    this.$router.push(`/projects`);
                }

                this.messages.push(...this.initialMessages);

                if (initial_data.chatbot_history) {
                    this.messages.push(...initial_data.chatbot_history);
                    this.scrollDown();
                } else {
                    this.scrollDown();
                }

                this.projectStructure = initial_data.project_structure;
                // console.log(this.projectStructure);
                this.initializeTools();
                this.chatbotDone = initial_data.is_chatbot_done;
                if (this.chatbotDone) {
                    this.tabIndex = 1
                    this.visualization_type = 'project_overview'
                }

            })
            .catch((error) => {
                console.log(
                    "Error occurred while fetching initial chatbot for project: " + this.id
                );
            });
    }
};
</script>