<template>
	<v-dialog max-width="1000px" v-model="value" @click:outside="closeDialog" v-if="project">
		<v-card>
			<v-card-title>
				<h1>Access Management</h1>
			</v-card-title>
			<v-card-text class="mt-6">
				<v-row>
					<v-col>
						<h2>Add Users</h2>
					</v-col>
				</v-row>
				<v-row>
					<v-form class="d-flex">
						<v-col>
							<v-select label="User Name" dense outlined v-model="newUser" :items="tenantUsers"
								item-text="full_name" item-value="id"></v-select>
						</v-col>
						<v-col>
							<v-select dense label="Access Role" outlined v-model="selectedRole" :items="platformRoles"
								item-text="name" item-value="role_id"></v-select>
						</v-col>
						<v-col>
							<v-btn class="ml-2" color="primary500" dark @click="addUser">Add user</v-btn>
						</v-col>
					</v-form>
					<v-alert class="mt-3" v-model="errorVisible" color="error500" dismissible>
						{{ errorMsg }}</v-alert>
				</v-row>
				<v-row>
					<v-col>
						<h2>Users With Access</h2>
					</v-col>
				</v-row>
				<v-row>
					<v-col class="pt-0">
						<v-data-table v-if="projectUsers" :headers="headers" :items="projectUsers" hide-default-footer
							expand-icon :items-per-page="-1">
							<template v-slot:item.actions="{ item, index: subindex }">
								<v-icon v-if="item.access_type == 'Project Specific'" color="error500" @click="removeUser(item, subindex)">
									mdi-account-remove
								</v-icon>
							</template>
						</v-data-table>
					</v-col>
				</v-row>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn @click="closeDialog" color="error500" dark>Close</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	props: {
		value: Boolean,
		project: Object
	},
	data() {
		return {
			errorVisible: false,
			errorMsg: "",
			newUser: null,
			selectedRole: null,
			platformRoles: [],
			tenantUsers: [],
			projectUsers: null,
			headers: [
				{ text: 'User Name', sortable: true, value: 'full_name', width: '200px' },
				{ text: 'Role', value: 'role_name', sortable: true, width: '200px' },
				{ text: 'Access Type', align: 'center', value: 'access_type', sortable: true, width: '120px' },
				{ text: 'Actions', align: 'center', value: 'actions', sortable: false, width: '90px' },
			],
		};
	},
	methods: {
		closeDialog(event) {
			this.selectedRole = null;
			this.newUser = null;
			this.projectUsers = null;
			this.$emit('hide', false);
		},
		getRoles() {
			this.axios.get('/user/roles_available?role_type=project_related')
				.then((res) => {
					this.platformRoles = res.data.roles;
				})
				.catch((err) => {
					this.errorVisible = true;
					this.errorMsg = "Error retrieving available roles. Please try again later."
				})
		},
		getTenantUsers() {
			this.axios.get('/user/tenant_users')
				.then((res) => {
					this.tenantUsers = res.data.users;
				})
				.catch((err) => {
					this.errorVisible = true;
					this.errorMsg = "Error retrieving the organization users. Please try again later."
				})
		},
		getProjectUsers() {
			this.axios.get(`/projects/${this.project.id}/users`)
				.then((res) => {
					this.projectUsers = res.data.users;
				})
				.catch((err) => {
					if (err.response.status === 403) {
						this.errorMsg = "You don't have permission to view the project users and manage permissions. Please contact the project owner";
					} else {
						this.errorMsg = "Error retrieving the users with access to the project. Please try again later."
					}
					this.errorVisible = true;
				})
		},
		addUser() {
			this.axios.post(`/projects/${this.project.id}/share`, { user_id: this.newUser, role_id: this.selectedRole })
				.then((res) => {
					this.newUser = null;
					this.selectedRole = null;
					this.getProjectUsers();
				})
				.catch((err) => {
					if (err.response.status === 403) {
						this.errorMsg = "You don't have enough permissions grant the access. Please contact the project owner";
					} else {
						this.errorMsg = err.response;
					}
					this.errorVisible = true;
					this.$posthog.capture('share_project_failed', {
						project_id: this.project.id,
						error: err.response
					})
				});
		},
		removeUser(u, subindex) {
			console.log(u);
			console.log(subindex);
			this.axios.post(`/projects/${this.project.id}/sharestop`, { user_id: u.user_id, role_id: u.role_id })
				.then((res) => {
					this.projectUsers.splice(subindex, 1);
				})
				.catch((err) => {
					if (err.response.status === 403) {
						this.errorMsg = "You don't have permission to revoke the access. Please contact the project owner";
					} else {
						this.errorMsg = "Error while deleting user";
					}
					this.errorVisible = true;
					this.$posthog.capture('unshare_project_failed', {
						project_id: this.project.id,
						error: err.response
					})
				});
		}
	},
	watch: {
		project(selected) {
			if (selected) {
				this.getRoles();
				this.getTenantUsers();
				this.getProjectUsers();
			}
		}
	}
}
</script>