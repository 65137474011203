<template>
  <v-container fluid class="main-container">
    <v-row class="fill-height d-flex justify-center align-center">
      <v-col cols="12" md="3">
        <v-card class="card-login" elevation="8">
          <v-row class="d-flex justify-center">
            <!-- <router-link to="/">
              <img src="~@/assets/images/AIgiled_Logo_login.png" alt="Logo" class="logo-login" />
            </router-link> -->
          </v-row>
          <v-row v-if="!needsPasswordChange" class="d-flex justify-center mt-12">
            <h2 class="header-text-login">Welcome back!</h2>
          </v-row>
          <v-row v-else class="d-flex justify-center mt-12">
            <h2 class="header-text-login">Change your Password</h2>
          </v-row>
          <v-row class="d-flex justify-center mt-8">
            <!-- Add a v-if to add here the change password logic -->
            <v-form v-if="needsPasswordChange" ref="formNewPasword" v-model="isValidNewPassword" validation
              style="width: 384px;" class="px-8">
              <v-text-field :class="{ 'textbox': true }" name="email" label="Email" type="text" v-model="email" disabled
                :rules="emailRules" required></v-text-field>
              <v-text-field :class="{ 'textbox': true }" name="organization" label="Organization" type="text"
                v-model="organization" disabled required></v-text-field>

              <v-text-field :class="{ 'textbox': true }" name="newPassword" label="New Password" :type="showPassword ? 'text' : 'password'"
                v-model="newPassword" clearable :rules="newPasswordRules" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" @click:append="showPassword = !showPassword" required></v-text-field>

              <v-text-field :class="{ 'textbox': true }" id="verifyPassword" name="Confirm New Password"
                label="Verify Password" :type="showPassword ? 'text' : 'password'" v-model="confirmNewPassword" clearable
                :rules="repeatedPasswordRules" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" @click:append="showPassword = !showPassword" required @keyup.enter="changePwdClicked"></v-text-field>
            </v-form>
            <v-form v-else ref="form" v-model="isValid" validation style="width: 384px;" class="px-8">
              <v-text-field :class="{ 'textbox': true }" name="email" label="Email" type="text" v-model="email"
                clearable :rules="emailRules" required></v-text-field>
              <v-text-field :class="{ 'textbox': true }" name="organization" label="Organization" type="text"
                v-model="organization" required></v-text-field>
              <v-text-field :class="{ 'textbox': true }" id="password" name="password" label="Password" :type="showPassword ? 'text' : 'password'"
                v-model="password" clearable :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" @click:append="showPassword = !showPassword" :rules="passwordRules" required @keyup.enter="loginClicked"></v-text-field>
              <v-switch v-model="rememberMe" label="Remember Me"></v-switch>
            </v-form>
          </v-row>
          <v-row class="d-flex justify-center">
            <v-btn v-if="!needsPasswordChange" color="primary500" dark class="mb-6" @click="loginClicked"
              :disabled="!isValid">Login</v-btn>
            <v-btn v-else color="primary500" dark class="mb-6" @click="changePwdClicked" :disabled="!isValidNewPassword">Change
              Password</v-btn>
          </v-row>
          <v-layout align-center justify-space-between>
            <v-divider class="mx-2" style="width: 45%;"></v-divider>
            <span>or</span>
            <v-divider class="mx-2" style="width: 45%;"></v-divider>
          </v-layout>
          <v-row class="d-flex justify-center mt-3">
            <v-btn color="white" class="google-login-btn" disabled>
              <v-img src="~@/assets/images/Logo_Google.png" class="google-logo mr-1" contain></v-img>
              <span class="google-text">Google</span>
            </v-btn>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-snackbar v-model="invalidCredentials" color="error500" multiLine>
      <v-icon color="error100">mdi-lock</v-icon>
       Invalid email or password
      <v-btn icon @click="invalidCredentials = false">
        <v-icon color="error100" >mdi-close</v-icon>
      </v-btn>
    </v-snackbar>

    <v-snackbar v-model="existingPassword" color="error500" multiLine>
      <v-icon color="error100">mdi-lock</v-icon>
      The new password can't be the same as your previous one
      <v-btn icon @click="existingPassword = false">
        <v-icon color="error100">mdi-close</v-icon>
      </v-btn>
    </v-snackbar>

  </v-container>
</template>

<style scoped>
.main-container {
  height: 100vh;
}

.card-login {
  margin: auto;
  width: fit-content;
}

.textbox input {
  height: 29px;
  padding-left: 12px;
  padding-right: 12px;
  font-size: 14px;
  background: #F3F4F6FF;
  border-radius: 6px;
  border-width: 0px;
  outline: none;
}

.textbox .label {
  font-size: 14px;
  line-height: 22px;
}

.logo-login {
  margin-top: 10%;
}

.header-text-login {
  font-size: 32px;
  line-height: 48px;
  color: var(--v-neutral900-base);
}

.google-login-btn {
  display: flex;
  margin-bottom: 5%;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  border-radius: 4px;
}

.google-logo {
  height: 25px;
  width: 25px;
}

.google-text {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  margin-left: 10px;
}
</style>


<script>

const axios = require("axios").default;

export default {
  data() {
    return {
      email: "",
      password: "",
      organization: "",
      rememberMe: false,
      isValid: false,
      isValidNewPassword: false,
      needsPasswordChange: false,
      newPassword: '',
      confirmNewPassword: '',
      emailRules: [(v) => !!v || "Email is required"],
      passwordRules: [(v) => !!v || "Password is required"],
      newPasswordRules: [(v) => v.length >= 8 || "Password must have at lest 8 characters"],
      repeatedPasswordRules: [
        (v) => !!v || "Repeated password is required",
        (v) => v == this.newPassword || "Passwords are not the same",
      ],
      invalidCredentials: false,
      existingPassword: false,
      showPassword: false,
    };
  },

  mounted() {
    this.$tawkMessenger.$on('load', () => {
      this.$tawkMessenger.hideWidget();
    });
  },

  methods: {

    initialAnalyticsSetup() {
      //Product Analytics Tracking - User and Tenant Identification
      //User Identification
      this.$posthog.identify(
        this.$store.getters.user.data.email,
        {
          email: this.$store.getters.user.data.email,
          name: this.$store.getters.user.data.full_name,
          db_id: this.$store.getters.user.data.id,
          tenant_id: this.$store.getters.user.org_data.tenant_id,
          tenant_name: this.$store.getters.user.org_data.name
        }
      );

      // Tenant Identification
      this.$posthog.group('company', this.$store.getters.user.org_data.tenant_id, {
        name: this.$store.getters.user.org_data.name,
        date_joined: this.$store.getters.user.org_data.created_at,
        area: this.$store.getters.user.org_data.area,
        website: this.$store.getters.user.org_data.website
      });

      this.$posthog.capture('user_logged_in');

    },

    loginClicked() {
      if (this.$refs.form.validate()) {
        this.login(this.email, this.password, this.organization, this.rememberMe);
      }
    },

    changePwdClicked() {
      if (this.$refs.formNewPasword.validate()) {
        this.changePassword(this.newPassword, this.confirmNewPassword, this.password, this.email, this.organization);
      }
    },

    changePassword(newPassword, confirmNewPassword, password, email, organization) {

      if (newPassword !== confirmNewPassword) {
        // Passwords do not match
        return;
      }

      if (newPassword === password) {
        // New password can't be the same as the previous one
        this.existingPassword = true;
        return;
      }

      let data = { email: email, password: password, new_password: newPassword, organization: organization };

      this.axios.post("/auth/change_password", data)
        .then((response) => {
          let data = {
            email: email,
            password: newPassword,
            organization: organization
          };
          this.$store.dispatch("login", data).then((response) => { 
            this.initialAnalyticsSetup();
          }).catch((error) => {
            console.error("Login error:", error);
            this.invalidCredentials = true;
          });
        })
        .catch((error) => {
          console.error("Password change error:", error);
        });
    },

    login(email, password, organization, rememberMe) {
      let data = {
        email: email,
        password: password,
        organization: organization,
        rememberMe: rememberMe,
      };
      this.$store.dispatch("login", data)
        .then((response) => {
          if (response.data.first_login) {
            this.$refs.form.resetValidation();
            this.needsPasswordChange = true;
          } else {
            this.initialAnalyticsSetup();
          }
        })
        .catch((error) => {
          console.error("Login error:", error);
          this.invalidCredentials = true;
        });
    },
  },
};
</script>
