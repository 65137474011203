<template>
  <v-dialog v-model="showDialog" transition="dialog-bottom-transition" persistent max-width="80%">
    <v-card>
      <v-fab-transition>
        <v-btn @click="getAutomaticSuggestions" class="flaoting-button" fab fixed right color="success500" dark
          v-if="isFormPartiallyComplete">
          <v-icon>mdi-auto-fix</v-icon>
        </v-btn>
      </v-fab-transition>

      <v-card-title>
        <v-icon color="primary500">mdi-information-outline</v-icon>
        <span class="text-h5 ml-1">Add New Task</span>
      </v-card-title>
      <v-card-text>

        <v-form ref="form" v-model="isMinimumData">

          <v-row>
            <v-col cols="6">
              <h4>Task Title</h4>
              <v-text-field ref="taskName" v-model="task.task_name" label="Task Title" dense solo flat outlined
                :rules="[rules.requiredAutocomplete]"></v-text-field>
            </v-col>
            <v-col cols="6">
              <h4>Epic</h4>
              <v-select ref="epicName" v-model="task.epic_name" :items="epicList" label="Epic" dense solo flat outlined
                :rules="[rules.requiredAutocomplete]" :menu-props="{ offsetY: true }"></v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <h4>Description</h4>
              <v-textarea ref="taskDescription" v-model="task.task_description" filled label="Description" auto-grow
                solo flat outlined :rules="[rules.requiredAutocomplete]" dense rows="1" no-resize></v-textarea>
            </v-col>
          </v-row>

          <v-divider class="mb-4"></v-divider>
          <!-- The section below can be completed with AI suggestion  -->
          <v-progress-linear v-if="suggestionOngoing" class="mb-4" color="primary400" height="10" striped
            v-model="this.suggestionProgress"></v-progress-linear>

          <v-row>
            <v-col cols="3">
              <h4>Status</h4>

              <v-select ref="status" v-model="task.status" :items="statusList" item-text="name" item-value="name"
                label="Status" dense solo flat outlined hide-details :menu-props="{ offsetY: true }"
                :rules="[rules.requiredToSave]">

                <template v-slot:item="{ item }">
                  <div :style="{ color: item.color }">
                    {{ capitalizeFirstLetter(item.name) }}
                  </div>
                </template>

                <template v-slot:selection="{ item, index }">
                  <v-chip :input-value="index === 0" :style="{ color: item.color, 'border-color': item.color }">
                    {{ capitalizeFirstLetter(item.name) }}
                  </v-chip>
                </template>
              </v-select>

            </v-col>
            <v-col cols="3">
              <h4>Start Date</h4>
              <v-menu v-model="menuTime" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                offset-y min-width="auto">

                <template v-slot:activator="{ on, attrs }">
                  <v-text-field :disabled="suggestionOngoing" ref="startDate" :value="formatDate(task.start_date)"
                    label="Start Date" append-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" dense solo flat
                    outlined hide-details :rules="[rules.requiredToSave]"></v-text-field>
                </template>
                <v-date-picker v-model="task.start_date" @input="menuTime = false"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="3">
              <h4>T-Shirt Size</h4>
              <v-select v-model="task.tshirt" :items="tshirtList" item-text="T-Sirt Size" item-value="order"
                label="T-Shirt Size" dense solo flat outlined :menu-props="{ offsetY: true }"
                @input="changesMade = true">
              </v-select>
            </v-col>
            <v-col cols="3">
              <h4>Time (hs)</h4>
              <v-text-field :disabled="suggestionOngoing" ref="time" v-model="task.time" label="Estimated Time"
                type="number" dense solo flat outlined hide-details
                :rules="[rules.requiredToSave && rules.timeCostValues]"></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="3">
              <h4>Priority</h4>
              <v-select v-model="selectedPriority" :items="priorityList" item-text="name" item-value="order"
                hide-no-data label="Priority" dense solo flat outlined :menu-props="{ offsetY: true }"
                @change="handlePriorityChange" hide-details>

                <template v-slot:item="{ item }">
                  <div :style="{ color: item.color }">
                    {{ capitalizeFirstLetter(item.name) }}
                  </div>
                </template>

                <template v-slot:selection="{ item, index }">
                  <div :input-value="index === 0" :style="{ color: item.color, 'border-color': item.color }">
                    {{ capitalizeFirstLetter(item.name) }}
                  </div>
                </template>
              </v-select>
            </v-col>
            <v-col cols="3">
              <h4>Task Role</h4>
              <v-select :disabled="suggestionOngoing" v-model="task.role" :items="roleList" label="Task Role" dense solo
                flat outlined multiple hide-no-data :menu-props="{ offsetY: true, maxHeight: '300' }"
                hide-details @change="createAssignedList"></v-select>
            </v-col>
            <v-col cols="3">
              <h4>Assigned To</h4>
              <v-select v-model="task.assigned_to" :items="assignedToList" item-text="display" item-value="user_id"
                :disabled="suggestionOngoing" label="Assigned To" dense solo flat hide-details outlined multiple
                :menu-props="{ offsetY: true, maxHeight: '300' }"></v-select>
            </v-col>
            <v-col cols="3">
              <h4>Cost</h4>
              <v-text-field :disabled="suggestionOngoing" v-model="task.cost" label="Estimated Cost" type="number"
                min="0" dense solo flat outlined :readonly="autoCalculateCost" hide-details>

                <template v-slot:append-outer>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon :color="autoCalculateCost ? 'primary500' : 'neutral300'" v-bind="attrs" v-on="on"
                        @click="toggleAutoCalculate">
                        {{ autoCalculateCost ? 'mdi-calculator-variant' :
                          'mdi-calculator-variant-outline' }}
                      </v-icon>
                    </template>
                    <span>Calculate automatic cost based on estimated time and
                      role</span>
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>

          </v-row>
          <v-row>
            <v-col cols="4">
              <h4>Tools</h4>
              <v-combobox :disabled="suggestionOngoing" v-model="task.tools" :items="toolsList" label="Tools" multiple
                outlined dense small-chips solo flat deletable-chips hide-no-data hide-selected
                :menu-props="{ offsetY: true, maxHeight: '300' }" hide-details></v-combobox>
            </v-col>
            <v-col cols="8">
              <h4>Dependencies</h4>
              <v-data-table :headers="headers" :items="task.dependencies" hide-default-footer hide-default-header dense
                :items-per-page="-1">

                <template v-slot:item.priority="{ item }">
                  <v-chip small dark :color="findPriorityObject(item.priority).color">
                    {{ findPriorityObject(item.priority).name }}
                  </v-chip>
                </template>

                <template v-slot:item.status="{ item }">
                  <v-chip :color="getStatusColor(item.status)" small dark>
                    {{ item.status }}
                  </v-chip>
                </template>

                <template v-slot:item.start_date="{ item }">
                  {{ formatDate(item.start_date) }}
                </template>

                <template v-slot:item.action="{ index: subindex }">
                  <v-icon @click="removeDependency(subindex)" color="error500">mdi-close</v-icon>
                </template>
              </v-data-table>
              <v-menu transition="slide-y-transition" maxHeight="300" :close-on-content-click="false">

                <template v-slot:activator="{ on, attrs }">
                  <div class="d-flex justify-end">
                    <v-btn :disabled="suggestionOngoing" color="primary500" dark v-bind="attrs" v-on="on" class="mt-2">
                      List of Tasks
                      <v-icon right>mdi-menu-down</v-icon>
                    </v-btn>
                  </div>
                </template>

                <v-list :menu-props="{ offsetY: true, maxHeight: '300' }">
                  <v-list-item v-for="(item, index) in availableDependencies" :key="index" @click="addDependency(item)">
                    <v-list-item-content>
                      <v-list-item-title>
                        <strong>{{ item.epic_name }}</strong> - {{ item.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ formatDate(item.start_date) }} | {{ item.status }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </v-form>

      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error500" text @click="closeAndClear">
          Close
        </v-btn>
        <v-btn color="success500" text :disabled="!isMinimumData" @click="saveTask">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-snackbar v-model="isNotProjectRelated" color="info500" multiLine>
      <v-icon color="info100">mdi-alert</v-icon>
      It seems that your task is not related to the project. Aborting the suggestions
      <v-btn icon @click="isNotProjectRelated = false">
        <v-icon color="info100">mdi-close</v-icon>
      </v-btn>
    </v-snackbar>

    <v-snackbar v-model="engineDown" color="warning500" multiLine>
      <v-icon color="warning100">mdi-alert</v-icon>
      Tasks suggestions failed. Andenia engine is currently down, please try again later
      <v-btn icon @click="engineDown = false">
        <v-icon color="warning100">mdi-close</v-icon>
      </v-btn>
    </v-snackbar>

  </v-dialog>
</template>

<style scoped>
.flaoting-button {
  margin-right: 11%;
  margin-top: 6px;
}

::v-deep .v-select.v-input--dense .v-chip {
  margin: 3px;
}
</style>

<script>
const axios = require("axios").default;

export default {
  props: {
    column: null,
    project: null,
    showDialog: Boolean,
  },

  data() {
    return {
      rules: {
        requiredAutocomplete: value => !!value || 'This field is required for autocomplete',
        requiredToSave: value => Array.isArray(value) ? value.length > 0 || 'This field is required' : !!value || 'This field is required',
        timeCostValues: (v) => v > 0 || "This field must be positive",
      },
      task: {
        epic_id: null,
        task_id: null,
        epic_name: null,
        task_name: null,
        task_description: null,
        task_guide: null,
        tools: [],
        role: [],
        time: null,
        tshirt: null,
        cost: null,
        status: null,
        start_date: null,
        end_date: null,
        dependencies: [],
        priority: null,
        assigned_to: null,
      },
      autoCalculateCost: false,
      selectedPriority: null,
      epicList: [],
      statusList: [],
      priorityList: [],
      tshirtList: ['XS', 'S', 'M', 'L', 'XL'],
      roleList: [],
      toolsList: [],
      value: '',
      taskDependenciesList: [],
      assignedToList: [],
      headers: [
        { text: 'Task Name', value: 'name', sortable: false },
        { text: 'Priority', value: 'priority', align: 'end', sortable: false },
        { text: 'Status', value: 'status', align: 'start', sortable: false },
        { text: 'Start Date', value: 'start_date', align: 'center', width: '200px', sortable: false },
        { text: 'Action', value: 'action', align: 'center', sortable: false },
      ],
      menuTime: false,
      isMinimumData: false,
      isNotProjectRelated: false,
      engineDown: false,
      suggestionOngoing: false,
      suggestionProgress: 0,
    };
  },
  watch: {
    // Verify changes in estimated time
    'task.time': function (newEstimatedTime, oldEstimatedTime) {
      if (this.autoCalculateCost) {
        this.calculateCost();
      }
    },
    // Verify changes in role
    'task.role': function (newRole, oldRole) {
      if (this.autoCalculateCost) {
        this.calculateCost();
      }
    },
    'task.priority'(newVal) {
      this.selectedPriority = newVal;
    },
    showDialog(newVal) {
      this.prepareTaskOptions();
    },
  },
  computed: {
    availableDependencies() {
      if (!this.task || !this.task.dependencies) {
        return [];
      }
      return this.taskDependenciesList.filter(dep =>
        !this.task.dependencies.some(selectedDep => selectedDep.id === dep.id)
      );
    },
    isFormPartiallyComplete() {
      return this.task.task_name && this.task.epic_name && this.task.task_description;
    },
  },
  methods: {
    async saveTask() {
      // Lista de todos los refs a validar
      const refsToValidate = ['taskName', 'epicName', 'taskDescription', 'status', 'startDate', 'time'];
      let allValid = true;

      for (const refName of refsToValidate) {
        if (this.$refs[refName]) {
          const valid = await this.$refs[refName].validate();
          if (!valid) allValid = false;
        }
      }

      // Add an extra validation in case the rule didn't work properly
      if (this.task.time <= 0 | this.task.cost < 0) {
        console.log("The time and cost can't be negative")
        allValid = false;
      }

      if (allValid) {
        this.createTask();
      }
      else {
        this.$refs.form.validate();
      }
    },
    createTask() {
      if (this.task.cost == null) {
        this.task.cost = 0
      }
      this.task.dependencies = this.task.dependencies.map(({ id }) => (id));
      let payload = { tasks: [this.task] };
      this.$emit('createNewTaskBoard', payload);
      this.closeAndClear();

    },
    closeAndClear() {
      this.task = {
        epic_id: null,
        task_id: null,
        epic_name: null,
        task_name: null,
        task_description: null,
        task_guide: null,
        tools: [],
        role: [],
        time: null,
        tshirt: null,
        cost: null,
        status: null,
        start_date: null,
        end_date: null,
        dependencies: [],
        priority: null,
        assigned_to: null,
      };
      if (this.$refs.form) {
        this.$refs.form.reset();
        this.$refs.form.resetValidation();
      }
      this.$emit('update:showDialog', false);
    },
    prepareTaskOptions() { //TODO: Change this function to backend
      if (this.project && this.project.tasks) {
        this.project.tasks.forEach(task => {
          if (!this.epicList.includes(task.epic_name)) {
            this.epicList.push(task.epic_name);
          }
          if (!this.taskDependenciesList.includes(task.task_id)) {
            this.taskDependenciesList.push({ id: task.task_id, epic_name: task.epic_name, name: task.task_name, priority: task.priority, start_date: task.start_date, status: task.status })
          }
          if (task.tools && Array.isArray(task.tools)) {
            task.tools.forEach(tool => {
              if (!this.toolsList.includes(tool)) {
                this.toolsList.push(tool)
              }
            });
          }
        });
      }
      if (this.project && this.project.roles) {
        this.project.roles.forEach(roles_type => {
          let roleExists = this.roleList.find(role => role.name === roles_type.name);
          if (!roleExists) {
            this.roleList.push(roles_type.name);
          }
        });
      }
      if (this.project && this.project.task_status_options) {
        this.project.task_status_options.forEach(task_status => {
          let statusExists = this.statusList.find(status => status.name === task_status.name);
          if (!statusExists) {
            this.statusList.push({ name: task_status.name, color: task_status.color });
          }
        });
      }
      if (this.project && this.project.task_priority_options) {
        this.project.task_priority_options.forEach(task_priority => {
          let priorityExists = this.priorityList.find(priority => priority.name === task_priority.name);
          if (!priorityExists) {
            this.priorityList.push({ name: task_priority.name, color: task_priority.color, order: task_priority.order });
          }
        });
      }
      this.task.status = this.column.name
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    formatDate(dateValue) {
      if (!dateValue) return null;
      const d = new Date(dateValue);
      const options = { year: 'numeric', month: 'short', day: 'numeric' };
      return d.toLocaleDateString('en-US', options);
    },
    formatStartDateForPicker(start_date) {
      const date = new Date(start_date);
      if (isNaN(date.getTime())) {
        return null;
      }

      const year = date.getFullYear();
      const month = (`0${date.getMonth() + 1}`).slice(-2);
      const day = (`0${date.getDate()}`).slice(-2);
      return `${year}-${month}-${day}`;
    },
    toggleAutoCalculate() {
      this.autoCalculateCost = !this.autoCalculateCost;
      if (this.autoCalculateCost) {
        this.calculateCost();
      }
    },
    calculateCost() {
      if (!this.autoCalculateCost || !this.task.time || this.task.role.length === 0) {
        this.task.cost = 0;
        return this.task.cost;
      }

      // Find the highest cost among the selected roles
      const maxRoleCost = this.task.role
        .map(roleName => {
          const role = this.project.roles.find(r => r.name === roleName);
          return role ? role.cost : 0;
        })
        .reduce((max, current) => Math.max(max, current), 0);

      // Calculate the total cost by multiplying the estimated time by the highest cost per hour
      this.task.cost = this.task.time * maxRoleCost;
    },
    getAutomaticSuggestions() {
      this.suggestionOngoing = true;
      this.$posthog.capture('new_task_ai_suggestions_triggered', {
        project_id: this.project.id
      });
      // Do the initial suggestion setup
      let initial_payload = { task_description: this.task.task_description };
      this.axios.post(`/tasks/${this.project.id}/suggestions_setup`, initial_payload)
        .then((res) => {
          let setupCorrect = res.data.is_project_related;
          if (setupCorrect === false) {
            this.isNotProjectRelated = true;
            this.suggestionOngoing = false;
            this.suggestionProgress = 0;
            return
          } else {
            // Get the task dependencies suggestion
            this.axios.post(`/tasks/${this.project.id}/dependencies_completion`, { epic_name: this.task.epic_name, task_name: this.task.task_name, task_description: this.task.task_description })
              .then((res) => {

                // Format the dependencies response to be correctly rendered in the component
                let newDependencies = res.data.dependencies;
                const newDependenciesTasks = newDependencies.map(newTask => this.taskDependenciesList.find(task => task.id === newTask)).filter(Boolean);
                this.task.dependencies = newDependenciesTasks;
                this.suggestionProgress = 20;

                // If the dependencies suggestion succeeded, estimate the t-shirt size and the time
                let tshirt_payload = { epic_name: this.task.epic_name, task_name: this.task.task_name, task_description: this.task.task_description };
                this.axios.post(`/tasks/${this.project.id}/tshirt_estimation`, tshirt_payload)
                  .then((res) => {
                    let tshirt_tasks = res.data.tshirt_tasks;
                    this.task.tshirt = tshirt_tasks;
                    this.suggestionProgress = 30;

                    // Get the tools suggestion
                    let tools_payload = { task_name: this.task.task_name, task_description: this.task.task_description };
                    this.axios.post(`tasks/${this.project.id}/tools_suggestion`, tools_payload)
                      .then((res) => {
                        this.task.tools = res.data.task_tools;
                        this.suggestionProgress = 40;
                      })
                      .catch((err) => {
                        console.log(err);
                        this.engineDown = true;
                        this.suggestionOngoing = false;
                        this.suggestionProgress = 0;
                        return;
                      });

                    // If the dependencies suggestion succeeded, estimate the time and the limit dates
                    let time_payload = { epic_name: this.task.epic_name, task_name: this.task.task_name, task_description: this.task.task_description, task_tools: this.task.tools, task_tshirt: tshirt_tasks };
                    this.axios.post(`/tasks/${this.project.id}/time_estimation`, time_payload)
                      .then((res) => {
                        let estimatedTime = res.data.estimated_time;
                        this.task.time = estimatedTime;
                        this.suggestionProgress = 50;

                        // If the time estimation worked, calculate the task start and end dates
                        let dates_payload = { estimated_time: estimatedTime, dependencies: newDependencies };
                        this.axios.post(`/tasks/${this.project.id}/date_limits`, dates_payload)
                          .then((res) => {
                            this.task.start_date = this.formatStartDateForPicker(res.data.start_date);
                            this.suggestionProgress = 60;
                          })
                          .catch((err) => {
                            console.log(err);
                            this.engineDown = true;
                            this.suggestionOngoing = false;
                            this.suggestionProgress = 0;
                            return;
                          });

                        let roles_payload = { epic_name: this.task.epic_name, task_name: this.task.task_name, task_description: this.task.task_description };
                        this.axios.post(`/tasks/${this.project.id}/roles_suggestion`, roles_payload)
                          .then((res) => {
                            this.task.role = res.data.task_roles;
                            this.autoCalculateCost = true;
                            this.suggestionProgress = 90;
                          })
                          .catch((err) => {
                            console.log(err);
                            this.engineDown = true;
                            this.suggestionOngoing = false;
                            this.suggestionProgress = 0;
                            return;
                          });
                      })
                      .catch((err) => {
                        console.log(err);
                        this.engineDown = true;
                        this.suggestionOngoing = false;
                        this.suggestionProgress = 0;
                        return;
                      })
                  })
                  .catch((err) => {
                    console.log(err);
                    this.engineDown = true;
                    this.suggestionOngoing = false;
                    this.suggestionProgress = 0;
                    return;
                  })
              })
              .catch((err) => {
                console.log(err);
                this.engineDown = true;
                this.suggestionOngoing = false;
                this.suggestionProgress = 0;
                return;
              });
          }
        })
        .catch((err) => {
          console.log(err);
          this.engineDown = true;
          this.suggestionOngoing = false;
          this.suggestionProgress = 0;
          return;
        });
    },
    handlePriorityChange(newOrder) {
      this.task.priority = newOrder;
    },
    addDependency(item) {
      this.task.dependencies.push(item);
      this.taskDependenciesList = this.taskDependenciesList.filter(dependency => dependency.id !== item.id);
    },
    removeDependency(index) {
      const itemToRemove = this.task.dependencies[index];
      this.taskDependenciesList.push(itemToRemove);
      this.task.dependencies.splice(index, 1);
    },
    findPriorityObject(order) {
      return this.priorityList.find(p => p.order === order) || {};
    },
    getStatusColor(statusName) {
      const status = this.statusList.find(s => s.name === statusName.charAt(0).toLowerCase() + statusName.slice(1));
      return status ? status.color : '#6f6f6f';
    },
    createAssignedList() {
      const taskRoles = this.task.role;

      const matchedUsers = [];
      const otherUsers = [];
      
      this.project.project_team.forEach(user => {
        if (taskRoles.includes(user.project_role)) {
          matchedUsers.push({
            ...user,
            display: `${user.full_name} - ${user.project_role}`
          });
        } else {
          otherUsers.push({
            ...user,
            display: `${user.full_name} - ${user.project_role}`
          });
        }
      });

      this.assignedToList = [...matchedUsers, ...otherUsers];
    },
  },
  created() {
    this.createAssignedList();
  }
};
</script>
