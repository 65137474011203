<template>
    <v-container>
        <v-row>
            <v-col>
                <v-select v-model="selectedFolder" :items="folders" item-text="folder_name" item-value="lists" label="Folder" @change="loadProjects"></v-select>
            </v-col>
            <v-col>
                <v-select v-model="selectedProject" :items="projects" item-text="name" item-value="id" label="Project"
                    :disabled="projects.length === 0"></v-select>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <v-btn :disabled="!selectedProject" @click="importProject">Import</v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>
export default {
    data() {
        return {
            folders: [], 
            selectedFolder: null,
            projects: [],
            selectedProject: null,
        };
    },
    created() {
        this.getClickUpFolders();
    },
    methods: {
        getClickUpFolders() {
            this.axios.get('/integrations/clickup/folders')
                .then((response) => {
                    this.folders = JSON.parse(response.data.folders);
                    console.log(this.folders);
                })
                .catch((error) => {
                    this.folders = [];
                })
        },
        loadProjects() {
            if (this.selectedFolder) {
                this.projects = this.selectedFolder;
                console.log(this.projects);
            } else {
                this.projects = [];
            }
        },
        importProject() {
            console.log('Importing project:', this.selectedProject);
            this.$emit('project-selected', {service: 'ClickUp', projectData: this.selectedProject});
        },
    },
};
</script>
  