<template>
    <v-app>
        <v-container>
            <v-row justify="center">
                <v-img alt="ComingSoon" src="../assets/images/view_coming_soon.png" transition="scale-transition"
                    max-height="800" />
                <v-btn color="primary500" dark to="/">Back to Home</v-btn>
            </v-row>
        </v-container>
    </v-app>
</template>

<script>
const axios = require('axios').default;

export default {
    data() {
        return {
        }
    },
    methods: {
    },
    created() {
    }
}
</script>