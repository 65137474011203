<template>
    <v-app>
        <v-container>
            <v-row>
                <v-col cols="auto" class="mr-auto">
                    <h1>Dashboard</h1>
                </v-col>
            </v-row>
            <v-row>
                <DashboardTile v-for="project in owned_projects.filter(x => project_active_status.includes(x.status))" :key="project.id" :project="project"
                    :isSetupComplete="true" />
            </v-row>
            <v-divider class="my-3"></v-divider>
            <v-row>
                <DashboardTile v-for="project in owned_projects.filter(x => !project_active_status.includes(x.status))" :key="project.id" :project="project"
                    :isSetupComplete="false" />
            </v-row>
            <v-divider class="my-3"></v-divider>
            <v-row>
                <v-col cols="12">
                    <v-card>
                        <v-card-title>
                            Organization Role
                        </v-card-title>
                        <v-card-text>
                            <v-data-table :headers="headers" :items="organization_users" hide-default-footer
                                :items-per-page="-1">
                                <template v-slot:item.action="{ item, index: subindex }">
                                    <v-icon :disabled="user_profile.org_role_internal != 'organization_admin'" class="mr-2" @click="editItem(item, subindex)">mdi-pencil</v-icon>
                                </template>
                            </v-data-table>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <!-- Dialog to edit the user organizational role -->
        <v-dialog v-model="showEditDialog" max-width="500px">
            <v-card>
                <v-card-title>
                    Edit User Organizational Role
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="12" md="12">
                            <h4>User</h4>
                            <!-- SELECT with users -->
                            <v-text-field v-model="currentEditItem.user" disabled></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                            <h4>Organizational Role</h4>
                            <v-select v-model="currentEditItem.role_id" :items="organization_roles" item-text="name"
                                item-value="role_id" label="Role" dense solo
                                :menu-props="{ offsetY: true, maxHeight: '300' }"></v-select>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary500" text @click="showEditDialog = false">Cancel</v-btn>
                    <v-btn color="primary500" text @click="saveRoleChanges">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-app>
</template>

<script>
import user_profile_mixin from "../mixins/user_profile.js";
const axios = require('axios').default;
import DashboardTile from "@/components/DashboardTile.vue";

export default {
    data() {
        return {
            owned_projects: [],
            organization_users: [],
            organization_roles: [],
            currentEditIndex: -1,
            currentEditItem: {
                user_id: '',
                user: '',
                role_name: '',
                role_id: ''
            },
            showEditDialog: false,
            headers: [
                { text: 'User', value: 'user_name', sortable: false },
                { text: 'Role', value: 'role_external_name', sortable: false },
                { text: 'Edit', value: 'action', align: 'center', width: '120px', sortable: false }
            ],
            user_profile: { org_role_internal: "organization_member"},
            project_active_status: ["board_setup_finished", "initial_tasks_guides_finished", "initial_tasks_cost_finished"]
        }
    },
    mixins: [user_profile_mixin],
    components: {
        DashboardTile,
    },
    methods: {
        getRoles() {
            this.axios.get('/user/roles_available?role_type=org_related')
                .then((res) => {
                    this.organization_roles = res.data.roles;
                })
                .catch((err) => {
                    console.log("Error retrieving available roles. Please try again later.");
                })
        },
        saveRoleChanges() {
            let role_external_name = this.organization_roles.find(role => role.role_id === this.currentEditItem.role_id).name;
            if (this.currentEditIndex >= 0) {
                // Update existing role
                this.organization_users[this.currentEditIndex].role_external_name = role_external_name;
                this.organization_users[this.currentEditIndex].role_id = this.currentEditItem.role_id;
                axios
                    .post(`/user/${this.currentEditItem.user_id}/role_update/${this.currentEditItem.role_id}`)
                    .then((response) => {
                        console.log("User role update successful")
                    })
                    .catch((error) => {
                        console.log("Error saving the user role", error);
                    });
            }
            this.currentEditIndex = -1;
            this.showEditDialog = false;
            this.currentEditItem = {
                user_id: '',
                user: '',
                role_name: '',
                role_id: ''
            };
        },
        fetchOrganizationUsers() {
            this.axios.get('/user/organization_roles')
                .then((response) => {
                    console.log(response.data);
                    this.organization_users = response.data.users;
                })
                .catch((error) => {
                    console.log(`Error while fetching the organization users. ${error}`);
                    this.$router.push(`/projects`);
                })
        },
        editItem(item, index) {
            this.currentEditIndex = index;
            this.currentEditItem.user = item.user_name;
            this.currentEditItem.user_id = item.user_id;
            this.currentEditItem.role = item.role_external_name;
            this.currentEditItem.role_id = item.role_id;
            this.showEditDialog = true;
        }
    },
    created() {
        this.fetchUserProfile(true, true).then((response) => {
            if (this.user_profile.org_role_internal == 'organization_member') {
                this.$router.push(`/projects`);
            }
        }
        );
        this.getRoles();
        this.fetchOrganizationUsers();
    }
}
</script>