import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: {
            customProperties: true
        },
        themes: {
            light: {
                primary100: '#e7f0fb',
                primary200: '#b6d3f3',
                primary300: '#85b5eb',
                primary400: '#5598e3',
                primary500: '#247adb',
                primary600: '#1c5faa',
                primary700: '#14447a',
                primary800: '#0c2949',
                primary900: '#040e18',

                neutral100: '#eef1f3',
                neutral200: '#cdd5dc',
                neutral300: '#abb9c5',
                neutral400: '#8a9dae',
                neutral500: '#688297',
                neutral600: '#516575',
                neutral700: '#3a4854',
                neutral800: '#232b32',
                neutral900: '#0c0e11',

                error100: '#fde4e5',
                error200: '#faafb1',
                error300: '#f7797d',
                error400: '#f4444a',
                error500: '#f10e16',
                error600: '#bb0b11',
                error700: '#86080c',
                error800: '#500507',
                error900: '#1b0202',

                info100: '#eaf8f6',
                info200: '#c0e9e5',
                info300: '#96dbd4',
                info400: '#6cccc3',
                info500: '#41beb2',
                info600: '#33938a',
                info700: '#246963',
                info800: '#163f3b',
                info900: '#071514',

                success100: '#edf6eb',
                success200: '#c9e5c3',
                success300: '#a4d49c',
                success400: '#80c374',
                success500: '#5cb34d',
                success600: '#478b3b',
                success700: '#33632a',
                success800: '#1f3b19',
                success900: '#0a1408',

                warning100: '#fdf5e5',
                warning200: '#f9e0b0',
                warning300: '#f5cc7c',
                warning400: '#f1b747',
                warning500: '#eca313',
                warning600: '#b87f0e',
                warning700: '#835b0a',
                warning800: '#4f3606',
                warning900: '#1a1202',
            },
        },
    },
});
