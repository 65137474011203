<template>
	<v-dialog max-width="600px" v-model="value" @click:outside="closeDialog">
		<v-container fluid class="white">
			<v-row>
				<v-col style="text-align: center;">
					<h1>Project Import</h1>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<v-card>
						<v-card-title>
							Connected Services
						</v-card-title>
						<v-card-text class="horizontal-images">
							<div v-for="(service, serviceData) in services" :key="serviceData">
								<img :src="imgs[serviceData]" alt="Service logo" @click="selected_service = serviceData" />
							</div>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
			<ImportClickUp v-if="selected_service == 'ClickUp'" @project-selected="importProject" />
			<v-row class="fill-height" align-content="center" justify="center" v-if="importing_progress">
				<v-col class="text-subtitle-1 text-center" cols="12">
					Importing the project!
				</v-col>
				<v-col cols="6">
					<v-progress-linear color="primary500" indeterminate rounded height="6"></v-progress-linear>
				</v-col>
			</v-row>
			<v-row v-if="importing_finished" :class="{ 'error-bg': error, 'success-bg': !error }" align="center">
				<v-col>
					<span>{{ error_msg }}</span>
				</v-col>
			</v-row>
		</v-container>
	</v-dialog>
</template>

<style scoped>
.horizontal-images {
	display: flex;
}

.horizontal-images img {
	cursor: pointer;
	height: 50px;
	width: 50px;
}

.error-bg {
	background-color: var(--v-error500-base);
	color: var(--v-neutral100-base);
}

.success-bg {
	background-color: var(--v-success500-base);
	color: var(--v-neutral100-base);
}
</style>

<script>

import ImportClickUp from "@/components/project_import/ImportClickUp.vue";

export default {
	props: {
		value: Boolean,
	},
	data() {
		return {
			error_msg: null,
			error: false,
			import_dialog_opened: true,
			services: {},
			selected_service: null,
			importing_progress: false,
			importing_finished: false,
			import_project_data: null,
			imgs: {
				ClickUp: require('@/assets/images/ClickUp.png'),
				Notion: require('@/assets/images/Notion.png'),
				Airtable: require('@/assets/images/Airtable.png'),
				Trello: require('@/assets/images/Trello.png'),
			}
		};
	},
	methods: {
		closeDialog: function (event) {
			this.$emit('hideImport', false);
		},
		importProject(importData) {
			let service = importData["service"];
			let project_data = importData["projectData"];
			console.log('Importing project from service:', service, " with data: ", project_data);
			this.importing_progress = true;
			this.importing_finished = false;
			this.axios.post('/integrations/project_import', { service: service, project_data: project_data })
				.then((res) => {
					console.log(res.data);
					let project_id = res.data.project_id;
					this.importing_progress = false;
					this.importing_finished = true;
					this.error = false;
					this.error_msg = "Project correctly imported"
					this.$posthog.capture("project_imported_success", {
						integration_service: service,
						imported_project_data: project_data,
						internal_project_id: project_id
					});
				})
				.catch((err) => {
					this.importing_progress = false;
					this.importing_finished = true;
					this.error_msg = err.data;
					this.error = true;
					this.$posthog.capture("project_imported_failed", {
						integration_service: service,
						imported_project_data: project_data						
					});
				});
			this.selected_service = null;
		},
		fetchServices() {
			this.axios.get('/integrations')
				.then((response) => {
					this.services = response.data.services;
				})
				.catch((error) => {
					this.services = {};
				})
		},
	},
	components: {
		ImportClickUp,
	},
	created() {
		this.fetchServices();
	}
}
</script>