<template>
  <v-col cols="12" sm="6" md="3">
    <v-card :class="[{'project-container': !isDescriptionVisible}, 'expanded-project-container']" :to="url" @mouseover="startTimer" @mouseout="clearTimer">
      <div :style="containerStyle" class="project-content">
        <div class="project-title"><b>{{ project.name }}</b></div>
        <div class="project-description" :style="descriptionStyle" ref="description">
          <p v-if="!project.description != null">{{ project.description }}</p>
          <p v-if="project.description == null">Description pending for the project. Please resume the chatbot to continue.</p>
        </div>
        <div v-if="org_role != 'organization_member'" class="project-buttons">
          <v-btn v-if="sharing"  @click.prevent="delete_project" class="delete-button" small icon>
            <v-icon color="error500">mdi-delete</v-icon>
          </v-btn>
          <v-btn v-if="sharing" @click.prevent="export_project" class="share-button" small icon>
            <v-icon color="primary500">mdi-export</v-icon>
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-col>
</template>

<style scoped>
.project-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 268px;
  background: white;
  border-radius: 4px; /* border-m */
  box-shadow: 0px 2px 5px #171a1f, 0px 0px 2px #171a1f; /* shadow-s */
}

.expanded-project-container {
  height: auto !important;
}

.project-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.project-title {
  margin-top: 5%;
  margin-bottom: 8%;
  margin-left: 10%;
  font-size: 18px;
  line-height: 28px;
  color: var(--v-neutral800-base); /* neutral-900 */
}

.project-description {
  margin-left: 5%;
  margin-right: 5%;
  font-size: 12px;
  line-height: 20px;
  color: var(--v-neutral700-base); /* neutral-900 */
  overflow: hidden;
  transition: height 0.5s ease;
}

.project-buttons {
  margin-top: 8%;
  margin-right: 3%;
  margin-bottom: 3%;
  align-self: flex-end;
}

.share-button {
  margin-left: 8px;
}

.delete-button {
  margin-right: 8px;
}
</style>

<script>
export default {
  props: {
    project: Object,
    sharing: Boolean,
    org_role: String
  },
  data() {
    return {
      isDescriptionVisible: false,
      timer: null
    };
  },
  computed: {
    url() {
      let project_id = this.project.id;
      let project_status = this.project.status;
      let status_redirect_mapping = {
        "chatbot_conversation_ongoing": `/chatbot/${project_id}`,
        "chatbot_information_done": `/chatbot/${project_id}`,
        "chatbot_conversation_ended": `/chatbot/${project_id}`,
        "chatbot_finished": `/chatbot/${project_id}`,
        "form_input": `/forminput/${project_id}`,
        "initial_tasks_created": `/taskvalidation/${project_id}`,
        "initial_dependencies_finished": `/taskvalidation/${project_id}`,
        "initial_tasks_times_finished": `/taskvalidation/${project_id}`,
        "board_setup_finished": `/project/${project_id}`,
        "initial_tasks_guides_finished": `/project/${project_id}`,
        "initial_tasks_cost_finished": `/project/${project_id}`,
      }

      let redirect_url = status_redirect_mapping[project_status];
      return redirect_url;
    },
    containerStyle() {
      return {
        height: this.isDescriptionVisible ? 'auto' : '172px'
      };
    },
    descriptionStyle() {
      return {
        height: this.isDescriptionVisible ? 'auto' : '41px'
      };
    }
  },
  methods: {
    share() {
      this.$emit("share", this.project);
    },
    export_project() {
      console.log("emitting export");
      this.$posthog.capture("export_dialog_opened");
      this.$emit("export", this.project);
    },
    delete_project() {
      console.log("emitting deletion");
      this.$emit("delete", this.project);
    },
    startTimer() {
      this.timer = setTimeout(this.showDescription, 1000);
    },
    clearTimer() {
      clearTimeout(this.timer);
      this.hideDescription();
    },
    showDescription() {
      this.isDescriptionVisible = true;
    },
    hideDescription() {
      this.isDescriptionVisible = false;
    }
  }
};
</script>
