<template>
    <v-container>
        <!-- <v-row>
            <v-col>
                <v-btn-toggle mandatory dense>
                    <v-btn @click="demoViewMode('month')">Month</v-btn>
                    <v-btn @click="demoViewMode('week')">Week</v-btn>
                    <v-btn @click="demoViewMode('day')">Day</v-btn>
                </v-btn-toggle>
            </v-col>
        </v-row> -->
        <v-expansion-panels class="panel" focusable ref="expansionPanels">
            <v-expansion-panel v-for="(tasks, epicId) in groupedTasks" :key="epicId">
                <v-expansion-panel-header :class="{ 'active-header': activePanel === epicId }"
                    @click="togglePanel(epicId)">

                    <v-layout row align-center justify-space-between>
                        <v-flex class="ml-4">
                            {{ epicNames[epicId] }}
                        </v-flex>
                        <v-flex xs4>
                            <v-layout row align-center justify-end>
                                <v-btn-toggle mandatory dense class="button_toogle"
                                    :class="{ 'show-button': activePanel === epicId }">
                                    <v-btn @click.stop="demoViewMode('Month')">Month</v-btn>
                                    <v-btn @click.stop="demoViewMode('Week')">Week</v-btn>
                                    <v-btn @click.stop="demoViewMode('Day')">Day</v-btn>
                                </v-btn-toggle>
                            </v-layout>
                        </v-flex>
                    </v-layout>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <frappe-gantt :view-mode="mode" :tasks="tasks" @task-updated="showDetails"
                        @task-date-updated="debugEventLog.push($event)"
                        @task-progress-change="debugEventLog.push($event)" />
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <v-snackbar v-model="deadlineExceeded" color="warning500" multiLine>
            <v-icon color="warning100">mdi-alert</v-icon>
            The estimated end date of the last task exceeds the project deadline
            <v-btn icon @click="snackbar_closed = true">
                <v-icon color="warning100">mdi-close</v-icon>
            </v-btn>
        </v-snackbar>
    </v-container>
</template>

<script>
import FrappeGantt from '@/components/FrappeGantt.vue';

export default {
    props: {
        project: null,
    },
    components: {
        FrappeGantt
    },
    data() {
        return {
            mode: 'month',
            debugEventLog: [],
            snackbar_closed: false,
            activePanel: null
        };
    },
    methods: {
        togglePanel(epicId) {
            this.activePanel = this.activePanel === epicId ? null : epicId;
        },
        formatDate(dateValue) {
            const d = new Date(dateValue);
            const year = d.getFullYear();
            const month = (d.getMonth() + 1).toString().padStart(2, '0');
            const day = d.getDate().toString().padStart(2, '0');
            return `${year}-${month}-${day}`;
        },
        convertTask(task) {
            const dependencies = task.dependencies ? task.dependencies.map(id => id.toString()).join(', ') : "";
            const ganttTask = {
                id: task.task_id.toString(),
                custom_class: 'cc-color',
                name: task.task_name,
                start: this.formatDate(task.start_date),
                end: this.formatDate(task.end_date),
                progress: 0,
                dependencies: dependencies,
                taks_db_id: task.id,
            };
            return ganttTask;
        },
        demoViewMode(viewMode) {
            this.mode = viewMode;
        },
        showDetails: function (event, task) {
            this.$router.push(`/project/${this.project.id}/task/${event.taks_db_id}`);
        },
        getMaxEndDate() {
            let maxEndDate = null;

            this.project.tasks.forEach((task) => {
                const taskEndDate = new Date(task.end_date);
                if (!maxEndDate || taskEndDate > maxEndDate) {
                    maxEndDate = taskEndDate;
                }
            });

            return maxEndDate;
        },
        groupTasksByEpic(tasks) {
            const groupedTasks = {};
            tasks.forEach(task => {
                const epicId = task.epic_id || 'no_epic';
                if (!groupedTasks[epicId]) {
                    groupedTasks[epicId] = [];
                }
                groupedTasks[epicId].push(this.convertTask(task));
            });

            Object.keys(groupedTasks).forEach(epicId => {
                groupedTasks[epicId].sort((a, b) => new Date(a.start) - new Date(b.start));
            });
            return groupedTasks;
        },
        sortTasksByStartDate(tasks) {
            return tasks.sort((a, b) => new Date(a.start_date) - new Date(b.start_date));
        }

    },
    computed: {
        groupedTasks() {
            if (this.project) {
                return this.groupTasksByEpic(this.project.tasks);
            } else {
                return {};
            }
        },
        epicNames() {
            const epicNames = {};
            this.project.tasks.forEach(task => {
                if (task.epic_id) {
                    epicNames[task.epic_id] = task.epic_name;
                }
            });
            return epicNames;
        },
        deadlineExceeded() {
            const maxEndDate = this.getMaxEndDate();
            if (!this.snackbar_closed && maxEndDate && maxEndDate > new Date(this.project.end_date)) {
                return true;
            } else {
                return false;
            }
        }
    }
};
</script>

<style>
.gantt-container {
    height: calc(100vh - 240px);
}

.gantt-container::-webkit-scrollbar {
    height: 8px;
    width: 8px;
    border-radius: 4px;
    background-color: var(--v-primary100-base);
}

.gantt-container::-webkit-scrollbar-thumb {
    background-color: var(--v-primary500-base);
    border-radius: 4px;
}

.gantt-container::-webkit-scrollbar-track {
    background-color: var(--v-primary100-base);
    border-radius: 4px;
}

.gantt-container::-webkit-scrollbar-track:hover {
    background-color: var(--v-primary100-base);
    border-radius: 4px;
}

.cc-color .bar {
    fill: var(--v-primary500-base) !important;
}

.active-header {
    background-color: var(--v-primary500-base);
    color: var(--v-neutral100-base);
    font-weight: bold;
    font-family: Inter;
}

.button_toogle {
    min-width: fit-content;
    float: right;
    margin-right: 10%;
    color: var(--v-primary500-base);
    box-shadow: none;
    border: 2px solid var(--v-primary500-base);
    border-radius: 4px;
    opacity: 0;
    transition: opacity 0.3s ease;
    pointer-events: none;
}

.show-button {
    pointer-events: visible;
    opacity: 1;
}
</style>
