<template>
    <v-app>
        <v-container>
            <v-row>
                <v-col cols="auto" class="mr-auto">
                    <h1>Integrations</h1>
                </v-col>
            </v-row>
            <v-expansion-panels class="panel" focusable>
                <v-expansion-panel @click="getClickUpSpaces">
                    <v-expansion-panel-header>
                        <template v-slot:actions>
                            <v-img class="panel-image" src="@/assets/images/ClickUp.png" height="50" width="50"></v-img>
                        </template>
                        <span class="panel-header">ClickUp</span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <p>Export and import projects from your ClickUp workspace</p>
                        <v-card v-if="!('ClickUp' in services)">
                            <v-card-title>Connection</v-card-title>
                            <v-card-actions>
                                <v-btn
                                    href="https://app.clickup.com/api?client_id=WWRO4OXCC6XBMN2K3T11RTWCHEHQ0L7A&redirect_uri=https://andenia.com/oauthclickup">
                                    Connect </v-btn>
                            </v-card-actions>
                        </v-card>
                        <v-card v-else>
                            <v-card-title>Integration Details</v-card-title>
                            <v-card-text>
                                <v-row>
                                    <v-col>
                                        <v-select
                                            v-model="services.ClickUp.custom_field_1"
                                            :items="clickUpSpaces"
                                            item-text="space_name"
                                            item-value="space_id"
                                            label="Spaces"
                                            >
                                        </v-select>
                                    </v-col>
                                    <v-col>
                                        <v-btn @click="saveClickUpSpaces">Save</v-btn>
                                    </v-col>
                                </v-row>

                                <v-divider></v-divider>

                                <v-row>
                                    <v-col>
                                        <v-subheader>Exported Projects: {{ services.ClickUp.exports }}</v-subheader>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-subheader>Imported Projects: {{ services.ClickUp.imports }}</v-subheader>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <template v-slot:actions>
                            <v-img class="panel-image" src="@/assets/images/Airtable.png" height="50" width="50"></v-img>
                        </template>
                        <span class="panel-header">Airtable</span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <p>Export and import projects from your Airtable workspace</p>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <template v-slot:actions>
                            <v-img class="panel-image" src="@/assets/images/Trello.png" height="50" width="50"></v-img>
                        </template>
                        <span class="panel-header">Trello</span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <p>Export and import projects from your Trello workspace</p>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <template v-slot:actions>
                            <v-img class="panel-image" src="@/assets/images/Notion.png" height="50" width="50"></v-img>
                        </template>
                        <span class="panel-header">Notion</span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <p>Export and import projects from your Notion workspace</p>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-container>
    </v-app>
</template>

<style scoped>
.panel {
    margin-top: 20px;
    font-family: Inter;
}

.panel-image {
    order: 0;
}

.panel-header {
    order: 1;
    margin-left: 5%;
}
</style>

<script>
import user_profile_mixin from "../mixins/user_profile.js";
const axios = require('axios').default;

export default {
    data() {
        return {
            services: [],
            clickUpSpaces: [],
            user_profile: { org_role_internal: "organization_member" },
        }
    },
    mixins: [user_profile_mixin],
    methods: {
        fetchServices() {
            this.axios.get('/integrations')
                .then((response) => {
                    this.services = response.data.services;
                })
                .catch((error) => {
                    this.services = [];
                })
        },
        getClickUpSpaces() {
            this.axios.get('/integrations/clickup/spaces')
                .then((response) => {
                    this.clickUpSpaces = JSON.parse(response.data.spaces);
                })
                .catch((error) => {
                    this.clickUpSpaces = [];
                })
        },
        saveClickUpSpaces() {
            console.log('Selected Spaces:', this.services.ClickUp.custom_field_1);
            this.axios.post('/integrations/clickup/spaces', {"space_id": this.services.ClickUp.custom_field_1})
                .then((response) =>{
                    console.log(response.data);
                })
                .catch((error) => {
                    console.log(`Sorry, the ClickUp space couldn't be saved. ${error}`)
                })
        },
    },
    created() {
        this.fetchUserProfile().then((response) => {
                if(this.user_profile.org_role_internal == 'organization_member'){
                    this.$router.push(`/projects`);
                }
            }
        );
        this.fetchServices();
    }
}
</script>