<template>
    <v-dialog max-width="640px" v-model="value" @click:outside="closeDialog">
        <v-container fluid class="white">
            <v-row class="button-container">
                    <h2>Are you sure you want to delete {{ project.name }}?</h2>
            </v-row>
			<v-row class="button-container">
				<v-btn @click="closeDialog" elevation="1" color="success500" dark class="no-button">
					Cancel
				</v-btn>
				<v-btn @click="deleteProject" elevation="1" color="error500" dark class="yes-button">
					Delete
				</v-btn>
			</v-row>
        </v-container>
    </v-dialog>
</template>

<style>
.button-container {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}

.no-button {
  margin-right: 30px;
}

</style>

<script>
export default {
	props: {
		value: Boolean,
		project: Object
	},
	data() {
		return {
			delete_dialog_opened: true,
		};
	},
	methods: {
		closeDialog: function(event) {
			this.$emit('hideDelete', null);
			this.delete_dialog_opened = false;
		},
		deleteProject: function() {
			this.axios.delete(`/projects/${this.project.id}`)
			.then((res) =>{
				console.log(res);
				this.$posthog.capture("project_deleted_success", {
					project_id: this.project.id
				});
			})
			.catch((err) => {
				console.log(err);
				this.$posthog.capture("project_deleted_failed", {
					project_id: this.project.id
				});
			});
			this.$emit('hideDelete', this.project.id);
			this.delete_dialog_opened = false;
		},
	}
}
</script>