<template>
	<v-dialog max-width="600px" v-model="value" @click:outside="closeDialog" v-if="project">
		<v-container fluid class="white">
			<v-row>
				<v-col>
					<h1>Project Export: <i>{{ project['name'] }}</i></h1>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<v-card>
						<v-card-title>
							Connected Services
						</v-card-title>
						<v-card-text class="horizontal-images">
							<div v-for="(service, serviceData) in services" :key="serviceData">
								<img :src="imgs[serviceData]" alt="Service logo"
									@click="exportProject(serviceData, service)" />
							</div>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
			<v-row class="fill-height" align-content="center" justify="center" v-if="exporting_progress">
				<v-col class="text-subtitle-1 text-center" cols="12">
					Exporting the project!
				</v-col>
				<v-col cols="6">
					<v-progress-linear color="primary500" indeterminate rounded height="6"></v-progress-linear>
				</v-col>
			</v-row>
			<v-row v-if="exporting_finished" :class="{ 'error-bg': error, 'success-bg': !error }" align="center">
				<v-col>
					<span>{{ error_msg }}</span>
				</v-col>
			</v-row>
		</v-container>
	</v-dialog>
</template>

<style scoped>
.horizontal-images {
	display: flex;
}

.horizontal-images img {
	cursor: pointer;
	height: 50px;
	width: 50px;
}

.error-bg {
	background-color: var(--v-error500-base);
	color: var(--v-neutral100-base);
}

.success-bg {
	background-color: var(--v-success500-base);
	color: var(--v-neutral100-base);
}
</style>

<script>
export default {
	props: {
		value: Boolean,
		project: Object
	},
	data() {
		return {
			error_msg: null,
			error: false,
			export_dialog_opened: true,
			services: {},
			exporting_progress: false,
			exporting_finished: false,
			imgs: {
				ClickUp: require('@/assets/images/ClickUp.png'),
				Notion: require('@/assets/images/Notion.png'),
				Airtable: require('@/assets/images/Airtable.png'),
				Trello: require('@/assets/images/Trello.png'),
			}
		};
	},
	methods: {
		closeDialog: function (event) {
			this.$emit('hideExport', false);
		},
		exportProject(service, serviceData) {
			console.log('Exporting project for service:', service);
			this.exporting_progress = true;
			this.exporting_finished = false;
			this.axios.post('/integrations/project_export', { project: this.project.id, service: service, service_data: serviceData })
				.then((res) => {
					this.exporting_progress = false;
					this.exporting_finished = true;
					this.error_msg = res.data;
					this.error = false;
					this.$posthog.capture("project_exported_success", {
						integration_service: service,
						exported_project_data: serviceData,
						internal_project_id: this.project_id
					});
				})
				.catch((err) => {
					this.exporting_progress = false;
					this.exporting_finished = true;
					this.error_msg = err.data;
					this.error = true;
					this.$posthog.capture("project_exported_failed", {
						integration_service: service,
						exported_project_data: serviceData,
						internal_project_id: this.project_id
					});
				});
		},
		fetchServices() {
			this.axios.get('/integrations')
				.then((response) => {
					this.services = response.data.services;
				})
				.catch((error) => {
					this.services = {};
					this.$posthog.capture("export_dialog_empty_services");
				})
		},
	},
	created() {
		this.fetchServices();
	}
}
</script>