<template>
    <v-dialog max-width="640px" v-model="value" @click:outside="closeDialog">
        <v-card>
            <v-card-title>
                <span class="text-h4">New Project Status</span>
            </v-card-title>
            <v-card-text>
                <v-container fluid class="white">
                    <v-row>
                        <v-col cols="12" sm="6" md="4">
                            <span class="text-h6">Status Name</span>
                            <v-text-field required hint="name of the new project status (i.e. in review)" dense outlined
                                v-model="newProjectStatus" style="background: white; " />
                        </v-col>
                        <v-col cols="12">
                            <span class="text-h6">Status Color</span>
                            <v-color-picker v-model="NewStatusColor" :modes="['hex']"></v-color-picker>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="closeDialog" variant="text" color="primary500" dark>Close</v-btn>
                <v-btn @click="addProject" variant="text" color="primary500" dark>Save</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
const axios = require('axios').default;

export default {
    props: {
        value: Boolean,
        project: null
    },
    data() {
        return {
            newProjectStatus: "",
            NewStatusColor: null,
        };
    },
    methods: {
        closeDialog: function (event) {
            this.$emit('hideNewStatus', false);
        },
        addProject() {
            const maxOrder = this.project.task_status_options.reduce((max, status) => {
                return status.order > max ? status.order : max;
            }, -1);
            this.project.task_status_options.push({
                "name": this.newProjectStatus,
                "color": this.NewStatusColor.hex,
                "order": maxOrder + 1
            })
            this.$posthog.capture('project_status_created', {
                project_id: this.project.id,
                project_status: this.newProjectStatus
            });
            this.newProjectStatus = null;
            this.NewStatusColor = null;
            this.$emit('hideNewStatus', false);
            this.$emit('addProjectStatus');
        },
    }
}
</script>