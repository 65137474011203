<template>
    <v-dialog v-model="value" @click:outside="closeDialog" max-width="640px">
        <v-card v-if="currentDialog === ''" class="mx-auto">
            <v-card-title class="justify-center">
                <h1>New Project</h1>
            </v-card-title>
            <v-card-actions>
                <v-row justify="center" class="mb-2 mt-2">
                    <v-col cols="12" sm="6" md="4" lg="5" class="d-flex flex-column justify-center align-center">
                        <v-btn text @click="selectDialog('prd')" class="d-flex align-center"
                            style="height: 100%; padding: 0;" outlined plain>
                            <v-img alt="PRD" src="../assets/images/PRD.png" transition="scale-transition"
                                aspect-ratio="1" width="200" />

                        </v-btn>
                        <h3 class="mt-1">Project Document</h3>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="5" class="d-flex flex-column justify-center align-center">
                        <v-btn text @click="selectDialog('manual')" class="d-flex align-center"
                            style="height: 100%; padding: 0;" outlined>
                            <v-img alt="Manual" src="../assets/images/ChatBot.png" transition="scale-transition"
                                aspect-ratio="1" width="200" />

                        </v-btn>
                        <h3 class="mt-1">Manual</h3>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>

        <v-card v-if="currentDialog === 'manual'" class="mx-auto">
            <v-card-title class="justify-center">
                <h1>Manual</h1>
            </v-card-title>
            <v-card-actions>
                <v-row justify="center" class="mb-2 mt-2">
                    <v-col cols="12" sm="6" md="4" lg="5" class="d-flex flex-column justify-center align-center">
                        <v-btn text @click="selectDialog('chatBot')" class="d-flex align-center"
                            style="height: 100%; padding: 0;" outlined plain>
                            <v-img alt="Chat Bot" src="../assets/images/ChatBot.png" transition="scale-transition"
                                aspect-ratio="1" width="200" />
                        </v-btn>
                        <h3 class="mt-1">Chatbot</h3>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="5" class="d-flex flex-column justify-center align-center">
                        <v-btn text @click="selectDialog('formInput')" class="d-flex align-center"
                            style="height: 100%; padding: 0;" outlined>
                            <v-img alt="Form Input" src="../assets/images/PRD.png" transition="scale-transition"
                                aspect-ratio="1" width="200" />
                        </v-btn>
                        <h3 class="mt-1">Form</h3>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>

        <v-card v-if="currentDialog === 'chatBot'">
            <v-card-title class="justify-center">
                <h1>New Project</h1>
            </v-card-title>
            <v-card-text>
                <v-form @submit.prevent class="d-flex">
                    <v-text-field @keyup.enter="addProjectChat" :rules="rules" placeholder="Project Name"
                        hide-details="auto" dense outlined type="text" v-model="new_project_name"
                        style="background: white; " />
                </v-form>
            </v-card-text>
            <v-card-actions class="d-flex justify-center">
                <v-btn @click="closeDialog" color="error500" dark class="mb-4 mr-5">Close</v-btn>
                <v-btn @click="addProjectChat" color="success500" dark class="mb-4 ml-5">Create</v-btn>
            </v-card-actions>
        </v-card>

        <v-card v-if="currentDialog === 'formInput'">
            <v-card-title class="justify-center">
                <h1>New Project</h1>
            </v-card-title>
            <v-card-text>
                <v-form @submit.prevent class="d-flex">
                    <v-text-field @keyup.enter="addProjectForm" :rules="rules" placeholder="Project Name"
                        hide-details="auto" dense outlined type="text" v-model="new_project_name"
                        style="background: white; " />
                </v-form>
            </v-card-text>
            <v-card-actions class="d-flex justify-center">
                <v-btn @click="closeDialog" color="error500" dark class="mb-4 mr-5">Close</v-btn>
                <v-btn @click="addProjectForm" color="success500" dark class="mb-4 ml-5">Create</v-btn>
            </v-card-actions>
        </v-card>

        <v-card v-if="currentDialog === 'prd'" disabled>
            <!-- TODO: add coming soon image/text to the v-card -->
            <v-card-title class="justify-center">
                <h1>New Project</h1>
            </v-card-title>
            <v-card-text>
                <v-file-input v-model="files" color="primary500" counter label="File input" multiple
                    placeholder="Select your files" prepend-icon="mdi-paperclip" outlined :show-size="1000">
                    <template v-slot:selection="{ index, text }">
                        <v-chip v-if="index < 2" color="primary500" dark label small>
                            {{ text }}
                        </v-chip>

                        <span v-else-if="index === 2" class="text-overline grey--text text--darken-3 mx-2">
                            +{{ files.length - 2 }} File(s)
                        </span>
                    </template>
                </v-file-input>
            </v-card-text>

            <v-card-actions class="d-flex justify-center">
                <v-btn @click="closeDialog" color="error500" dark class="mb-4 mr-5">Close</v-btn>
                <v-btn @click="addProjectPRD" color="success500" dark class="mb-4 ml-5">Upload</v-btn>
            </v-card-actions>

        </v-card>
    </v-dialog>
</template>

<style>
.square-btn {
    width: 200px;
    height: 200px;
}
</style>

<script>
const axios = require('axios').default;

export default {
    props: {
        value: Boolean
    },
    data() {
        return {
            newProjectChatBot: false,
            newProjectPRD: false,
            new_project_name: "",
            currentDialog: '',
            rules: [
                value => !!value || 'Required.',
            ],
            files: [],
        };
    },
    methods: {
        selectDialog(dialog) {
            this.currentDialog = dialog;
            if(dialog === 'prd') {
                this.$posthog.capture('new_project_prd_selection');
            }
        },
        closeDialog: function (event) {
            this.$emit('hide', false);
            this.currentDialog = '';
        },
        addProjectChat() {
            if (this.new_project_name == '') {
                return
            }
            let data = {
                name: this.new_project_name
            };
            axios.post('/projects', data)
                .then((response) => {
                    console.log('Added: ' + this.new_project_name + response.data.id);
                    this.$router.push(`/chatbot/${response.data.id}`);
                })
                .catch((error) => {
                    console.log('Error occurred while adding' + this.new_project_name);
                });
        },
        addProjectForm() {
            if (this.new_project_name == '') {
                return
            }
            let data = {
                name: this.new_project_name,
                status: 'form_input'
            };
            axios.post('/projects', data)
                .then((response) => {
                    console.log('Added: ' + this.new_project_name + response.data.id);
                    this.$router.push(`/forminput/${response.data.id}`);
                })
                .catch((error) => {
                    console.log('Error occurred while adding' + this.new_project_name);
                });
        },
        addProjectPRD() {
            console.log("New Project PRD: ", this.files) //TODO: Add PRD feature
        }
    }
}
</script>