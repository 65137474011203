<template>
    <v-app>
        <v-container>
            <v-img alt="ComingSoon" src="../assets/images/view_coming_soon.png" transition="scale-transition"
                max-height="800" />
        </v-container>
    </v-app>
</template>

<script>
import user_profile_mixin from "../mixins/user_profile.js";
const axios = require('axios').default;

export default {
    data() {
        return {
            user_profile: { org_role_internal: "organization_member" },
        }
    },
    mixins: [user_profile_mixin],
    methods: {

    },
    created() {
        this.fetchUserProfile();
    }
}
</script>