<template>
    <v-app>
        <v-container fluid>
            <v-breadcrumbs :items="breadcrumbLinks" large class="breadcrumbs-item"></v-breadcrumbs>
            <v-row>
                <v-col class="ml-4">
                    <h1>{{ project.name }}</h1>
                </v-col>
            </v-row>
            <v-divider class="my-3 ml-4"></v-divider>
            <v-row>
                <v-col cols="12">
                    <v-card>
                        <v-card-text>
                            <v-row class="mt-1 d-flex">
                                <v-col class="mx-10 text-start">
                                    <h2>Project information</h2>
                                </v-col>
                                <v-col class="text-end">
                                    <v-btn class="mr-5 save-project-overview" color="primary500" dark
                                        @click="saveProjectStructure()">
                                        <v-icon>mdi-content-save</v-icon>
                                        Save
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <h4>Description</h4>
                                    <v-textarea filled label="Description" auto-grow solo flat outlined dense rows="1"
                                        no-resize hide-details v-model="project.description"></v-textarea>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="4">
                                    <h4>Start Date</h4>
                                    <v-text-field :value="formatDate(project.start_date)" label="Start Date"
                                        append-icon="mdi-calendar" readonly dense solo flat outlined
                                        disabled></v-text-field>

                                </v-col>
                                <v-col cols="4">
                                    <h4>End Date</h4>
                                    <v-menu v-model="menuEndDate" :close-on-content-click="false" :nudge-right="40"
                                        transition="scale-transition" offset-y min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field :value="formatDate(project.end_date)" label="End Date"
                                                append-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" dense solo
                                                flat outlined></v-text-field>
                                        </template>
                                        <v-date-picker v-model="project.end_date"
                                            @input="menuEndDate = false"></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="4">
                                    <h4>Project Area</h4>
                                    <v-text-field v-model="project.area" label="Project Area" dense solo flat
                                        outlined></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="3">
                                    <v-row>
                                        <h4 class="mx-3">T-Shirt Size Conversion</h4>
                                    </v-row>
                                    <v-row>
                                        <v-data-table :headers="headers_tshirt" :items="project.project_tshirt_size"
                                            hide-default-footer class="outlined-data-table mx-3 mb-6">
                                            <template v-slot:[`item.hour_min`]="{ item }">
                                                <v-text-field v-model="item.hour_min" dense solo flat outlined
                                                    hide-details type="number"
                                                    :rules="[v => !!v, v => v >= 0, v => (item.hour_max === null || v <= item.hour_max)]"></v-text-field>
                                            </template>
                                            <template v-slot:[`item.hour_max`]="{ item }">
                                                <v-text-field v-model="item.hour_max" dense solo flat outlined
                                                    hide-details type="number"
                                                    :rules="[v => !!v, v => v >= 0, v => (item.hour_min === null || v >= item.hour_min)]"></v-text-field>
                                            </template>
                                        </v-data-table>
                                    </v-row>

                                </v-col>
                                <v-col cols="9">
                                    <v-row>
                                        <h4 class="mx-3">Tools</h4>
                                    </v-row>
                                    <v-row>
                                        <v-combobox v-model="project.tools" :items="availableTools" class="mx-3"
                                            label="Add some tags" multiple outlined dense small-chips solo flat
                                            deletable-chips hide-no-data hide-selected @input="handleInput"
                                            :menu-props="{ bottom: true, offsetY: true }"></v-combobox>
                                    </v-row>
                                    <v-row align="center" justify="space-between">
                                        <h4 class="mx-3">Team:</h4>
                                        <v-btn @click="addRole" color="primary500" outlined class="mx-3">
                                            <v-icon left>mdi-plus</v-icon>
                                            New User
                                        </v-btn>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <!-- TODO: Add :items="user" -->
                                            <v-data-table :headers="headers" :items="mergedProjectUsers"
                                                class="outlined-data-table" hide-default-footer :items-per-page="-1">
                                                <template v-slot:item.action="{ item, index: subindex }">
                                                    <v-icon class="mr-2"
                                                        @click="editItem(item, subindex)">mdi-pencil</v-icon>
                                                    <v-icon class="mr-2"
                                                        @click="confirmDeleteItem(item, subindex)">mdi-delete</v-icon>
                                                </template>
                                            </v-data-table>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>

        <!-- Dialog to edit or create rol -->
        <v-dialog v-model="showEditDialog" max-width="500px">
            <v-card>
                <v-card-title>
                    {{ currentEditIndex >= 0 ? 'Edit User' : 'Add New Project User' }}
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="12" md="12">
                            <h4>User</h4>
                            <v-select v-model="currentEditItem.user_id" :items="tenantUsers" item-text="full_name"
                                item-value="user_id" label="User" dense solo
                                :menu-props="{ offsetY: true, maxHeight: '300' }"
                                :disabled="isUserFieldDisabled"></v-select>
                        </v-col>
                        <v-col v-if="!isInheritedRole" cols="12" sm="12" md="12">
                            <h4>Access Role</h4>
                            <v-select v-model="currentEditItem.access_role_id" :items="platformRoles" item-text="name"
                                item-value="role_id" label="Access Role" dense solo
                                :menu-props="{ offsetY: true, maxHeight: '300' }"></v-select>
                        </v-col>
                        <v-col v-if="!isProjectGuest" cols="12" sm="12" md="12">
                            <h4>Project Role</h4>
                            <v-select v-model="currentEditItem.project_role" :items="roleList" item-text="name"
                                item-value="name" label="Project Role" dense solo
                                :menu-props="{ offsetY: true, maxHeight: '300' }"></v-select>
                        </v-col>
                        <v-col v-if="user_profile.org_role_internal != 'organization_member' && !isProjectGuest"
                            cols="12" sm="12" md="12">
                            <h4>Cost per Hour</h4>
                            <v-text-field v-model="currentEditItem.cost_per_hour" label="Cost/Hour" dense solo
                                type="number"
                                :rules="[v => (!v || v > 0) || 'The number must be positive']"></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary500" text @click="showEditDialog = false">Cancel</v-btn>
                    <v-btn color="primary500" text @click="saveRoleChanges">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Message to delete user -->
        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="text-h5">Are you sure you want to delete this user?</v-card-title>
                <v-card-text v-if="currentDeleteItem.access_type == 'Inherited'">
                    This user has an inherited role access, only the project role and the cost per hour will be deleted
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="success500" text @click="dialogDelete = false">Cancel</v-btn>
                    <v-btn color="error500" text @click="deleteRole">Delete</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-snackbar v-model="project_saved" timeout="2000" color="success500" multiLine>
            <v-icon color="success100">mdi-content-save-check</v-icon>
            Project overview correctly saved!
            <v-btn icon @click="project_saved = false">
                <v-icon color="success100">mdi-close</v-icon>
            </v-btn>
        </v-snackbar>

        <!-- TODO: Add a snackbar to handle the error messages -->
    </v-app>
</template>

<style scoped>
.breadcrumbs-item {
    padding: 0px 15px;
}
</style>

<script>
import Vue from 'vue';
import user_profile_mixin from "../mixins/user_profile.js";
const axios = require("axios").default;

export default {
    mixins: [user_profile_mixin],
    data() {
        return {
            id: this.$route.params.project_id,
            project: { name: 'loading...' },
            items: [],
            availableTools: [],
            userList: [],
            roleList: [],
            permissionList: [],
            showEditDialog: false,
            menuEndDate: false,
            currentEditIndex: -1,
            project_saved: false,
            errorVisible: false,
            errorMsg: null,
            dialogDelete: false,
            platformRoles: [],
            accessRoleMap: null,
            mergedProjectUsers: [],
            tenantUsers: [],
            guestRoleId: null,
            user_profile: { org_role_internal: "organization_member" },
            currentEditItem: {
                user_id: '',
                project_role: '',
                cost_per_hour: 1,
                full_name: '',
                access_role: '',
                access_role_id: '',
                access_type: '',
            },
            currentDeleteItem: {
                user_id: '',
                project_role: '',
                cost_per_hour: 1,
                full_name: '',
                access_role: '',
                access_role_id: '',
                access_type: '',
            },
            headers_team: [
                { text: 'User', value: 'full_name', sortable: false },
                { text: 'Access Role', value: 'access_role', sortable: false },
                { text: 'Access Type', value: 'access_type', sortable: true },
                { text: 'Project Role', value: 'project_role', sortable: true },
                { text: 'Cost/Hour', value: 'cost_per_hour', align: 'center', width: '120px', sortable: false },
                { text: 'Delete', value: 'action', align: 'center', width: '120px', sortable: false },
            ],
            headers_tshirt: [
                { text: 'T-shirt', value: 'size', align: 'center', width: '75px', sortable: false },
                { text: 'Min Hour', value: 'hour_min', align: 'center', sortable: false },
                { text: 'Max Hour', value: 'hour_max', align: 'center', sortable: false },
            ],
        };
    },

    computed: {
        breadcrumbLinks() {
            if ('name' in this.project) {
                let items = [
                    {
                        text: 'Dashboard',
                        link: true,
                        exact: true,
                        disabled: false,
                        to: {
                            name: 'dashboard',
                        }
                    },
                    {
                        text: this.project.name,
                        disabled: true,
                    }
                ];
                return items;
            } else {
                return [];
            }
        },
        headers() {
            if (this.user_profile.org_role_internal != 'organization_member') {
                return this.headers_team;
            } else {
                return this.headers_team.filter((x) => x.text != 'Cost/Hour');
            }
        },
        isUserFieldDisabled() {
            return this.currentEditIndex >= 0;
        },
        isProjectGuest() {
            return this.currentEditItem.access_role_id == this.guestRoleId;
        },
        isInheritedRole() {
            return this.currentEditItem.access_type == 'Inherited';
        }
    },
    methods: {
        initializeTools() {
            this.availableTools = [...this.projectStructure.tools];
        },
        handleInput(newSelectedTools) {
            const newTools = newSelectedTools.filter(tool => !this.availableTools.includes(tool));
            this.availableTools.push(...newTools);
        },
        addRole() {
            this.currentEditIndex = -1;
            this.currentEditItem = {
                user_id: '',
                project_role: '',
                cost_per_hour: 1,
                full_name: '',
                access_role: '',
                access_role_id: '',
                access_type: '',
            };
            this.showEditDialog = true;
        },
        editItem(item, index) {
            this.currentEditIndex = index;
            this.currentEditItem = { ...item };
            this.showEditDialog = true;
        },
        formatDate(dateValue) {
            if (!dateValue) return null;
            const d = new Date(dateValue);
            const options = { year: 'numeric', month: 'short', day: 'numeric' };
            return d.toLocaleDateString('en-US', options);
        },
        formatEndDate(end_date) {
            const isoDate = new Date(end_date).toISOString();
            return isoDate.substring(0, 10);
        },
        confirmDeleteItem(item, index) {
            this.dialogDelete = true;
            this.deletingRoleIndex = index;
            this.currentDeleteItem = item;
        },
        deleteRole() {
            // Get the user_id from currentDeleteItem
            const userId = this.currentDeleteItem.user_id;

            // Remove the user from the team object within project
            this.project.project_team = this.project.project_team.filter(
                (member) => member.user_id !== userId
            );
            this.saveProjectTeamChanges();

            // Removed the user from the merged array
            this.mergedProjectUsers.splice(this.deletingRoleIndex, 1);

            // If the access role is not inherited, remove it as well
            if (this.currentDeleteItem.access_type != 'Inherited') {
                this.removeUserAccess(userId, this.currentDeleteItem.access_role_id);
            }

            // Clear off the flags
            this.currentDeleteItem = {
                user_id: '',
                project_role: '',
                cost_per_hour: 1,
                full_name: '',
                access_role: '',
                access_role_id: '',
                access_type: '',
            };
            this.deletingRoleIndex = null;
            this.dialogDelete = false;
        },
        saveRoleChanges() {
            const currentItem = this.currentEditItem;
            const editIndex = this.currentEditIndex;
            const existingItem = this.mergedProjectUsers[editIndex];

            console.log(existingItem);

            // Determine if we are editing an existing user or adding a new one
            const isEdit = editIndex >= 0 && existingItem;

            // Check if access_role_id has changed
            const accessRoleChanged = isEdit && currentItem.access_role_id !== existingItem.access_role_id;

            // If access_role_id has changed, delete the old role and add the new one (only project related roles)
            if (accessRoleChanged) {
                this.removeUserAccess(existingItem.user_id, existingItem.access_role_id);
                this.addUserAccess(existingItem.user_id, currentItem.access_role_id);
            }

            let cost_per_hour = parseFloat(this.currentEditItem.cost_per_hour);
            cost_per_hour = isNaN(cost_per_hour) ? 0 : (cost_per_hour < 0 ? 0 : cost_per_hour);

            // Based on if it's an update or create operation, handle the different logic
            if (isEdit) {
                // Update existing user
                Vue.set(this.mergedProjectUsers, editIndex, {
                    ...existingItem,
                    project_role: currentItem.project_role,
                    access_role: this.accessRoleMap.get(currentItem.access_role_id),
                    access_role_id: currentItem.access_role_id,
                    cost_per_hour: cost_per_hour,
                    access_type: currentItem.access_type
                });
            } else {
                // Create new user
                this.mergedProjectUsers.push({
                    user_id: currentItem.user_id,
                    full_name: this.tenantUsers.find(user => user.user_id === currentItem.user_id).full_name,
                    project_role: currentItem.project_role,
                    access_role: this.accessRoleMap.get(currentItem.access_role_id),
                    access_role_id: currentItem.access_role_id,
                    cost_per_hour: cost_per_hour,
                    access_type: 'Project Specific'
                });

                // Add the access role
                this.addUserAccess(currentItem.user_id, currentItem.access_role_id);

            }

            // Update the project team object
            const teamIndex = this.project.project_team.findIndex(member => member.user_id === currentItem.user_id);

            if (teamIndex !== -1) {
                this.project.project_team[teamIndex] = {
                    user_id: currentItem.user_id,
                    full_name: currentItem.full_name,
                    project_role: currentItem.project_role,
                    cost_per_hour: cost_per_hour
                };
            } else {
                this.project.project_team.push({
                    user_id: currentItem.user_id,
                    full_name: this.tenantUsers.find(user => user.user_id === currentItem.user_id).full_name,
                    project_role: currentItem.project_role,
                    cost_per_hour: cost_per_hour
                });
            }
            this.saveProjectTeamChanges();

            // Clear off the flags
            this.currentEditIndex = -1;
            this.showEditDialog = false;
            this.currentEditItem = {
                user_id: '',
                project_role: '',
                cost_per_hour: 1,
                full_name: '',
                access_role: '',
                access_role_id: '',
                access_type: '',
            };
        },
        saveProjectTeamChanges() {
            let saveStructure = {
                project_team: this.project.project_team
            };
            // Save the project_team field
            axios
                .post(`/projects/${this.project.id}/edit`, {
                    fields: saveStructure,
                })
                .then((response) => {
                    this.project_saved = true;
                })
                .catch((error) => {
                    console.log("Error saving the project team", error);
                });
        },
        saveProjectStructure() {
            const isArrayFieldEmpty = (field) => {
                return Array.isArray(field) && (field === null || field.length === 0);
            };

            if (Object.keys(this.project).length === 0) {
                return false;
            }
            if (
                this.project.end_date === null &&
                this.project.start_date === null &&
                this.project.project_description === null &&
                isArrayFieldEmpty(this.project.role) &&
                isArrayFieldEmpty(this.projectStructure.tools)
            ) {
                return false;
            }

            let saveStructure = {
                end_date: this.project.end_date,
                area: this.project.area,
                description: this.project.description,
                tools: this.project.tools,
                project_tshirt_size: this.project.project_tshirt_size,
                role: this.project.role
            }

            axios
                .post(`/projects/${this.project.id}/edit`, {
                    fields: saveStructure,
                })
                .then((response) => {
                    this.project_saved = true;
                });
            return true;
        },
        fetchProjectInfo() {
            if (this.id == null) {
                return;
            }
            axios
                .get(`/projects/${this.id}`)
                .then((response) => {
                    this.project = response.data;
                    let project_status = response.data.status;
                    const valid_states = ["board_setup_finished", "initial_tasks_guides_finished", "initial_tasks_cost_finished"];
                    if (!valid_states.includes(project_status)) {
                        this.$router.push(`/projects`);
                    }
                    this.project.end_date = this.formatEndDate(this.project.end_date);
                    this.prepareOrganizationLists();
                    axios.get(`/projects/${this.id}/users`)
                        .then((response_users) => {
                            let projectUsers = response_users.data.users;
                            this.mergedProjectUsers = this.mergeProjectUsersData(this.project.project_team, projectUsers);
                        })
                        .catch((error) => {
                            // TODO: handle the error accordingly
                        });
                })
                .catch((error) => {
                    console.log(
                        "Error occurred while fetching project with id: " + this.id
                    );
                    this.$router.push(`/projects`);
                });
        },
        prepareOrganizationLists() {
            if (this.project && this.project.roles) {
                this.project.roles.forEach(role => {
                    if (!this.roleList.some(existingRole => existingRole.name === role.name)) {
                        this.roleList.push({ name: role.name, cost: role.cost });
                    }
                });
            }
        },
        getRoles() {
            this.axios.get('/user/roles_available?role_type=project_related')
                .then((res) => {
                    this.platformRoles = res.data.roles;
                    const guestRole = this.platformRoles.find(item => item.name === 'Guest');
                    this.guestRoleId = guestRole ? guestRole.role_id : null;
                    this.accessRoleMap = new Map(this.platformRoles.map(role => [role.role_id, role.name]));
                })
                .catch((err) => {
                    console.log("Error retrieving available roles. Please try again later.")
                })
        },
        mergeProjectUsersData(projectTeam, projectUsers) {
            // Create a hash map of project users to easily index it afterwards
            const projectUsersMap = new Map(projectUsers.map(user => [user.user_id, user]));

            // Iterate through projectTeam and merge with projectUsers
            const mergedArray = projectTeam.map(teamMember => {
                const matchedUser = projectUsersMap.get(teamMember.user_id);

                // Construct the object based on whether matchedUser exists
                return {
                    user_id: teamMember.user_id,
                    full_name: teamMember.full_name,
                    project_role: teamMember.project_role || null,
                    access_role: matchedUser ? matchedUser.role_name : null,
                    access_role_id: matchedUser ? matchedUser.role_id : null,
                    cost_per_hour: teamMember.cost_per_hour || null,
                    access_type: matchedUser ? matchedUser.access_type : null
                };
            });

            // Handle users that are only in projectUsers and not in projectTeam (should be the majority of cases)
            projectUsers.forEach(user => {
                if (!mergedArray.find(merged => merged.user_id === user.user_id)) {
                    mergedArray.push({
                        user_id: user.user_id,
                        full_name: user.full_name,
                        project_role: null,
                        access_role: user.role_name,
                        access_role_id: user.role_id,
                        cost_per_hour: null,
                        access_type: user.access_type
                    });
                }
            });

            console.log(mergedArray);
            return mergedArray;
        },
        getTenantUsers() {
            this.axios.get('/user/tenant_users')
                .then((res) => {
                    // Rename the id property to user_id
                    this.tenantUsers = res.data.users.map(item => {
                        const { id, ...rest } = item;
                        return {
                            ...rest,
                            user_id: id
                        };
                    });
                })
                .catch((err) => {
                    this.errorVisible = true;
                    this.errorMsg = "Error retrieving the organization users. Please try again later."
                })
        },
        addUserAccess(user_id, role_id) {
            this.axios.post(`/projects/${this.id}/share`, { user_id: user_id, role_id: role_id })
                .then((res) => {
                })
                .catch((err) => {
                    if (err.response.status === 403) {
                        this.errorMsg = "You don't have enough permissions grant the access. Please contact the project owner";
                    } else {
                        this.errorMsg = err.response;
                    }
                    this.errorVisible = true;
                    this.$posthog.capture('share_project_failed', {
                        project_id: this.id,
                        error: err.response
                    })
                });
        },
        removeUserAccess(user_id, role_id) {
            this.axios.post(`/projects/${this.id}/sharestop`, { user_id: user_id, role_id: role_id })
                .then((res) => {
                })
                .catch((err) => {
                    if (err.response.status === 403) {
                        this.errorMsg = "You don't have permission to revoke the access. Please contact the project owner";
                    } else {
                        this.errorMsg = "Error while deleting user";
                    }
                    this.errorVisible = true;
                    this.$posthog.capture('unshare_project_failed', {
                        project_id: this.id,
                        error: err.response
                    })
                });
        }
    },
    created() {
        this.fetchUserProfile();
        this.getRoles();
        this.getTenantUsers();
        this.fetchProjectInfo();
    }
};
</script>
