<template>
	<v-app>
		<v-container>
			<v-row>
				<v-col cols="auto" class="mr-auto">
					<h1>Redirecting</h1>
				</v-col>
			</v-row>

		</v-container>
	</v-app>
</template>

<style scoped>
</style>

<script>
const axios = require('axios').default;

export default {
	data() {
		return {
		}
	},
	methods: {

	},
    created(){
        const code = this.$route.query.code
        if (code) {
            axios
            .post('/integrations/clickup_oauth', { redirect_code: code })
            .then((response) => {
                console.log('API response:', response.data)
                this.$router.push('/integrations')
            })
            .catch((error) => {
                console.error('API error:', error)
                this.$router.push('/integrations')
            })
        } else {
          this.$router.push('/integrations')
        }
    },
}
</script>