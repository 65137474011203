<template>
  <div>

    <v-chip
      close
      :color="label.color"
      text-color="white"
      @click:close="delete_clicked"
      @click="select"
      class="mb-1 mr-1"
      label
    >
      {{label.text}}
    </v-chip>

  </div>
</template>

<script>
export default {
  props: {
    board: Object,
    label: Object,
  },
  data() {
    return {
      id: this.$route.params.project_id,
    };
  },
  methods: {
    delete_clicked: function(event) {
      this.axios
            .delete(`/boards/${this.id}/${this.board.id}/labels/${this.label.id}`)
    },
    select() {
      this.$emit("select");
    }
  }
};
</script>
