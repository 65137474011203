<template>
    <div class="app">
        <v-app-bar color="neutral800">
            <v-toolbar-title class="ml-4">
                <router-link to="/projects" class="white--text">
                    <v-img alt="Andenia" class="shrink mr-2" contain :src="require('@/assets/images/andenia_logo.png')"
                        transition="scale-transition" width="40" height="40" />
                </router-link>
            </v-toolbar-title>
            <v-spacer></v-spacer>

            <v-stepper v-model="selectedStep" class="stepper" dark>
                <v-stepper-header>
                    <v-stepper-step v-for="(step, index) in steps" color='primary500' :key="step"
                        :complete="step < selectedStep" :step="step">
                        {{ stepNames[index] }}
                        <v-icon v-if="index < steps.length - 1"
                            :color="step <= selectedStep ? 'primary500' : ''">mdi-chevron-right</v-icon>
                    </v-stepper-step>
                </v-stepper-header>
            </v-stepper>

            <v-spacer></v-spacer>

        </v-app-bar>

        <v-row>
            <v-card class='mx-auto content-card d-flex flex-column'>
                <v-form class="mx-6 d-flex flex-column">
                    <v-container class="d-flex fill-height">
                        <v-row class="mt-1 d-flex">
                            <v-col class="mx-10 text-start">
                                <h2>{{ this.projectName }}</h2>
                            </v-col>
                            <v-col class="text-end">
                                <v-btn class="mr-5 save-project-overview" color="primary500" outlined
                                    @click="saveProjectStructure()">
                                    <v-icon>mdi-content-save</v-icon>
                                    Save
                                </v-btn>
                                <v-btn class="done-project-overview" :disabled="!validateFieds" color="success500"
                                    @click="createTasks()">
                                    <v-icon>mdi-check-circle-outline</v-icon>
                                    Done
                                </v-btn>
                            </v-col>

                        </v-row>
                        <v-row>
                            <v-col>
                                <h4>Description</h4>
                                <v-textarea filled label="Description" auto-grow solo flat outlined dense rows="1"
                                    no-resize hide-details v-model="projectStructure.project_description"></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="4">
                                <h4>Start Date</h4>
                                <v-menu v-model="menuStartDate" :close-on-content-click="false" :nudge-right="40"
                                    transition="scale-transition" offset-y min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field :value="formatDate(projectStructure.start_date)"
                                            label="Start Date" append-icon="mdi-calendar" readonly v-bind="attrs"
                                            v-on="on" dense solo flat outlined></v-text-field>
                                    </template>
                                    <v-date-picker v-model="projectStructure.start_date"
                                        @input="menuStartDate = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="4">
                                <h4>End Date</h4>
                                <v-menu v-model="menuEndDate" :close-on-content-click="false" :nudge-right="40"
                                    transition="scale-transition" offset-y min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field :value="formatDate(projectStructure.end_date)" label="End Date"
                                            append-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" dense solo flat
                                            outlined></v-text-field>
                                    </template>
                                    <v-date-picker v-model="projectStructure.end_date"
                                        @input="menuEndDate = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="4">
                                <h4>Project Area</h4>
                                <v-text-field v-model="projectStructure.area" label="Project Area" dense solo flat
                                    outlined></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="3">
                                <v-row>
                                    <h4 class="mx-3">T-Shirt Size Conversion</h4>
                                </v-row>
                                <v-row>
                                    <v-data-table :headers="headers_tshirt"
                                        :items="projectStructure.project_tshirt_size" hide-default-footer
                                        class="outlined-data-table mx-3 mb-6">
                                        <template v-slot:[`item.hour_min`]="{ item }">
                                            <v-text-field v-model="item.hour_min" dense solo flat outlined hide-details
                                                type="number"></v-text-field>
                                        </template>
                                        <template v-slot:[`item.hour_max`]="{ item }">
                                            <v-text-field v-model="item.hour_max" dense solo flat outlined hide-details
                                                type="number"></v-text-field>
                                        </template>
                                    </v-data-table>
                                </v-row>

                            </v-col>
                            <v-col cols="9">
                                <v-row>
                                    <h4 class="mx-3">Tools</h4>
                                </v-row>
                                <v-row>
                                    <v-combobox v-model="projectStructure.tools" :items="availableTools" class="mx-3"
                                        label="Add some tags" multiple outlined dense small-chips solo flat
                                        deletable-chips hide-no-data hide-selected @input="handleInput"
                                        :menu-props="{ bottom: true, offsetY: true }"></v-combobox>
                                </v-row>
                                <v-row align="center" justify="space-between">
                                    <h4 class="mx-3">Team:</h4>
                                    <v-btn @click="addRole" color="primary500" dark class="mx-3">
                                        <v-icon left>mdi-plus</v-icon>
                                        New Role
                                    </v-btn>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-data-table :headers="headers" :items="projectStructure.team"
                                            class="outlined-data-table" hide-default-footer :items-per-page="-1">
                                            <template v-slot:item.action="{ item, index: subindex }">
                                                <v-icon class="mr-2"
                                                    @click="editItem(item, subindex)">mdi-pencil</v-icon>
                                                <v-icon class="mr-2"
                                                    @click="confirmDeleteItem(subindex)">mdi-delete</v-icon>
                                            </template>
                                        </v-data-table>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>

                <!-- Message after push Done button to create tasks -->
                <v-overlay :value="overlay">
                    <v-card class="dialog-content">
                        <v-card-title>
                            <h2>
                                The tasks are being created!
                            </h2>
                        </v-card-title>
                        <v-progress-circular :size="70" :width="7" color="primary500"
                            indeterminate></v-progress-circular>
                    </v-card>
                </v-overlay>

                <!-- Message to delete role -->
                <v-dialog v-model="dialogDelete" max-width="500px">
                    <v-card>
                        <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="success500" dark @click="dialogDelete = false">Cancel</v-btn>
                            <v-btn color="error500" dark @click="deleteRole">Delete</v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <!-- Dialog to edit or create rol -->
                <v-dialog v-model="showEditDialog" max-width="700px">
                    <v-card>
                        <v-card-title>
                            {{ currentEditIndex >= 0 ? 'Edit Role' : 'Add New Role' }}
                        </v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col cols="6">
                                    <h4>Rol</h4>
                                    <v-text-field v-model="currentEditItem.role" label="Rol" dense solo></v-text-field>
                                </v-col>
                                <v-col cols="3">
                                    <h4>Cost per Hour</h4>
                                    <v-text-field v-model="currentEditItem.costPerHour" label="Cost/Hour" dense solo
                                        type="number"
                                        :rules="[v => (!v || v > 0) || 'The number must be positive']"></v-text-field>
                                </v-col>
                                <v-col cols="3">
                                    <h4>Number</h4>
                                    <v-text-field v-model="currentEditItem.number" label="Number" dense solo
                                        type="number"
                                        :rules="[v => (!v || v > 0) || 'The number must be positive']"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary500" text @click="showEditDialog = false">Cancel</v-btn>
                            <v-btn color="primary500" text @click="saveRoleChanges">Save</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <v-snackbar v-model="creation_failed_snackbar" color="warning500" multiLine>
                    <v-icon color="warning100">mdi-alert</v-icon>
                    <span class="message-text-engine">Andenia engine is currently down, please try again later</span>
                    <v-btn icon @click="creation_failed_snackbar = false">
                        <v-icon color="warning100">mdi-close</v-icon>
                    </v-btn>
                </v-snackbar>

                <v-snackbar v-model="project_saved" timeout="2000" color="success500" multiLine>
                    <v-icon color="success100">mdi-content-save-check</v-icon>
                    Project form correctly saved!
                    <v-btn icon @click="project_saved = false">
                        <v-icon color="success100">mdi-close</v-icon>
                    </v-btn>
                </v-snackbar>

            </v-card>
        </v-row>
    </div>
</template>


<style scoped>
.message-text-engine {
    color: var(--v-warning100-base);
}

.select-tabs {
    border-radius: 11px;
}

.app {
    font-family: Inter;
    height: 100vh;
    width: 100vw;
}

.stepper {
    background-color: transparent !important;
    box-shadow: none !important;
}

.content-card {
    margin-top: 4%;
    height: calc(100vh - 200px);
    width: 80%;
    overflow-y: auto;
}

.dialog-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    border-radius: 8px;
    background-color: var(--v-neutral100-base) !important;
    color: var(--v-neutral800-base) !important
}

.content-card::-webkit-scrollbar,
.custom-textarea::-webkit-scrollbar {
    width: 8px;
    border-radius: 4px;
    background-color: var(--v-primary100-base);
}

.content-card::-webkit-scrollbar-thumb,
.custom-textarea::-webkit-scrollbar-thumb {
    background-color: var(--v-primary500-base);
    /* Color del pulgar de la barra de desplazamiento */
    border-radius: 4px;
    /* Puntas redondeadas del pulgar */
}

.content-card::-webkit-scrollbar-track,
.custom-textarea::-webkit-scrollbar-track {
    background-color: var(--v-primary100-base);
    /* Color de fondo de la pista de la barra de desplazamiento */
    border-radius: 4px;
    /* Puntas redondeadas del pulgar */
}

.content-card::-webkit-scrollbar-track:hover,
.custom-textarea::-webkit-scrollbar-track:hover {
    background-color: var(--v-primary100-base);
    /* Color de fondo de la pista de la barra de desplazamiento al pasar el cursor */
    border-radius: 4px;
    /* Puntas redondeadas del pulgar */
}

.outlined-data-table {
    border: 1px solid var(--v-neutral400-base) !important;
    border-radius: 4px !important;
}
</style>

<script>
import axios from 'axios';

export default {
    name: 'Chatbot',
    data() {
        return {
            id: this.$route.params.project_id,

            projectStructure: {},
            projectName: "Project Name",
            headers: [
                { text: 'Rol', value: 'name', sortable: false },
                { text: 'Cost/Hour', value: 'cost', align: 'center', width: '120px', sortable: false },
                { text: 'Number', value: 'number', align: 'center', width: '120px', sortable: false },
                { text: 'Delete', value: 'action', align: 'center', width: '120px', sortable: false },
            ],
            headers_tshirt: [
                { text: 'T-shirt', value: 'size', align: 'center', width: '75px', sortable: false },
                { text: 'Min Hour', value: 'hour_min', align: 'center', sortable: false },
                { text: 'Max Hour', value: 'hour_max', align: 'center', sortable: false },
            ],
            selectedStep: 1,
            steps: [1, 2, 3, 4],
            stepNames: ['Description', 'Validation', 'Time and Dependencies', 'Finish'],
            overlay: false,
            creation_failed_snackbar: false,
            project_saved: false,





            availableTools: [],
            dialogDelete: false,
            showEditDialog: false,
            currentEditIndex: -1,
            currentEditItem: {
                role: '',
                costPerHour: 1,
                number: 1,
            },
            menuStartDate: false,
            menuEndDate: false,

        };
    },
    methods: {
        formatDate(dateValue) {
            if (!dateValue) return null;
            const d = new Date(dateValue);
            const options = { year: 'numeric', month: 'short', day: 'numeric' };
            return d.toLocaleDateString('en-US', options);
        },
        getProjectName() {
            this.axios.get(`/projects/${this.id}`)
                .then((response) => {
                    this.projectName = response.data.name;
                })
                .catch((error) => {
                    console.log(`An error has ocurred while fetching the project data:  ${error}`);
                })

        },

        async createTasks() {
            this.overlay = true;
            this.projectStructure.project_name = this.projectName;
            this.projectStructure.project_status = "chatbot_finished";
            this.projectStructure.project_id = this.id;
            this.$posthog.capture('initial_tasks_creation_started', {
                project_id: this.id
            });
            await axios
                .post(`/projects/${this.id}/edit`, {
                    fields: this.projectStructure,
                })
                .then((response) => {
                    // console.log(response);
                });
            await axios
                .post(`/tasks/${this.id}/chatbot_tasks`, {
                    project_details: this.projectStructure,
                })
                .then((response) => {
                    // console.log(response);
                    this.$posthog.capture('initial_tasks_creation_finished', {
                        project_id: this.id
                    });
                    this.$router.push(`/taskvalidation/${this.id}`);
                })
                .catch((error) => {
                    console.log("Andenia engine is currently down");
                    this.overlay = false;
                    this.creation_failed_snackbar = true;
                });
        },

        saveProjectStructure() {
            const isArrayFieldEmpty = (field) => {
                return Array.isArray(field) && (field === null || field.length === 0);
            };

            if (Object.keys(this.projectStructure).length === 0) {
                return false;
            }
            if (
                this.projectStructure.end_date === null &&
                this.projectStructure.start_date === null &&
                this.projectStructure.project_description === null &&
                isArrayFieldEmpty(this.projectStructure.team) &&
                isArrayFieldEmpty(this.projectStructure.tools)
            ) {
                return false;
            }

            let saveStructure = {
                end_date: this.projectStructure.end_date,
                start_date: this.projectStructure.start_date,
                project_name: this.projectName,
                description: this.projectStructure.project_description,
                tools: this.projectStructure.tools,
                project_tshirt_size: this.projectStructure.project_tshirt_size,
                team: this.projectStructure.team
            }
            // console.log(saveStructure)
            axios
                .post(`/projects/${this.id}/edit`, {
                    fields: saveStructure,
                })
                .then((response) => {
                    // console.log(response);
                    this.project_saved = true;
                });
            return true;
        },
        initializeTools() {
            this.availableTools = [...this.projectStructure.tools];
        },
        handleInput(newSelectedTools) {
            const newTools = newSelectedTools.filter(tool => !this.availableTools.includes(tool));
            this.availableTools.push(...newTools);
        },

        addRole() {
            this.currentEditIndex = -1;
            this.currentEditItem;
            this.showEditDialog = true;
        },
        editItem(item, index) {
            this.currentEditIndex = index;
            this.currentEditItem.role = item.name;
            this.currentEditItem.costPerHour = item.cost;
            this.currentEditItem.number = item.number;
            this.showEditDialog = true;
        },
        saveRoleChanges() {
            if (this.currentEditIndex >= 0) {
                // Update existing role
                let role = this.projectStructure.team[this.currentEditIndex];
                role.name = this.currentEditItem.role;
                role.cost = parseFloat(this.currentEditItem.costPerHour);
                role.number = parseInt(this.currentEditItem.number);
                // Aditional validation
                role.cost = isNaN(role.cost) ? 0 : (role.cost < 0 ? 0 : role.cost);
                role.number = isNaN(role.number) ? 0 : (role.number < 0 ? 0 : role.number);
            } else {
                // Create new rol
                this.projectStructure.team.push({
                    name: this.currentEditItem.role,
                    cost: parseFloat(this.currentEditItem.costPerHour),
                    number: parseInt(this.currentEditItem.number),
                });
            }

            this.currentEditIndex = -1;
            this.showEditDialog = false;

        },
        confirmDeleteItem(item) {
            this.dialogDelete = true;
            this.deletingRoleIndex = item;
        },
        deleteRole() {
            this.projectStructure.team.splice(this.deletingRoleIndex, 1);
            this.dialogDelete = false;
        },
    },
    mounted() {

    },
    computed: {
        validateFieds() {
            // Check that project structure is not an empty object (first load)
            if (Object.keys(this.projectStructure).length === 0) {
                return false;
            }

            let isInvalidTshirtSize = true;

            // Check values in T-shirt size table
            if (this.projectStructure.project_tshirt_size !== null) {
                isInvalidTshirtSize = this.projectStructure.project_tshirt_size.some(size => {
                    return parseInt(size.hour_min) === null || parseInt(size.hour_min) < 0 ||
                    parseInt(size.hour_max) === null || parseInt(size.hour_max) < 0 ||
                        parseInt(size.hour_min) >= parseInt(size.hour_max) ||
                        parseInt(size.hour_min) === '' || parseInt(size.hour_max) === '';
                });
            }

            // If field type is array, check if it's empty
            const isArrayFieldEmpty = (field) => {
                return Array.isArray(field) && (field === null || field.length === 0);
            };

            if (
                this.projectStructure.end_date === null ||
                this.projectStructure.start_date === null ||
                this.projectStructure.project_description === null || this.projectStructure.project_description === '' ||
                this.projectStructure.area === null || this.projectStructure.area === '' ||
                isArrayFieldEmpty(this.projectStructure.team) ||
                isArrayFieldEmpty(this.projectStructure.tools) ||
                isInvalidTshirtSize
            ) {
                // If at least one field is empty, return it as not validated
                return false;
            } else {
                console.log("Step 5")
                // All fields are valid
                return true;
            }
        }
    },
    watch: {

    },
    created() {
        // this.getFlagOnboarding();
        this.getProjectName();
        console.log("Starting form");
        axios
            .get(`/projects/${this.id}/overview_chat_history`)
            .then((response) => {
                let initial_data = response.data;
                if (initial_data.redirect) {
                    this.$router.push(`/projects`);
                }

                this.projectStructure = initial_data.project_structure;
                console.log(this.projectStructure);
                this.initializeTools();
            })
            .catch((error) => {
                console.log(
                    "Error occurred while fetching initial chatbot for project: " + this.id
                );
            });
    }
};
</script>