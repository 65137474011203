<template>
    <div>
        <v-container class="content-container">
            <v-row>
                <v-text-field v-model="search" label="Search" prepend-inner-icon="mdi-magnify" single-line
                    variant="outlined" hide-details></v-text-field>
                <v-expansion-panels class="panel" focusable ref="expansionPanels">
                    <v-expansion-panel v-for="(epic, index) in sortByTaskId(filteredEpics)" :key="index">
                        <v-expansion-panel-header :class="{ 'active-header': activePanel === index }"
                            @click="togglePanel(index)">
                            <v-container>
                                <v-layout row wrap align-center>
                                    <v-flex xs8>
                                        {{ epic.epic_name }}
                                    </v-flex>
                                </v-layout>
                            </v-container>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-data-table :headers="headers" :items="epic.tasks" :search="search"
                                @click:row="showDetails" class="elevation-2 selectable-row" hide-default-footer expand-icon
                                :items-per-page="-1">
                                <template v-slot:[`item.actions`]="{ item, index }">
                                    <v-icon @click.stop="confirmDeleteRow(epic, index)">mdi-delete</v-icon>
                                </template>
                            </v-data-table>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-row>
        </v-container>

        <v-dialog v-model="showDeleteDialog" max-width="500">
            <v-card>
                <v-card-title class="headline">Confirm Deletion</v-card-title>
                <v-card-text>{{ deleteConfirmationMessage }}</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="success500" text @click="cancelDelete">Cancel</v-btn>
                    <v-btn color="error500" text @click="confirmDelete">Delete</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </div>
</template>

<style scoped>
.panel {
    margin-top: 20px;
    font-family: Inter;
}

.v-expansion-panel-header {
    height: 55px;
}

.active-header {
    background-color: var(--v-primary500-base);
    color: white;
    font-weight: bold;
    font-family: Inter;
}

.selectable-row {
  cursor: pointer;
}
</style>

<script>

export default {
    props: {
        project: null,
    },
    data() {
        return {
            id: this.$route.params.project_id,
            activePanel: null,
            search: '',
            headers: [
                { text: 'Task', value: 'task_name', width: '200px' },
                { text: 'T-Shirt Size', align: 'center', value: 'tshirt', width: '90px' },
                { text: 'Time [h]', align: 'center', value: 'time', width: '90px' },
                { text: 'Description', value: 'task_description' },
                { text: 'Role', value: 'role', width: '170px' },
                { text: 'Actions', align: 'center', value: 'actions', sortable: false, width: '90px' },
            ],
            tasks_id: -1,
            showDeleteDialog: false,
            deletingEpic: null,
            deletingRowIndex: null,
            deleteConfirmationMessage: '',
            deletingEpicIndex: null,
        };
    },
    methods: {
        togglePanel(index) {
            this.activePanel = this.activePanel === index ? null : index;
        },
        sortByTaskId(tasks) {
            return tasks.sort((a, b) => a.tasks[0].epic_id - b.tasks[0].epic_id);
        },
        showDetails: function (value) {
            this.$router.push(`/project/${this.project.id}/task/${value.id}`);
        },
        confirmDeleteRow(epic, index) {
            const taskName = epic.tasks[index].task_name;
            this.deletingEpic = epic;
            this.deletingRowIndex = index;
            this.deleteConfirmationMessage = `Are you sure you want to delete "${taskName}"?`;
            this.showDeleteDialog = true;
        },
        cancelDelete() {
            this.resetDeleteState();
            this.showDeleteDialog = false;
        },
        confirmDelete() {
            if (this.deletingRowIndex !== null) {
                this.deleteRow(this.deletingEpic, this.deletingRowIndex);
            }
            this.showDeleteDialog = false;
            this.resetDeleteState();
        },
        deleteRow(epic, index) {
            const task_id = epic.tasks[index].id;
            this.$emit("deleteTask", task_id)
        },
        resetDeleteState() {
            this.deletingEpic = null;
            this.deletingRowIndex = null;
        }
    },
    computed: {
        filteredEpics() {
            if (this.project) {
                let listEpics = this.project.tasks.reduce((accumulator, epic) => {
                    const existingEpic = accumulator.find((item) => item.epic_name === epic.epic_name);
                    if (existingEpic) {
                        existingEpic.tasks.push({ ...epic, selected: false });
                    } else {
                        accumulator.push({
                            epic_name: epic.epic_name,
                            tasks: [{ ...epic, selected: false }],
                        });
                    }
                    return accumulator;
                }, []);

                console.log(listEpics);
                return listEpics.filter(epic => epic.tasks.length > 0);
            }
            else {
                return [];
            }
        },
    },
};
</script>