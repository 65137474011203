<template>
  <v-container fill-height class="d-flex align-center justify-center">
    <v-row>
      <v-col class="text-center">
        <v-img
          src="https://cdn.vuetifyjs.com/images/logos/logo.svg"
          alt="404 Image"
          contain
          class="mx-auto"
          max-width="300"
        ></v-img>
        <h1 class="display-1 font-weight-bold">404</h1>
        <h2 class="headline font-weight-light mb-3">Page not found</h2>
        <p class="subheading">We are sorry, but the page you are looking for does not exist.</p>
        <v-btn color="primary" class="mt-5" @click="goHome">Back to top</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  methods: {
    goHome() {
      this.$router.push('/');
    }
  }
};
</script>

<style>
h1.display-1 {
  font-size: 10rem;
}

v-img {
  filter: drop-shadow(2px 4px 6px black);
}

v-btn {
  font-size: 1.2rem;
  padding: 10px 20px;
}

v-container {
  background-color: #f5f5f5;
}
</style>
