<template>
  <v-app class="white" >
    <v-card flat>
      <v-toolbar flat>
        <!-- Project title -->
        <v-toolbar-title v-if="!project_name_editing" class="ml-5 mt-15">
          <h2>{{ project.name }}
            <v-btn icon @click="projectEditClicked">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </h2>
          <!-- Subtitles -->
          <v-row align="center">
            <v-col cols="6">
              <v-subheader>Created on: {{ project.created_at | date_format }}</v-subheader>
            </v-col>

            <v-col cols="6">
              <v-subheader>Location: ARG{{ project.location }}</v-subheader>
            </v-col>
          </v-row>

        </v-toolbar-title>

        <!-- Edit title -->
        <v-text-field v-else dense hide-details outlined type="text" v-model="newProjectName" append-icon="mdi-check"
          @click:append="saveClicked" append-outer-icon="mdi-close" @click:append-outer="cancelClicked"></v-text-field>

        <v-spacer></v-spacer>

        <!-- Button to change between visualitations -->
        <template v-slot:extension>
          <v-tabs v-model="tabIndex" @change="updateVisualizationType" centered slider-color="primary500">
            <v-tab>
              <v-icon medium>mdi-developer-board</v-icon> Kanban
            </v-tab>
            <v-tab>
              <v-icon medium>mdi-chart-timeline</v-icon> Gantt
            </v-tab>
            <v-tab>
              <v-icon medium>mdi-list-box</v-icon> List
            </v-tab>
          </v-tabs>
        </template>

        <!-- Right buttons actions -->
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item @click="showNewStatusDialog">
              <v-list-item-icon>
                <v-icon>mdi-plus</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Add Status</v-list-item-title>
            </v-list-item>
            <v-list-item @click="showLabelsDialog">
              <v-list-item-icon>
                <v-icon>mdi-label</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Labels</v-list-item-title>
            </v-list-item>
            <v-list-item @click="toggleArchived">
              <v-list-item-icon>
                <v-icon>mdi-archive</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Archive</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar>

      <v-tabs-items v-model="visualization_type">
        <v-tab-item value="kanban">
          <v-card flat>
            <v-card-text>
              <Board :project="project" v-on:updateProjectStatusOrder="updateStatusColumns"
                v-on:taskOrderChanged="isOrderEdited = true" v-on:createNewTaskProject="addNewTask"
                v-on:deleteTaskProject="deleteCardTask"/>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item value="gantt">
          <v-card flat>
            <v-card-text>
              <Gantt :project="project" />
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item value="list">
          <v-card flat>
            <v-card-text>
              <ListView :project="project" v-on:deleteTask="deleteCardTask"/>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>

    <v-snackbar v-model="taskCreationFailed" color="error500" multiLine>
      <v-icon color="error100">mdi-alert</v-icon>
      We are sorry! We couldn't create your task, please try again later
      <v-btn icon @click="taskCreationFailed = false">
        <v-icon color="error100">mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
    <BoardLabelsDialog v-model="labelsDialogVisible" :board="project" v-on:visibility-change="hideLabelsDialog" />
    <NewProjectStatus v-model="newStatusDialogVisible" :project="project" v-on:hideNewStatus="hideNewStatusDialog"
      v-on:addProjectStatus="updateStatusColumns" />
    <v-navigation-drawer v-model="archiveVisible" app right disable-resize-watcher>
      <ArchivedCards />
    </v-navigation-drawer>
  </v-app>
</template>

<style scoped></style>

<script>
import Board from "@/components/Board.vue";
import ArchivedCards from "@/components/ArchivedCards.vue";
import BoardLabelsDialog from "@/components/BoardLabelsDialog.vue";
import NewProjectStatus from "../components/NewProjectStatus.vue";
import Gantt from "../components/Gantt.vue";
import ListView from "../components/ListView.vue";

const axios = require("axios").default;
import user_profile_mixin from "../mixins/user_profile.js";

export default {
  components: {
    Board,
    ArchivedCards,
    BoardLabelsDialog,
    NewProjectStatus,
    Gantt,
    ListView
  },
  filters: {
    date_format: function (value) {
      if (!value) return '';

      // Create a date object from the provided value
      const date = new Date(value);

      // Options to format the date in the desired format
      const options = { year: 'numeric', month: 'long', day: 'numeric' };

      // Return the formatted date
      return date.toLocaleDateString("en-US", options);
    },
  },
  mixins: [user_profile_mixin],
  data() {
    return {
      id: this.$route.params.project_id,
      visualization_type: null,
      tabIndex: 0,
      toggle_one: 0,
      board: [],
      project: { name: 'loading...' }, //TODO: use vuetify skeleton loading
      project_name_editing: false,
      newProjectName: "",
      labelsDialogVisible: false,
      labelsDialogCardId: 0,
      isOrderEdited: false,
      newStatusDialogVisible: false,
      archiveVisible: false,
      taskCreationFailed: false,
      user_profile: {org_role_internal: "organization_member"},
    };
  },

  beforeMount() {
    window.addEventListener("beforeunload", this.beforeNav)
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("beforeunload", this.beforeNav);
    })
  },

  mounted() {
    this.intervalHolder = setInterval(this.updateTasksOrder, 300000);
  },

  beforeRouteLeave(to, from, next) {
    clearInterval(this.intervalHolder);
    if (this.isOrderEdited) {
      this.updateTasksOrder();
      this.isOrderEdited = false;
      next();
    }
    next();
  },

  methods: {
    updateVisualizationType(index) {
      const types = ['kanban', 'gantt', 'list'];
      this.visualization_type = types[index];
    },
    projectEditClicked() {
      this.newProjectName = this.project.name;
      this.project_name_editing = true;
    },
    cancelClicked() {
      this.project_name_editing = false;
      this.newProjectName = "";
    },
    saveClicked() {
      if (this.newProjectName.length == 0) return
      this.axios
        .post(`/projects/${this.id}/edit`, { fields: { project_name: this.newProjectName } })
        .then((res) => {
          this.project.name = this.newProjectName;
          this.project_name_editing = false;
        })
    },
    addNewTask(payload) {
      this.axios
        .post(`/tasks/${this.id}/create_tasks`, payload)
        .then(response => {
          let new_task_data = response.data.tasks[0]; 
          payload.tasks[0].id = new_task_data.id;
          payload.tasks[0].project_id = this.project.id;
          payload.tasks[0].task_id = new_task_data.task_id; 
          payload.tasks[0].end_date = new_task_data.end_date;
          this.project.tasks.push(payload.tasks[0]);
        })
        .catch(error => {
          console.error('Error creating task', error);
          this.taskCreationFailed = true;
        });
    },
    deleteCardTask(task_id) {
      this.axios
        .delete(`/tasks/${this.id}/${task_id}`)
        .then((response) => {
          this.project.tasks = this.project.tasks.filter(task => task.id !== task_id);
        })
        .catch((error) => {
        });
    },
    toggleArchived() {
      this.archiveVisible = !this.archiveVisible;
    },
    showLabelsDialog() {
      this.labelsDialogVisible = true;
    },
    hideLabelsDialog(event) {
      this.labelsDialogVisible = event;
    },
    showNewStatusDialog() {
      this.newStatusDialogVisible = true;
    },
    hideNewStatusDialog(event) {
      this.newStatusDialogVisible = event;
    },
    fetchProjectInfo() {
      if (this.id == null) {
        return;
      }
      axios
        .get(`/projects/${this.id}`)
        .then((response) => {
          this.project = response.data;
          let project_status = response.data.status;
          const valid_states = ["board_setup_finished", "initial_tasks_guides_finished", "initial_tasks_cost_finished"];
          if (!valid_states.includes(project_status)) {
            this.$router.push(`/projects`);
          }
          this.visualization_type = 'kanban';
        })
        .catch((error) => {
          this.$router.push(`/projects`);
        });
    },
    updateStatusColumns() {
      this.axios
        .post(`/projects/${this.project.id}/edit`, { fields: { task_status_options: this.project.task_status_options } })
        .then((res) => {
          if (res.data) {
            console.log(res.data);
          }
        });
    },
    updateTasksOrder() {
      let task_payload = this.project.tasks.map(({ status, order, id }) => ({ status, order, id }));
      this.axios
        .post(`/tasks/${this.project.id}/task_orders`, { updated_tasks: task_payload })
        .then((res) => {
          if (res.data) {
            console.log(res.data);
          }
        });
    },
    beforeNav(event) {
      clearInterval(this.intervalHolder);
      if (!this.isOrderEdited) {
        return
      } else {
        event.preventDefault()
        this.updateTasksOrder();
        this.isOrderEdited = false;
        return
      }
    }
  },
  watch: {
    visualization_type(newVal) {
      let viz_analytics = 'project_visualization_' + newVal;
      this.$posthog.capture(viz_analytics, {
        project_id: this.project.id
      });
    },
  },
  created() {
    this.fetchUserProfile();
    this.fetchProjectInfo();
  }
};
</script>
