<template>
  <v-container fluid class="main-container">
    <v-row class="fill-height d-flex justify-center align-center">
      <v-col cols="12" md="3">
        <v-card class="card-login" elevation="8">
          <v-row class="d-flex justify-center">
            <h2 class="header-text-login mt-5">Andenia</h2>
          </v-row>
          <v-row class="d-flex justify-center mt-4 mb-4">
            <h2>Sign out of your Andenia account</h2>
          </v-row>
          
          <v-row justify="center">
            <v-btn to="/" class="mt-4 mb-4" color="primary500" dark>Sign out</v-btn>
          </v-row>
          <v-row justify="center">
            <v-btn to="login" class="mt-4 mb-4" outlined color="primary500">Login with another account</v-btn>
          </v-row>


        </v-card>
      </v-col>
    </v-row>

  </v-container>
</template>

<style>
.main-container {
  height: 100vh;
}

.card-login {
  margin: auto;
  width: 100%;
}

.header-text-login {
  font-size: 32px;
  line-height: 48px;
  color: var(--v-neutral900-base);
}
</style>

<script>
export default {

  mounted() {
    this.$posthog.reset();
    window.Tawk_API.logout();
    this.$tawkMessenger.hideWidget();
  },

};
</script>